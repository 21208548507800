import { CognitoUserPool } from 'amazon-cognito-identity-js';

// console.log(process.env.REACT_APP_USER_POOL_ID, 'USER_POOL_ID');

const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID
};

// eslint-disable-next-line import/no-anonymous-default-export
export default new CognitoUserPool(poolData);
