import React, { useState } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import PlanTable from './PlanTable';

function Plan() {
    const [frequency, setFrequency] = useState('quarterly')


    const handleFrChange = (value) => {
        setFrequency(value)
    }

    return (
        <div className='plans'>
            <div className='container'>
                <div className="switch-wrapper">
                    <input id="quarterly" type="radio" value='quarterly' name="switch" checked={frequency === 'quarterly'} />
                    <input id="yearly" type="radio" value='yearly' name="switch" checked={frequency === 'yearly'} />
                    <label htmlFor="quarterly" onClick={() => handleFrChange('quarterly')} >Quarterly</label>
                    <label htmlFor="yearly" onClick={() => handleFrChange('yearly')}>Yearly</label>
                    <span className="highlighter"></span>
                </div>
                <div className='plans_table'>
                    <Tabs variant='enclosed' >
                        <TabList>
                            <Tab>Individual</Tab>
                            <Tab>Student</Tab>
                            <Tab>Law Firm</Tab>
                            <Tab>Enterprise</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <PlanTable frequency={frequency} />
                            </TabPanel>
                            <TabPanel>
                                <PlanTable frequency={frequency} />
                            </TabPanel>
                            <TabPanel>
                                <PlanTable frequency={frequency} />
                            </TabPanel>
                            <TabPanel>
                                <PlanTable frequency={frequency} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </div>


            </div>
        </div>
    )
}

export default Plan