/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Button, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserModalToolbar from './UserModalToolbar';
import UserListHead from './UserListHead';
import Scrollbar from '../../component/Scrollbar';
import { getAllClient } from '../../service/UserService';
import SearchNotFound from './SearchNotFound';
import { getCustomClient } from '../../service/Library';


const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
];



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // return array.filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        return array.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function SaveDocModal({ docData, bookmarkList, handleClose, handleSave }) {
    const [clientList, setClientList] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateId, setupdateId] = useState('')
    const [useCustomClient, setUseCustomClient] = useState(false)

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const filteredClient = applySortFilter(clientList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredClient.length === 0;


    useEffect(() => {
        clientsHandler();
        checkSelected(docData?.contract_id)
    }, [])

    const clientsHandler = async () => {
        const res = await getAllClient();
        console.log(res, 'resClient');
        
        if (res.length === 0) {
            setUseCustomClient(true)
            customClientsHandler()
        }
        else if (res) {
            setClientList(res);
        }
    };

    const customClientsHandler = async () => {
        const res = await getCustomClient();
        console.log(res.data.data, 'resClient');
        if (res) {
            setClientList(res.data.data);
        }
    };


    const checkSelected = (id) => {
        // let data = bookmarkList?.client_library?.filter((_) => _?.contract_id === id)[0]?.client_id
        let data = bookmarkList?.client_library?.filter((_) => _?.contract_id === id)
        const clientIds = data?.map(item => item.client_id);
        if (clientIds) {
            if (Array.isArray(clientIds)) {
                setSelected(clientIds)
            }
            else {
                setSelected([clientIds])
            }
        }
        else {
            setupdateId('')
            setIsUpdate(false)
        }
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <div>

            <Card>
                {console.log("filteredClient", filteredClient)}
                <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                <TableContainer sx={{ height: "400px", overflow: 'auto' }}>
                    <Table>
                        < TableBody >
                            {filteredClient?.map((row) => {
                                const isItemSelected = selected?.indexOf(row.id) !== -1;
                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                        onClick={(event) => {
                                            handleSave(
                                                isItemSelected,
                                                row?.id,
                                                bookmarkList?.client_library?.filter((_) => _?.client_id === row?.id)[0]?.id
                                            )
                                            handleClick(event, row?.id)
                                        }}
                                    >
                                        <TableCell align="left"> {row.name || row.client_name || '-'}</TableCell>
                                        <TableCell padding="checkbox" >
                                            <Checkbox checked={isItemSelected} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                        {isUserNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Card>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                <Button color="primary" onClick={handleClose}> Cancel </Button>
                {/* <Button variant="contained" onClick={() => handleSave(selected, isUpdate, updateId)} > Save </Button> */}
            </Stack>
        </div >
    )
}

export default SaveDocModal