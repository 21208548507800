export function boldString(str, find) {
  // var re = new RegExp(find, "ig");
  // return str.replace(re, "<b>" + find + "</b>");

  if (!Array.isArray(find)) {
    var re = new RegExp(find, "ig");
    return str?.replace(re, "<b>" + find + "</b>");
  }

  // Use a regular expression to match any of the words in the query array
  const regex = new RegExp(`(${find.join('|')})`, 'gi');

  // Replace matches with bold tags
  const highlightedDesc = str?.replace(regex, match => `<b>${match}</b>`);

  return highlightedDesc

}
