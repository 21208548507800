/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { AiFillCaretDown } from "react-icons/ai";
import Button from "../ui-component/button";
import Logo from "../ui-component/Logo";
import { useAccount } from "./context/AccountProvider";
import { getAllProject } from "../service/CMSService";

function Header({ pages, blogs }) {
  console.log("pages, blogs", pages, blogs);
  const { logout, userDetails } = useAccount();
  const [allProjects, setAllProjects] = useState([]);

  const logoutHandler = () => {
    logout();
    window.location.href = '/';
    localStorage.clear();
  };

  useEffect(() => {
    getAllProjectHandler();
  }, []);


  const getAllProjectHandler = async () => {
    const res = await getAllProject();
    const resData = res?.data;
    if (resData?.status) {
      setAllProjects(resData.data);
    }
  };


  return (
    <nav className=" navbar sticky-top p-0" style={{ height: "80px" }}>
      {console.log("userDetails", userDetails)}
      <div className="container">
        <Stack
          className="navbar_wrp"
          direction={"row"}
          fullWidth="true"
          flexWrap={"noWrap"}
        >
          <Stack direction={"row"} style={{ gap: "150px" }} className="navbar_wrp_left">
            <Logo />
            <button
              className="navbar_toggler hide"
              style={{ display: "none" }}
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#demo"
            >
              <span className="navbar_toggler-icon"></span>
            </button>
            <div className=" navbar_collapse">
              <ul className="navbar_nav">
                <li className="nav_item dropdown">
                  <a className="nav_link" role="button">
                    Products <AiFillCaretDown />
                  </a>
                  <ul className="dropdown-menu ">

                    {allProjects.map((project, i) => (
                      <li key={i}>
                        <a
                          className="dropdown-item"
                          href={`/product/${project.masterData.seoURL}`}
                        >
                          {project.masterData.name}
                        </a>
                      </li>
                    ))}

                  </ul>
                </li>
                <li className="nav_item dropdown">
                  <a className="nav_link" role="button">
                    Who it's For <AiFillCaretDown />
                  </a>
                  <ul className="dropdown-menu animate__animated animate__zoomIn animate__faster">


                    {pages.filter((_) => _.isHeader === true)?.map((item, i) => {
                      return (
                        <li key={i}>
                          <a
                            className="dropdown-item"
                            href={`/forum/${item.seoURL}`}
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}


                  </ul>
                </li>
                <li className="nav_item dropdown">
                  <a
                    className="nav_link nav_text mr-2 text-black d-flex align-items-center"
                    role="button"
                  >
                    Resources <AiFillCaretDown />
                  </a>
                  <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">
                    <li>
                      <a className="dropdown-item" href="/library">
                        Clause Library
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/library">Contract Library</a>
                    </li>
                    <li>
                      <a className="dropdown-item">Case Studies</a>
                    </li>
                    <li>
                      <a className="dropdown-item">Whitepapers</a>
                    </li>
                    <li>
                      <a className="dropdown-item">Blogs</a>
                    </li>
                  </ul>
                </li>
                <li className="nav_item dropdown">
                  <a className="nav_link " role="button">
                    Support <AiFillCaretDown />
                  </a>
                  <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">
                    <li>
                      <a className="dropdown-item" href="#">
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Tech Support
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Sales Desk
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        FAQs
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav_item dropdown">
                  <a href="/library" className="nav_link " role="button">
                    Library
                  </a>
                </li>
                <li className="nav_item dropdown">
                  <a className="nav_link " href="/contactus" role="button">
                    Contact Us
                  </a>
                </li>
                <li className="nav_item dropdown">
                  <a className="nav_link " href="/forum/about-us" role="button">
                    About Us
                  </a>
                </li>
              </ul>
            </div>
          </Stack>



          {!userDetails?.email ?
            <Stack
              direction={"row"}
              alignItems={"center"}
              className="navbar_wrp_right"
              fullWidth="true"
            >
              <Button variant="contained" className=" primary" href="/login" text="Login" />
              <Button className="secondary" text="Signup" />
            </Stack>
            :
            <ul>
              <li className="dropdown aftlog">
                <span className="nav-link nav-text mr-2 text-black d-flex align-items-center" href="#" role="button">
                  {userDetails?.email}
                </span>
                <ul className="dropdown-menu p-0 animate__animated animate__zoomIn animate__faster">
                  <li onClick={() => logoutHandler()}>
                    <a className="dropdown-item" >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          }

        </Stack>
      </div>
    </nav>
  );
}

export default Header;
