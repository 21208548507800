import { Box, Checkbox, SimpleGrid, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import Input from "../../component/Input";
import { createPricing } from "../../service/CMSService";
import { FaRegCircleDot } from "react-icons/fa6";
import SEO from "../../component/SEO";

const Demo = () => {
  const toast = useToast();
  const [pricingDetails, setPricingDetails] = useState({});

  const resetForm = () => {
    setPricingDetails({
      firstName: "",
      lastName: "",
      workEmail: "",
      jobTitle: "",
      company: "",
      employees: "",
      phone: "",
      country: "",
      isAgreed: false,
    });
  };

  const createPricingHandler = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
      isAgreed,
    } = pricingDetails;
    const payload = {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
    };
    if (!isAgreed) {
      return toast({
        title: "Warning.",
        description: "Please accept terms and privacy policy",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const res = await createPricing(payload);
    if (res.status === 200) {
      resetForm();
      toast({
        title: "Success.",
        description: "Thank you for contacting me.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error.",
        description: "Something went worng",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <SEO
        title={"Demo" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box className="container">
        <Box className="free-demo">
          <Box className="left">
            <h5>Start your Free Trial</h5>
            <p>
              No Credit Card Required , No Software to Install
              <br /> With Your 30-Day Trial ,You Get
            </p>
            <ul>
              <li>
                {" "}
                <FaRegCircleDot />
                Kronicle’s MS Word Add-in tool helps knowledge workers
                efficiently assemble, analyse and review contracts, research
                papers and litigation documents.
              </li>
              <li>
                {" "}
                <FaRegCircleDot />
                Users could research and look up information without leaving
                word
              </li>
              <li>
                {" "}
                <FaRegCircleDot />
                The Kronicle Word Add-in have all the essential pre-signature
                tools which contracting professionals would require
              </li>
            </ul>
          </Box>
          <Box className="free-form">
            <h5>Sign Up Now To Start Your Free Sales Trial</h5>
            <form onSubmit={createPricingHandler}>
              <SimpleGrid columns={2} spacing={3}>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        firstName: e.target.value,
                      });
                    }}
                    value={pricingDetails?.firstName}
                    label="First Name"
                  />
                </Box>
                <Box>
                  <Input
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        lastName: e.target.value,
                      });
                    }}
                    value={pricingDetails?.lastName}
                    label="Last Name"
                  />
                </Box>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        workEmail: e.target.value,
                      });
                    }}
                    value={pricingDetails?.workEmail}
                    label="Work Email"
                  />
                </Box>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        jobTitle: e.target.value,
                      });
                    }}
                    value={pricingDetails?.jobTitle}
                    label="Job Title"
                  />
                </Box>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        company: e.target.value,
                      });
                    }}
                    value={pricingDetails?.company}
                    label="Company"
                  />
                </Box>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        phone: e.target.value,
                      });
                    }}
                    value={pricingDetails?.phone}
                    label="Phone"
                  />
                </Box>
                <Box>
                  <Input
                    type="number"
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        employees: e.target.value,
                      });
                    }}
                    value={pricingDetails?.employees}
                    label="Employee"
                  />
                </Box>
                <Box>
                  <Input
                    required={true}
                    onChange={(e) => {
                      setPricingDetails({
                        ...pricingDetails,
                        country: e.target.value,
                      });
                    }}
                    value={pricingDetails?.country}
                    label="Country"
                  />
                </Box>
              </SimpleGrid>
              <Box className="checkbox-agree">
                <Checkbox
                  checked={pricingDetails?.isAgreed}
                  onChange={(e) =>
                    setPricingDetails({
                      ...pricingDetails,
                      isAgreed: e.target.checked,
                    })
                  }
                />{" "}
                <label className="form-check-label mx-2" for="flexCheckDefault">
                  I agree to the{" "}
                  <a href="/forum/terms-and-conditions" target="_blank">
                    Terms of Use{" "}
                  </a>
                  and
                  <a href="/forum/privacy-policy" target="_blank">
                    {" "}
                    Privacy Policy
                  </a>
                </label>
              </Box>
              <Box className="button">
                <button className="btn btn-blue" type="submit">
                  Start My Free Trial
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Demo;
