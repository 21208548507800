import React, { useEffect, useRef, useState } from "react";
import { Box, InputAdornment, OutlinedInput } from "@mui/material";
import { IconSearch } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import { MdSearch } from "react-icons/md";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  genericFilterHandler,
  setSearchItem,
  setSearchValue,
} from "../../redux/searchSlice";
import { setIsLoading } from "../../redux/librarySlice";
import { Loader } from "rsuite";

const Search = ({ activeTab, setPreviewShow, fromSearchResult, selectedQuery, contractCount, clauseCount, definitionCount, getSearchQuery }) => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState(selectedQuery)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchValue, searchItemList, isLoading } = useSelector((state) => state.search);

  // searchValue useMemo

  const searchValueMemo = React.useMemo(() => searchValue, [searchValue]);

  const debounced = useDebouncedCallback((value) => {
    getSearchQuery(value)
    dispatch(setSearchValue(value));
    if (!value) {
      dispatch(setSearchItem([]));
    }
    setPreviewShow && setPreviewShow(false)
    dispatch(genericFilterHandler(activeTab, value, "search"));
  },
    // delay in ms
    1000
  );


  const handleSearch = (value) => {
    setSearchText(value)
    getSearchQuery(value)
    dispatch(setIsLoading(true))
    debounced(value)
  }

  const goToSearchPage = (value) => {
    setSearchText(value?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
    setSearchQuery(value?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
    navigate(`/search-result/${value}`);

    dispatch(setSearchItem([]));
  };

  useEffect(() => {
    setSearchText(selectedQuery?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase()))
    if (selectedQuery) {
      dispatch(setSearchValue(selectedQuery));
    }
  }, [dispatch, selectedQuery, searchQuery]);


  const [isClassActive, setClassActive] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setClassActive(false);
    }
  };

  const BoldWordInText = ({ text, targetWord }) => {
    // Split the text into parts based on the target word
    const parts = text?.split(new RegExp(`(${targetWord})`, 'gi'));

    return (
      <p>
        {parts?.map((part, index) =>
          part?.toLowerCase() === targetWord?.toLowerCase() ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          )
        )}
      </p>
    );
  };



  return (
    <Box className="search-box">
      {console.log("searchValueMemo?.", searchValueMemo?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase()))}
      <OutlinedInput
        sx={{
          ...theme.typography.customInput,
          width: "100%",
          fontSize: "24px",
          margin: "0",
          fontWeight: 500,
          paddingRight: 0,
          borderColor: '#005287',
          "& input::placeholder": {
            fontSize: "20px",
            fontWeight: 500,
            color: '#909090',
          },
          "& input": {
            borderRadius: "4px",
            borderColor: '#005287',
          },
          "fieldset":
          {
            borderColor: '#005287',
            color: '#505050',
          }
        }}
        autoComplete='off'
        id="input-search-header"
        placeholder="Search..."
        onChange={(e) => handleSearch(e.target.value)}
        onClick={() => setClassActive(true)}
        value={searchText}
        defaultValue={searchValueMemo?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase()) || searchQuery?.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}
        endAdornment={
          <InputAdornment position="start" className="search_icon" >
            {console.log("isLoading", isLoading)}
            {isLoading ? <Loader />
              :
              <IconSearch
                size={30}
                sx={{ height: "100%", cursor: "pointer" }}
                // color="#005287"
                onClick={() => searchValueMemo?.trim()?.length ? goToSearchPage(searchValueMemo) : ''}
              />
            }
          </InputAdornment>
        }
        aria-describedby="search-helper-text"
        inputProps={{ "aria-label": "weight" }}
      />
      {console.log("activeTab", activeTab)}
      {searchItemList?.length > 0 && searchValueMemo?.trim()?.length ? (
        activeTab?.contractType ? (
          <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
            <ul>
              {searchItemList?.map((item) => (
                <li
                  onClick={() => goToSearchPage(item?.contract_title)}
                  className="search-item"
                >
                  <MdSearch />
                  <p>
                    <BoldWordInText text={item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())} targetWord={searchValueMemo} />
                    {/* {item?.contract_title.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}  */}
                  </p>
                </li>
              ))}
            </ul>
          </Box>
        )
          :
          activeTab?.clause ? (
            <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
              <ul>
                {searchItemList?.map((item) => (
                  <li
                    onClick={() => goToSearchPage(item?.heading)}
                    className="search-item"
                  >
                    <MdSearch />
                    <p> {item?.heading} </p>
                  </li>
                ))}
              </ul>
            </Box>
          )
            :
            activeTab?.definition ? (
              <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
                <ul>
                  {searchItemList?.map((item) => (
                    <li
                      onClick={() => goToSearchPage(item?.define_term)}
                      className="search-item"
                    >
                      <MdSearch /> <p>  {item?.define_term} </p>
                    </li>
                  ))}
                </ul>
              </Box>
            )
              :
              window.location.pathname === '/' && searchValueMemo?.trim()?.length ? (
                <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
                  <ul>
                    <li className="search-item">
                      No Result found
                    </li>
                  </ul>
                </Box>
              ) : null
      ) :
        searchItemList?.length === 0 && !isLoading && searchValue?.length > 0 &&
        <>
          {/* <Box ref={divRef} className={isClassActive ? 'search-result' : 'hide-class search-result'}>
            <ul>
              <li className="search-item" >
                <p style={{ textAlign: "center" }}>
                  Search Result Not Found
                </p>
              </li>
            </ul>
          </Box> */}
        </>

      }
    </Box>
  );
};

export default Search;
