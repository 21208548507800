import axios from 'axios';

export const getAllUsers = async () => {
    try {
        const { data } = await axios.get(`users`);
        return data;
    } catch (err) {
        return err;
    }
};

export const getMyUserDetails = async () => {
    try {
        const { data } = await axios.get(`users/my-details`);
        return data;
    } catch (err) {
        return err;
    }
};


//get all client
export const getAllClient = async () => {
    try {
        const { data } = await axios.get(`clients`);
        return data;
    } catch (err) {
        return err;
    }
};


//new api for assign subscription from rootuser


export const userSignup = async (payload) => {
    try {
        const { data } = await axios.post(`users/signup`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


