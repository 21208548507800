import axios from "axios";


const instance = axios.create({
  baseURL: process.env.REACT_APP_CONTRACT_API_URL,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
    // Add other headers as needed
  },
});

//library 


export const getCustomClient = async () => {
  try {
    const res = await instance.get(`custom-client`);
    return res;
  } catch (error) {
    return error;
  }
};

export const createCustomClient = async (payload) => {
  try {
    const res = await instance.post(`custom-client`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteCustomClient = async (id) => {
  try {
    const res = await instance.delete(`custom-client/${id}`,);
    return res;
  } catch (error) {
    return error;
  }
};





export const fetchAllLibraryFilter = async (payload) => {
  try {
    const res = await instance.post(`boomarks-filter`, payload);
    return res;
  } catch (error) {
    return error;
  }
};

