/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-return-await */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Pool from '../../utils/UserPool';

const AccountContext = createContext();

export function useAccount() {
    return useContext(AccountContext);
}

export default function AccountProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [userPool, setUserPool] = useState({});

    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });

    const authenticate = async (Username, Password) =>
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            setUserPool(user);
            const authDetails = new AuthenticationDetails({
                Username,
                Password
            });
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    console.log(data);
                    resolve(data);
                },
                onFailure: (err) => {
                    console.log(err);
                    reject(err.message || JSON.stringify(err));
                },
                newPasswordRequired: (data, requiredAttr) => {
                    console.log(requiredAttr);
                    const successData = {
                        isFirstLogin: true,
                        userData: data
                    };
                    resolve(successData);
                }
            });
        });

    const register = (email, password, given_name, family_name, nickname, gender, phone_number, salutation) => {
        const attributeList = [
            new CognitoUserAttribute({ Name: 'email', Value: email }),
            new CognitoUserAttribute({
                Name: 'custom:salutation',
                Value: salutation
            }),
            new CognitoUserAttribute({ Name: 'given_name', Value: given_name }),
            new CognitoUserAttribute({ Name: 'family_name', Value: family_name }),
            new CognitoUserAttribute({ Name: 'nickname', Value: nickname }),
            new CognitoUserAttribute({ Name: 'phone_number', Value: phone_number }),
            new CognitoUserAttribute({ Name: 'gender', Value: gender })
        ];

        return new Promise((resolve, reject) => {
            Pool.signUp(email, password, attributeList, null, (err, data) => {
                if (err) {
                    console.log(err);
                    reject(err.message || JSON.stringify(err));
                }
                console.log(data);
                resolve(data);
            });
        });
    };

    const confirmRegister = (email, code) => {
        const userData = {
            Username: email,
            Pool
        };
        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmRegistration(code, true, (err, res) => {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                }
                resolve(res);
            });
        });
    };
    const resendVerificationCode = (email, code) => {
        const userData = {
            Username: email,
            Pool
        };
        const cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.resendConfirmationCode((err, res) => {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                }
                resolve(res);
            });
        });
    };

    const changePassword = (currentPassword, newPassword) => {
        const user = {
            Username: userData.email,
            Pool
        };
        const cognitoUser = new CognitoUser(user);
        return new Promise((resolve, reject) => {
            cognitoUser.changePassword(currentPassword, newPassword, (err, res) => {
                if (err) {
                    reject(err.message || JSON.stringify(err));
                }
                resolve(res);
            });
        });
    };
    const changeFirstLoginPassword = (newPassword, userAttr) => {
        return new Promise((resolve, reject) => {
            userPool.completeNewPasswordChallenge(newPassword, userAttr, {
                onSuccess: (result) => {
                    // login
                    resolve(result);
                },
                onFailure: (err) => {
                    console.log(err);
                    reject(err.message || JSON.stringify(err));
                }
            });
        });
    };

    useEffect(() => {
        getSession()
            .then((session) => {
                console.log(session, 'session');
                const {
                    idToken: { payload }
                } = session;
                setIsLoggedIn(true);
                setUserData(payload);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            setIsLoggedIn(false);
        }
    };

    function resetPassword(email) {
        const user = {
            Username: email,
            Pool
        };
        const cognitoUser = new CognitoUser(user);

        return new Promise((resolve, reject) => {
            // call forgotPassword on cognitoUser
            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    console.log('call result: ' + result);
                    resolve(result);
                },
                onFailure: function (err) {
                    reject(err.message || JSON.stringify(err));
                }
            });
        });
    }

    // confirmPassword can be separately built out as follows...
    function confirmPassword(email, verificationCode, newPassword) {
        const user = {
            Username: email,
            Pool
        };
        const cognitoUser = new CognitoUser(user);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onFailure(err) {
                    reject(err.message || JSON.stringify(err));
                },
                onSuccess() {
                    resolve(true);
                }
            });
        });
    }

    console.log(isLoggedIn, 'isLoggedIn');

    const values = {
        authenticate,
        register,
        confirmRegister,
        resendVerificationCode,
        logout,
        setIsLoggedIn,
        setUserData,
        changePassword,
        setUserDetails,
        isLoggedIn,
        userData,
        userDetails,
        resetPassword,
        confirmPassword,
        changeFirstLoginPassword
    };
    return <AccountContext.Provider value={values}>{children}</AccountContext.Provider>;
}
