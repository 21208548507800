/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Header from '../../component/Header'
import { Outlet } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import { getMyUserDetails } from '../../service/UserService';
import { useDispatch } from 'react-redux';
import { USER_DATA } from '../../redux/actions';
import { useAccount } from '../../component/context/AccountProvider';
import { getAllBlog, getAllPage } from '../../service/CMSService';

function MainLayout() {
    const theme = useTheme();
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { setUserDetails } = useAccount();

    useEffect(() => {
        setLoading(false);
        if (localStorage.getItem('token')) {
            getMyUserDetailsHandler();
        }
    }, []);

    const getMyUserDetailsHandler = async () => {
        const res = await getMyUserDetails();
        dispatch({ type: USER_DATA, payload: res });
        console.log(res, 'resData');
        setUserDetails(res);
    };


    const [pages, setPages] = useState([]);
    const [blogs, setBlogs] = useState([]);

    const getAllPageHanlder = async () => {
        const res = await getAllPage();
        setPages(res.data.data || []);
    };

    useEffect(() => {
        getAllPageHanlder();
        getAllBlogHandler();
    }, []);

    const getAllBlogHandler = async () => {
        const res = await getAllBlog();
        setBlogs(res.data.data || []);
    };




    return (
        <>
            <Header pages={pages} blogs={blogs} />
            <div className="content-wrap gray_bg">
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout