import ChipInput from 'material-ui-chip-input';
import React from 'react'

function ChipTagSelect({ handleChange, ddname, value }) {
    const [tags, setTags] = React.useState(value);


    console.log("vvvvvvvv", value);


    const handleAddChip = (chip) => {
        let newTag = [...tags, chip]
        setTags([...tags, chip]);
        debugger
        handleChange(newTag, ddname)
    };
    const handleDeleteChip = (chip, index) => {
        // setTags(tags.filter((tag) => tag !== chip));
    };


    return (
        <div className="App">
            <ChipInput
                variant="outlined"
                color='primary'
                size="small"
                className='taginput'
                hiddenLabel
                alwaysShowPlaceholder={true}
                dataSource={value}
                value={value}
                placeholder={ddname}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
            />
        </div>
    );
}

export default ChipTagSelect