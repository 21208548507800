import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchAllFAQs } from "../../service/CMSService";
import SEO from "../../component/SEO";

const FAQs = () => {
  const [allFaqs, setAllFaqs] = useState([]);

  const fetchAllFAQsHandler = async () => {
    const res = await fetchAllFAQs();
    setAllFaqs(res?.data?.data);
  };

  useEffect(() => {
    fetchAllFAQsHandler();
  }, []);

  console.log(allFaqs, "allFaqs");

  return (
    <>
      <SEO
        title={"Faqs" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading className="animate__animated animate__fadeInDown animate__faster">FAQs</Heading>
          <Text>Tech Support and Pricing FAQs</Text>
        </Box>
      </Box>
      <div className="container">
        <div className="faq">

          <Box mt={5} >
            <Heading mb={10} className="faq_heading">Tech Support FAQs</Heading>
            <Accordion allowToggle>
              {allFaqs.filter((_) => _.techsupport === true).map((faq, i) => (
                <AccordionItem className="faq_item" key={i}>
                  <h2>
                    <AccordionButton >
                      <Box fontSize="2xl" as="span" flex="1" textAlign="left">
                        {faq.questions}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} className="faq_item_ans">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faq?.answer,
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>

          <Box mt={14} >
            <Heading mb={10} className="faq_heading">Pricing FAQs</Heading>
            <Accordion allowToggle>
              {allFaqs.filter((_) => _.pricing === true).map((faq, i) => (
                <AccordionItem className="faq_item" key={i}>
                  <h2>
                    <AccordionButton >
                      <Box fontSize="2xl" as="span" flex="1" textAlign="left">
                        {faq.questions}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} className="faq_item_ans">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: faq?.answer,
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        </div>

      </div>
    </>
  );
};

export default FAQs;
