import {
  Box,
  Button,
  Checkbox,
  Heading,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Input from "../../component/Input";
import {
  BsEnvelopeAtFill,
  BsFilePerson,
  BsFillPersonBadgeFill,
  BsFillPersonFill,
  BsFillPhoneFill,
} from "react-icons/bs";
import {
  BiWorld
} from "react-icons/bi";
import SEO from "../../component/SEO";
import { createPricing } from "../../service/CMSService";

const Pricing = () => {
  const toast = useToast();
  const [pricingDetails, setPricingDetails] = useState({});

  const resetForm = () => {
    setPricingDetails({
      firstName: "",
      lastName: "",
      workEmail: "",
      jobTitle: "",
      company: "",
      employees: "",
      phone: "",
      country: "",
      isAgreed: false,
    });
  };

  const createPricingHandler = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
      isAgreed,
    } = pricingDetails;
    const payload = {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
    };
    if (!isAgreed) {
      return toast({
        title: "Warning.",
        description: "Please accept terms and privacy policy",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const res = await createPricing(payload);
    if (res.status === 200) {
      resetForm();
      toast({
        title: "Success.",
        description: "Thank you for contacting me.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      return toast({
        title: "Error.",
        description: "Something went worng",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <SEO
        title={"Pricing" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box className="pricing">
        <Box
          display="flex"
          p={5}
          alignItems="center"
          justifyContent="center"
          background="rgba(9,99,157,0.8)"
          color="#fff"
          className="pricing-head"
        >
          <Box textAlign="center" className="heading">
            <Heading className="animate__animated animate__fadeInDown animate__faster">
              Pricing
            </Heading>
          </Box>
        </Box>
        <Box className="container">
          <Box className="body">
            <div className="body-head">
              <h4>CUSTOMISE YOUR PLAN</h4>
              <p>
                At Kronicle, we value your time and resources. Kindly share your
                requirements with us, so that we could assist you in developing
                a customized plan that works best for you.
              </p>
              <p>
                Fill out the form below and we will get in touch with you as
                soon as possible.
              </p>
            </div>
            <Box className="form-box">
              <h5>Sign up now for a customized pricing plan.</h5>
              <form onSubmit={createPricingHandler}>
                <SimpleGrid columns={2} spacing={5}>
                  <Box>
                    <Input
                      required={true}
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          firstName: e.target.value,
                        });
                      }}
                      value={pricingDetails?.firstName}
                      label="First Name"
                      leftIcon={<BsFillPersonFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          lastName: e.target.value,
                        });
                      }}
                      value={pricingDetails?.lastName}
                      label="Last Name"
                      leftIcon={<BsFillPersonFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      type="email"
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          workEmail: e.target.value,
                        });
                      }}
                      value={pricingDetails?.workEmail}
                      label="Work Email"
                      leftIcon={<BsEnvelopeAtFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          jobTitle: e.target.value,
                        });
                      }}
                      value={pricingDetails?.jobTitle}
                      label="Job Title"
                      leftIcon={<BsFilePerson />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          company: e.target.value,
                        });
                      }}
                      value={pricingDetails?.company}
                      label="Company"
                      leftIcon={<BsFillPersonBadgeFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          phone: e.target.value,
                        });
                      }}
                      value={pricingDetails?.phone}
                      label="Phone"
                      leftIcon={<BsFillPhoneFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      type="number"
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          employees: e.target.value,
                        });
                      }}
                      value={pricingDetails?.employees}
                      label="Employee"
                      leftIcon={<BsFillPersonFill />}
                    />
                  </Box>
                  <Box>
                    <Input
                      required={true}
                      onChange={(e) => {
                        setPricingDetails({
                          ...pricingDetails,
                          country: e.target.value,
                        });
                      }}
                      value={pricingDetails?.country}
                      label="Country"
                      leftIcon={<BiWorld />}
                    />
                  </Box>
                </SimpleGrid>
                <Box className="checkbox-agree">
                  <Checkbox
                    onChange={(e) =>
                      setPricingDetails({
                        ...pricingDetails,
                        isAgreed: e.target.checked,
                      })
                    }
                  />{" "}
                  <label
                    className="form-check-label mx-2" for="flexCheckDefault">
                    I agree to the{" "}
                    <a href="/forum/terms-and-conditions" target="_blank">
                      Terms of Use{" "}
                    </a>
                    and
                    <a href="/forum/privacy-policy" target="_blank">
                      {" "}
                      Privacy Policy
                    </a>
                  </label>
                </Box>
                <Box className="button">
                  <Button
                    disabled={!pricingDetails?.isAgreed}
                    className="btn btn-blue"
                    type="submit"
                  >
                    Contact Me With My Pricing Plan
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Pricing;
