import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
// project imports

const Home = Loadable(lazy(() => import('../pages/Home')));
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('./ResetPassword')));
const ForgetPassword = Loadable(lazy(() => import('./ForgetPassword')));
const PasswordSuccess = Loadable(lazy(() => import('./PasswordSuccess')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword/changePassword')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterSign = Loadable(lazy(() => import('../pages/authentication/RegisterSign')));


// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = [

    {
        path: '/',
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/register-signup',
        element: <RegisterSign />
    },
    {
        path: '/register',
        element: <Register />
    },
    // {
    //     path: '/library',
    //     element: <Library />
    // },
    {
        path: '/ChangePassword',
        element: <ChangePassword />
    },
    {
        path: '/forgetPassword',
        element: <ForgetPassword />
    },
    {
        path: '/resetPassword',
        element: <ResetPassword />
    },
    {
        path: '/passwordSuccess',
        element: <PasswordSuccess />
    },
];

export default AuthenticationRoutes;
