/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { Button, Card, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { getAllsearchFilters, setSelectedFilters } from "../../redux/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ModalComponent from "../../component/Modal";

function MiuiSelectPopup({ fetchListData, close, open, placeholder, ddname, activeTab, query, setSelected, genericFilterHandler, value }) {
  const dispatch = useDispatch()
  const containerRef = useRef(null);
  const loadRef = useRef(null);
  const { cikList, accessionNumberList, formsList } = useSelector((state) => state.search);
  const [selectedOptions, setSelectedOptions] = useState(value || []);
  const [loadOptions, setLoadOptions] = useState();

  useEffect(() => {
    if (ddname === 'CIK') {
      setLoadOptions(cikList)
      loadRef.current = cikList;
    }

    if (ddname === 'AccessionNumber') {
      setLoadOptions(accessionNumberList)
      loadRef.current = accessionNumberList;
    }

    if (ddname === 'Forms') {
      setLoadOptions(formsList)
      loadRef.current = formsList;
    }

  }, [cikList, accessionNumberList, formsList])



  useEffect(() => {
    setSelectedOptions(value || [])
    setChecked(value.map(item => item.name) || [])
  }, [value])


  const [checked, setChecked] = useState([0]);

  const handleToggle = (item, value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    const currenSelectedIndex = selectedOptions.indexOf(item);
    const newSelected = [...selectedOptions];

    if (currentIndex === -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(currenSelectedIndex, 1);
    }
    setSelectedOptions(newSelected);

  };


  const handleSave = () => {
    dispatch(setSelected(selectedOptions));
    dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
    dispatch(genericFilterHandler(activeTab, query));
    close()
  }




  const handleScroll = () => {
    const container = containerRef.current;
    if (Math.floor(container.scrollHeight - container.scrollTop) === container.clientHeight) {
      debugger
      console.log("loadRef", loadRef);
      dispatch(getAllsearchFilters(activeTab, query, loadRef.current?.data?.length + 10, ddname))
    }
  };


  useEffect(() => {
    debugger
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ModalComponent
        width="calc(100% - 30px)"
        maxWidth="400px"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={open}
        handleClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Card>
            <Stack>
              <h4> Select {placeholder} </h4>
            </Stack>
          </Card>

          <List
            ref={containerRef}
            sx={{ height: "400px", overflow: 'auto', width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {loadOptions?.data.length ?
              loadOptions?.data?.map((item, i) => {
                const labelId = `checkbox-list-label-${item.name}`;
                return (
                  <ListItem
                    key={i}
                    disablePadding
                  >
                    <ListItemButton onClick={handleToggle(item, item.name)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(item?.name) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${item.name}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })
              :
              <ListItem> No Data Found </ListItem>
            }
          </List>

          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
            <Button color="primary" onClick={close}> Cancel </Button>
            {loadOptions?.data.length ? <Button variant="contained" onClick={() => handleSave()}> Select </Button> : ''}
          </Stack>

        </div>
      </ModalComponent>
    </>
  );
};


export default MiuiSelectPopup

