
// project imports
import Home from '../pages/Home';
import SearchResult from '../pages/SearchResult';
import MainLayout from '../layout/MainLayout';
import Library from '../pages/Library';
import StaticPage from '../pages/StaticPage';
import Plan from '../pages/Plan/Plan';
import BlogRenderPage from '../pages/BlogRenderPage';
import BlogList from '../pages/Blog';
import ProductRenderPage from '../pages/ProductRenderPage';
import FAQs from '../pages/FAQs';
import ContactUs from '../pages/ContactUs';
import CaseStudy from '../pages/CaseStudy';
import Pricing from '../pages/Pricing';
import Demo from '../pages/Demo';
import SalesDesk from '../pages/SalesDesk';
import TechSupport from '../pages/TechSupport';
import InstallNow from '../pages/InstallNow';
import ThankYou from '../pages/ThankYou';
// import PurchasePlans from '../views/pages/OrgSubscription/PurchasePlans/PurchasePlans';
// import ContractLibrary from '../views/pages/authentication/contractLibrary/contractLibrary';
// dashboard routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },
        {
            path: "/search-result/:query",
            element: <SearchResult />,
        },
        {
            path: '/Library',
            element: <Library />
        },
        {
            path: '/Library/:url',
            element: <Library />
        },
        {
            path: "/forum/:url",
            element: <StaticPage />,
        },
        {
            path: "/plans",
            element: <Plan />,
        },
        {
            path: "/blog/:url",
            element: <BlogRenderPage />,
        },
        {
            path: "/blogs",
            element: <BlogList />,
        },
        {
            path: "/product/:url",
            element: <ProductRenderPage />,
        },
        {
            path: "/faqs",
            element: <FAQs />,
        },
        {
            path: "/contactus",
            element: <ContactUs />,
        },
        {
            path: "/casestudies",
            element: <CaseStudy />,
        },
        {
            path: "/pricing",
            element: <Pricing />,
        },
        {
            path: "/demo",
            element: <Demo />,
        },
        {
            path: "/salesdesk",
            element: <SalesDesk />,
        },
        {
            path: "/techsupport",
            element: <TechSupport />,
        },
        {
            path: "/installnow",
            element: <InstallNow />,
        },
        {
            path: "/thankyou",
            element: <ThankYou />,
        },
    ]
};

export default MainRoutes;
