/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, Checkbox, Container, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  MdArrowBack,
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowForward,
  MdClose,
  MdRemoveRedEye,
  MdOutlineBookmarkAdd
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import {
  fetchAcessionNumberHandler,
  fetchCIKHandler,
  fetchClausesHandler,
  fetchCompanyHandler,
  fetchContractTitleHandler,
  fetchCountryHandler,
  fetchDepartmentHandler,
  fetchEntityTypeHandler,
  fetchExhibitIDHandler,
  fetchFormsHandler,
  fetchGoverningLawHandler,
  fetchIndustryHandler,
  fetchSearchDataHnadler,
  fetchStateOfIncorporationHandler,
  fetchStockExchangesHandler,
  genericFilterHandler,
  setDocData,
  setExcludeKeyword,
  setIncludeKeyword,
  setRemoveAllFilters,
  setSearchResult,
  setSelectedAccessionNumberList,
  setSelectedCIKList,
  setSelectedClause,
  setSelectedContractTitle,
  setSelectedCountry,
  setSelectedDate,
  setSelectedDepartment,
  setSelectedEntityTypeList,
  setSelectedExcludeKeyword,
  setSelectedExhibitList,
  setSelectedFilters,
  setSelectedFormsList,
  setSelectedGoverningLaw,
  setSelectedIncludeKeyword,
  setSelectedIndustry,
  setSelectedStockExchangesList,
  setSelectedstateOfIncorporationList,
} from "../../redux/searchSlice";
import { boldString } from "../../utils/commonFunctions";
import Search from "../../component/Search";
import { DateRangePicker, Loader, SelectPicker, Stack } from "rsuite";
import styled from "styled-components";
import { CgScreenShot } from "react-icons/cg";
import ViewFullScreen from "./ViewFullScreen";
import { useAccount } from "../../component/context/AccountProvider";
import { createBookmark, getAllbookmarkList, removeBookmark } from "../../service/BookmarkService";
import Toast from "../../component/Toast";
import SaveBM from "./SaveBM";
import MiuiAsyncSelect from "../../ui-component/MiuiAsyncSelect";
import { useDebouncedCallback } from "use-debounce";
import ModalComponent from "../../component/Modal";
import MiuiSelectPopup from "../../ui-component/MiuiSelectPopup";
import { Cancel } from "@mui/icons-material";
import { getSigleContarctData } from "../../service/Global";
import ChipTagSelect from "../../ui-component/ChipTagSelect";
import ChipInput from "material-ui-chip-input";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#005287',
  backgroundColor: 'transparent',
  borderColor: '#005287'
}));

const data = [
  { label: "Sort by - Relevance", value: "relevance" },
  { label: "Sort by - Latest", value: "newest" },
  { label: "Sort by - Older", value: "oldest" },
]

const SearchResult = () => {
  const { userDetails } = useAccount();
  const rootRef = useRef(null);
  const divRef = useRef(null);
  const previewDivRef = useRef(null);
  const { query } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const { docData, isLoading, searchResult, inclueKeyword,
    excludeKeyword, searchResultCount, searchPageCount, governingLawList,
    selectedFilters, contractCount, clauseCount, definitionCount,
    departmentList, industryList, contractTitleList, clausesList, cikList, accessionNumberList,
    entityTypeList, formsList, stockExchangesList, countryList, stateOfIncorporationList, exhibitIDList
  } = useSelector((state) => state.search);
  let searchData = useSelector((state) => state.search);
  const [tabValue, setTabValue] = useState(0);
  const [previewShow, setPreviewShow] = useState(false);
  const [moreFilter, setMoreFilter] = useState(false);
  const [viewFullViewer, setViewFullViewer] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const [customBookMarkModal, setCustomBookMarkModal] = useState(false)
  const [bookmarkList, setBookmarkList] = useState([])


  // const [activeTab, setActiveTab] = useState({
  //   contractType: true,
  //   clause: false,
  //   definition: false,
  // });
  const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem('activeTab')));
  const [showSaveDD, setShowSaveDD] = useState(false)

  const [toastData, setToastData] = useState({
    open: false,
    message: '',
    status: ''
  });

  const handleCloseToast = () => {
    setToastData((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    setSearchValue(query);
    let activeTabData = JSON.parse(localStorage.getItem('activeTab'))
    console.log("activeTabData", activeTabData);
    if (activeTabData?.contractType) {
      setTabValue(0)
    }
    if (activeTabData?.clause) {
      setTabValue(1)
    }
    if (activeTabData?.definition) {
      setTabValue(2)
    }

    const payload = {
      _query: query,
      _index: activeTab?.contractType
        ? "contracts"
        : activeTab?.clause
          ? "clauses"
          : "definitions",
      filters: {}
    };
    if (searchParams.get("company")) {
      payload.filters = {
        ...payload.filters,
        company: [+searchParams.get("company")],
      };
    }
    if (searchParams.get("industry")) {
      payload.filters = {
        ...payload.filters,
        industry: [+searchParams.get("industry")],
      };
    }
    if (searchParams.get("governing_law")) {
      payload.filters = {
        ...payload.filters,
        governing_law: [+searchParams.get("governing_law")],
      };
    }
    dispatch(fetchSearchDataHnadler(payload));
  }, [dispatch, query, searchParams]);


  useEffect(() => {
    dispatch(genericFilterHandler(activeTab, query));
  }, [activeTab]);

  useEffect(() => {
    if (!localStorage.getItem('activeTab')) {
      localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
      setActiveTab({ contractType: true, clause: false, definition: false })
    }
    fetchBookmarkList()
  }, [])

  useEffect(() => {
    if (!searchResult?.results?.length) {
      setPreviewShow(false)
    }

  }, [searchResult])


  useEffect(() => {
    if (searchResult?.results?.length) {
      if (activeTab?.contractType) {
        let newData = { ...searchResult?.contract[searchResult.results[0]._id], ...searchResult?.results[0] }
        dispatch(setDocData(newData));
      }
      else {
        handleDocDataonLoad()
      }
    }
  }, [searchResult?.results?.length])


  const handleDocDataonLoad = async () => {
    let previewData = await getSigleContarctData(searchResult.results[0].contract_id);
    let newData = { ...previewData.data, ...searchResult.results[0] }
    dispatch(setDocData(newData));
  }


  const onSelectHandler = async (item, index, name) => {
    if (name === 'clause' || name === 'definition') {
      setPreviewShow(true);
      handleScrollToTop()
      setSaveModal(false)
      setCustomBookMarkModal(false)
      let previewData = await getSigleContarctData(item?.contract_id);
      let newData = { ...previewData.data, ...item }
      dispatch(setDocData(newData));
      const _temp = [...searchResult.results];
      // add selected true to selected item using map
      const temp = _temp?.map((el, i) => {
        if (i === index) {
          return { ...el, selected: true };
        } else {
          return { ...el, selected: false };
        }
      });
      dispatch(setSearchResult({ ...searchResult, 'results': temp }));
    }
    else {
      setPreviewShow(true);
      handleScrollToTop()
      setSaveModal(false)
      setCustomBookMarkModal(false)
      let previewData = searchResult?.contract[item?.contract_id]
      let newData = { ...previewData, ...item }
      dispatch(setDocData(newData));
      const _temp = [...searchResult.results];
      // add selected true to selected item using map
      const temp = _temp?.map((el, i) => {
        if (i === index) {
          return { ...el, selected: true };
        } else {
          return { ...el, selected: false };
        }
      });
      dispatch(setSearchResult({ ...searchResult, 'results': temp }));
    }

  };

  const handleRemoveSelected = (arrayType, idToRemove) => {
    setPreviewShow(false)
    if (selectedFilters[arrayType]) {
      const updatedArray = selectedFilters[arrayType].filter(item => item._id !== idToRemove);

      debugger
      let finalData = { ...selectedFilters, [arrayType]: updatedArray };

      dispatch(setSelectedFilters({ replaceData: finalData }));
      if (arrayType === 'Industry') {
        dispatch(setSelectedIndustry(updatedArray))
      }
      else if (arrayType === 'ContractType') {
        dispatch(setSelectedContractTitle(updatedArray))
      }
      else if (arrayType === 'Department') {
        dispatch(setSelectedDepartment(updatedArray))
      }
      else if (arrayType === 'Clauses') {
        dispatch(setSelectedClause(updatedArray))
      }
      else if (arrayType === 'GoverningLaw') {
        dispatch(setSelectedGoverningLaw(updatedArray))
      }
      else if (arrayType === 'Country') {
        dispatch(setSelectedCountry(updatedArray))
      }
      else if (arrayType === 'AccessionNumber') {
        dispatch(setSelectedAccessionNumberList(updatedArray))
      }
      else if (arrayType === 'EntityType') {
        dispatch(setSelectedEntityTypeList(updatedArray))
      }
      else if (arrayType === 'Forms') {
        dispatch(setSelectedFormsList(updatedArray))
      }
      else if (arrayType === 'StockExchanges') {
        dispatch(setSelectedStockExchangesList(updatedArray))
      }
      else if (arrayType === 'StateOfIncorporation') {
        dispatch(setSelectedstateOfIncorporationList(updatedArray))
      }
      else if (arrayType === 'ExhibitID') {
        dispatch(setSelectedExhibitList(updatedArray))
      }
      else if (arrayType === 'CIK') {
        dispatch(setSelectedCIKList(updatedArray))
      }
      else if (arrayType === 'include') {
        const updatedSelectInclude = searchData?.includeKeyword.filter(item => item !== idToRemove);
        dispatch(setIncludeKeyword(updatedSelectInclude))
        dispatch(setSelectedIncludeKeyword(updatedArray.map(obj => obj.name)))
      }
      else if (arrayType === 'exclude') {
        const updatedSelectExclude = searchData?.includeKeyword.filter(item => item !== idToRemove);
        dispatch(setExcludeKeyword(updatedSelectExclude))
        dispatch(setSelectedExcludeKeyword(updatedArray.map(obj => obj.name)))
      }
      dispatch(genericFilterHandler(activeTab, query));
    }
  }

  const handleRemoveAllSelected = () => {
    setPreviewShow(false)
    dispatch(setRemoveAllFilters([]));
    dispatch(genericFilterHandler(activeTab, query));
  }

  const handleDate = (date) => {
    setPreviewShow(false)
    dispatch(setSelectedDate(date));
    dispatch(genericFilterHandler(activeTab, query));
  }

  const handleRemove = () => {
    dispatch(setSelectedDate(''));
    dispatch(genericFilterHandler(activeTab, query));
  }


  const handleBookmark = async (e, item) => {
    const { name, value, checked } = e.target;
    if (!checked) {
      const res = await removeBookmark(item.id);
      if (res.message === 'Bookmark deleted successfully.') {
        setToastData({
          open: true,
          status: 'success',
          message: res.message || `success`
        });
        fetchBookmarkList();
      }
      else {
        setToastData({
          open: true,
          status: 'error',
          message: res.message || `something went wrong`
        });
      }
    }
    else {
      let payload = {
        contract_id: docData?.contract_id,
        library_type: name,
        bookmark_type: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions",
        bookmark_name: activeTab?.contractType ? docData?.contract_title : activeTab?.clause ? docData.heading : docData.define_term,
      }
      const res = await createBookmark(payload);
      if (res.message === 'Bookmark Created Successfully.') {
        setToastData({
          open: true,
          status: 'success',
          message: res.message || `success`
        });
        fetchBookmarkList()
      }
      else {
        setToastData({
          open: true,
          status: 'error',
          message: res.message || `something went wrong`
        });
      }
    }
  }

  //fetch bookmark list
  const fetchBookmarkList = async () => {
    const res = await getAllbookmarkList();
    if (res.data) {
      setBookmarkList(res?.data)
    }
  }

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (divRef?.current && !divRef?.current.contains(e.target)) {
      // Clicked outside the div, so remove the class
      setShowSaveDD(false)
    }
  };



  const handleWord = (value, name) => {
    debugger
    setPreviewShow(false)
    if (name === 'Include Keywords') {
      const objectsArray = [...searchData?.includeKeyword, value]?.map((str, index) => {
        if (str.trim() !== '') {
          return {
            "_id": str.trim(),
            "name": str.trim(),
            "label": str.trim(),
            "value": str.trim()
          }
        }
        return null;
      }).filter(obj => obj !== null);
      dispatch(setIncludeKeyword([...searchData?.includeKeyword, value]));
      dispatch(setSelectedIncludeKeyword([...searchData?.includeKeyword, value]));
      dispatch(setSelectedFilters({ data: 'include', value: objectsArray }));
      dispatch(genericFilterHandler(activeTab, query));
    }
    else {
      const objectsArray = [...searchData?.excludeKeyword, value]?.map((str, index) => {
        if (str.trim() !== '') {
          return {
            "_id": str.trim(),
            "name": str.trim(),
            "label": str.trim(),
            "value": str.trim()
          }
        }
        return null;
      }).filter(obj => obj !== null);
      dispatch(setExcludeKeyword([...searchData?.excludeKeyword, value]));
      dispatch(setSelectedExcludeKeyword([...searchData?.excludeKeyword, value]));
      dispatch(setSelectedFilters({ data: 'exclude', value: objectsArray }));
      dispatch(genericFilterHandler(activeTab, query));
    }
  };


  // const matchText = (text, match) => {
  //   const regexPattern = new RegExp(`(.*?)(?:\\b${match}\\b)(.*)`);
  //   return text.match(regexPattern) || [];
  // }

  const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);

  const [cikPopup, setCikPopup] = useState(false)
  const [accessionPopup, setAccessionPopup] = useState(false)
  const [formPopup, setFormPopup] = useState(false)



  // const boldString = (desc, query) => {
  //   // Ensure query is an array
  //   if (!Array.isArray(query)) {
  //     query = [query];
  //   }

  //   // Use a regular expression to match any of the words in the query array
  //   const regex = new RegExp(`(${query.join('|')})`, 'gi');

  //   // Replace matches with bold tags
  //   const highlightedDesc = desc.replace(regex, match => `<strong>${match}</strong>`);

  //   return { __html: highlightedDesc };
  // };




  const handleScrollToTop = () => {
    if (previewDivRef.current) {
      // Scroll to the top of the div
      previewDivRef.current.scrollTop = 0;
    }
  };


  return (
    <>
      {console.log("searchData", searchData)}
      {console.log("userDetails", userDetails)}

      {!viewFullViewer ?
        <div className="gray_bg">
          <Container className="main-container">
            <Box className="search-main">
              <Box>
                <Box className="search-head">
                  <div className="search-left">
                    <Search
                      activeTab={activeTab}
                      selectedQuery={query}
                      fromSearchResult
                      setPreviewShow={setPreviewShow}
                    />
                    {/* <span>{searchResult && searchResultCount} results found</span> */}
                  </div>
                  <div className="filter">
                    <ColorButton size="large"
                      variant="outlined"
                      endIcon={moreFilter ? <MdArrowDropUp /> : <MdArrowDropDown />}
                      onClick={() => setMoreFilter(!moreFilter)}
                    >
                      {moreFilter ? 'Less Filter' : 'More Filter'}
                    </ColorButton>
                  </div>
                </Box>
                <div className="search-result-head">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>{" "}
                    </li>
                    <li>{query.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())}</li>
                  </ul>
                  <div className="filters-main">
                    <div className="filter-row">

                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Contract Type"
                          ddname='ContractType'
                          loadOptions={contractTitleList}
                          activeTab={activeTab}
                          query={query}
                          setSelected={setSelectedContractTitle}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedContractTitle}
                          fetchListData={fetchContractTitleHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Industry"
                          ddname='Industry'
                          loadOptions={industryList}
                          activeTab={activeTab}
                          query={query}
                          setSelected={setSelectedIndustry}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedIndustry}
                          fetchListData={fetchIndustryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Department"
                          ddname='Department'
                          loadOptions={departmentList}
                          activeTab={activeTab}
                          query={query}
                          setSelected={setSelectedDepartment}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedDepartment}
                          fetchListData={fetchDepartmentHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      {activeTab?.contractType &&
                        <div className="filter-col">
                          <MiuiAsyncSelect
                            multiple
                            placeholder="Clauses"
                            ddname='Clauses'
                            loadOptions={clausesList}
                            activeTab={activeTab}
                            query={query}
                            setSelected={setSelectedClause}
                            genericFilterHandler={genericFilterHandler}
                            value={searchData?.selectedClause}
                            fetchListData={fetchClausesHandler}
                            setPreviewShow={setPreviewShow}
                          />
                        </div>
                      }
                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Governing Law"
                          ddname='GoverningLaw'
                          loadOptions={governingLawList}
                          activeTab={activeTab}
                          query={query}
                          setSelected={setSelectedGoverningLaw}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedGoverningLaw}
                          fetchListData={fetchGoverningLawHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>

                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Country"
                          ddname='Country'
                          loadOptions={countryList}
                          activeTab={activeTab}
                          query={query}
                          setSelected={setSelectedCountry || []}
                          genericFilterHandler={genericFilterHandler}
                          value={searchData?.selectedCountry}
                          fetchListData={fetchCountryHandler}
                          setPreviewShow={setPreviewShow}
                        />
                      </div>
                      {/* 
                      <div className="filter-col">
                        <MiuiAsyncSelect
                          multiple
                          placeholder="Party/Counter Party"
                          ddname='Party/Counter Party'
                          loadOptions={[]}
                          activeTab={activeTab}
                          query={query}
                          setSelected={[]}
                        genericFilterHandler={genericFilterHandler}
                        value={searchData?.selectedContractTitle}
                        fetchListData={fetchCountryHandler}
                        />
                      </div> */}

                      {/* <div className="filter-col">
                        <TextField
                          sx={{ width: '151px' }}
                          hiddenLabel
                          id="include-keywords"
                          placeholder="Include Keywords"
                          variant="outlined"
                          size="small"
                          defaultValue={searchData?.selectedincludeKeyword?.join(',')}
                          // value={searchData.selectedincludeKeyword.join(',')}
                          onChange={(e) => handleWord(e.target.value, 'include')}
                        />
                      </div> */}


                      <div className="filter-col">
                        <ChipInput
                          id="includekeyword"
                          variant="outlined"
                          color='primary'
                          size="small"
                          className='taginput'
                          hiddenLabel
                          alwaysShowPlaceholder={true}
                          dataSource={searchData?.includeKeyword}
                          value={searchData?.includeKeyword}
                          placeholder={'Include Keywords'}
                          onAdd={(chip) => handleWord(chip, 'Include Keywords')}
                        />
                      </div>

                      <div className="filter-col">
                        <ChipInput
                          id="excludekeyword"
                          variant="outlined"
                          color='primary'
                          size="small"
                          className='taginput'
                          hiddenLabel
                          alwaysShowPlaceholder={true}
                          dataSource={searchData?.excludeKeyword}
                          value={searchData?.excludeKeyword}
                          placeholder={'Exclude Keywords'}
                          onAdd={(chip) => handleWord(chip, 'Exclude Keywords')}
                        />
                      </div>


                      {/* <div className="filter-col">
                        <TextField
                          sx={{ width: '151px' }}
                          hiddenLabel
                          id="exclude-keywords"
                          placeholder="Exclude Keywords"
                          variant="outlined"
                          size="small"
                          value={excludeKeyword}
                          onChange={(e) => handleWord(e.target.value, 'exclude')}
                        />
                      </div> */}

                      <div className="filter-col">
                        <DateRangePicker
                          cleanable
                          showOneCalendar
                          placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                          defaultValue={searchData?.selectedDate?.length ? [new Date(searchData.selectedDate[0]), new Date(searchData.selectedDate[1])] : []}
                          onOk={(date) => handleDate(date)}
                          onClean={(date) => handleRemove(date)}
                        />
                      </div>


                      {moreFilter &&
                        <>
                          <div className="filter-col" onClick={() => setCikPopup(true)}>
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="CIK"
                              ddname='CIK'
                              loadOptions={cikList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedCIKList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedCIKList}
                              fetchListData={fetchCIKHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col" onClick={() => setAccessionPopup(true)}>
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Accession Number"
                              ddname='AccessionNumber'
                              loadOptions={accessionNumberList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedAccessionNumberList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedAccessionNumberList}
                              fetchListData={fetchAcessionNumberHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Entity Type"
                              ddname='EntityType'
                              loadOptions={entityTypeList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedEntityTypeList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedEntityTypeList}
                              fetchListData={fetchEntityTypeHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col" onClick={() => setFormPopup(true)}>
                            <MiuiAsyncSelect
                              multiple
                              readOnly
                              placeholder="Form"
                              ddname='Forms'
                              loadOptions={formsList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedFormsList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedFormsList}
                              fetchListData={fetchFormsHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Stock Exchange"
                              ddname='StockExchanges'
                              loadOptions={stockExchangesList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedStockExchangesList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedStockExchangesList}
                              fetchListData={fetchStockExchangesHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="Exhibit ID"
                              ddname='ExhibitID'
                              loadOptions={exhibitIDList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedExhibitList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedExhibitIDList}
                              fetchListData={fetchExhibitIDHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>

                          <div className="filter-col">
                            <MiuiAsyncSelect
                              multiple
                              placeholder="State of Incorporation"
                              ddname='StateOfIncorporation'
                              loadOptions={stateOfIncorporationList}
                              activeTab={activeTab}
                              query={query}
                              setSelected={setSelectedstateOfIncorporationList}
                              genericFilterHandler={genericFilterHandler}
                              value={searchData?.selectedStateOfIncorporationList}
                              fetchListData={fetchStateOfIncorporationHandler}
                              setPreviewShow={setPreviewShow}
                            />
                          </div>
                        </>
                      }
                    </div>

                    <div className="sort-by">
                      <div className="filter-row">
                        <div className="filter-col">
                          <SelectPicker
                            data={data}
                            cleanable={false}
                            defaultValue='relevance'
                            placeholder="Sort by"
                            searchable={false}
                            style={{ width: 224 }}
                            onChange={(data) => {
                              console.log(data, "data");
                              dispatch(genericFilterHandler(activeTab, query, null, 0, data));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="selected-filter">
                    {console.log("selectedFilters", selectedFilters)}
                    <ul>
                      {Object.keys(selectedFilters).map((item, i) => {
                        return (
                          selectedFilters[item].length ?
                            <li key={i}>
                              <h6>{item}: </h6>
                              <ul>
                                {selectedFilters[item]?.map((selected, index) => {
                                  return (
                                    <li key={index}>
                                      <div className="selected">
                                        <span>{selected?.name}</span>
                                        <MdClose
                                          onClick={() => handleRemoveSelected(item, selected?._id)}
                                        />
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </li>
                            : ""
                        )
                      })}
                    </ul>
                    {Object.values(selectedFilters).some(array => array.length > 0) ? <Button onClick={() => handleRemoveAllSelected()}> Clear All </Button> : ''}
                  </div>
                </div>

                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab onClick={() => {
                    setActiveTab({ contractType: true, clause: false, definition: false, });
                    localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
                    setPreviewShow(false);
                  }}
                    label={`Contract Type ${contractCount > 0 ? `(${contractCount})` : ""}`}
                    {...a11yProps(0)}
                  />
                  <Tab onClick={() => {
                    setActiveTab({ contractType: false, clause: true, definition: false, });
                    localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: true, definition: false, }))
                    setPreviewShow(false);
                  }}
                    label={`Clause  ${clauseCount > 0 ? `(${clauseCount})` : ""}`}
                    {...a11yProps(1)}
                  />
                  <Tab onClick={() => {
                    setActiveTab({ contractType: false, clause: false, definition: true, });
                    localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: false, definition: true, }))
                    setPreviewShow(false);
                  }}
                    label={`Definition ${definitionCount > 0 ? `(${definitionCount})` : ""}`}
                    {...a11yProps(2)}
                  />
                  <div className="head-right">
                    <ul>
                      <li>
                        <span className={`preview ${previewShow ? 'active' : ''}`} onClick={() => setPreviewShow(!previewShow)} >
                          <MdRemoveRedEye />
                          Preview
                        </span>
                      </li>
                      <li>
                        <span> Page {searchPageCount / 10 + 1} / {Math.ceil(searchResultCount / 10)}</span>
                      </li>
                      <li>
                        <div className="arrow">
                          <IconButton
                            aria-label="prev"
                            size="small"
                            disabled={(searchPageCount / 10 + 1) === 1}
                            color="primary"
                            onClick={() => {
                              setPreviewShow(false)
                              dispatch(genericFilterHandler(activeTab, query, null, searchPageCount - 10))
                            }}
                          >
                            <MdArrowBack />
                          </IconButton>

                          <IconButton
                            aria-label="prev"
                            size="small"
                            disabled={(searchPageCount / 10 + 1) === Math.ceil(searchResultCount / 10)}
                            color="primary"
                            onClick={() => {
                              setPreviewShow(false)
                              dispatch(genericFilterHandler(activeTab, query, null, searchPageCount + 10))
                            }}
                          >
                            <MdArrowForward />
                          </IconButton>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Tabs>
              </Box>
              {console.log("searchPageCount", searchPageCount + 10)}

              {/* contract tab */}
              <CustomTabPanel value={tabValue} index={0} className="searchtab_content">
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <ul style={{ height: "100%" }}>
                      {isLoading ?
                        <div style={{ height: "100%", position: "relative" }} >
                          <Loader center content="Loading Data..." />
                        </div>
                        :
                        activeTab?.contractType && searchResult?.results?.length ?
                          searchResult?.results?.map((item, i) => (
                            <li
                              className={item?.selected && previewShow ? "selected" : ""}
                              onClick={() => onSelectHandler(item, i)}
                            >
                              <div className="title">
                                <span className="number">{searchPageCount + (i + 1)}. </span>
                                <h5>{item?.contract_title?.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())}</h5>
                              </div>
                              <div className="doc_content">
                                <div className="tags-main">
                                  <ul>
                                    {item?.contract_title &&
                                      <li>
                                        <p>
                                          <b>Contract Type: </b> {item?.contract_title}
                                        </p>
                                      </li>
                                    }
                                    {item?.industry &&
                                      <li>
                                        <p>
                                          <b>Industry: </b> {item?.industry || 'NA'}
                                        </p>
                                      </li>
                                    }
                                    {item?.department &&
                                      <li>
                                        <p>
                                          <b>Department: </b>{" "}
                                          {item?.department?.map((d) => d.department_type).join(", ")}
                                        </p>
                                      </li>
                                    }
                                    {item?.governing_law &&
                                      <li>
                                        <p>
                                          <b>Governing Law: </b>{" "}
                                          {item?.governing_law || "NA"}
                                        </p>
                                      </li>
                                    }
                                    {item?.filing_date &&
                                      <li>
                                        <p>
                                          <b>Filing Date: </b>{" "}
                                          {new Date(item?.filing_date).toISOString().split("T")[0]}
                                        </p>
                                      </li>
                                    }
                                  </ul>
                                </div>
                                <div className="description">
                                  {item?.description?.map((desc) => (
                                    <>
                                      <p>
                                        {searchData.selectedincludeKeyword.length ?
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, searchData.selectedincludeKeyword) }} ></div>
                                          :
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, query) }} ></div>
                                        }
                                        {/* <span>{matchText(desc, query)[1]} </span>
                                        <b> {query} </b>
                                        <span> {matchText(desc, query)[2]}</span> */}
                                      </p>

                                    </>

                                  ))}
                                </div>
                              </div>
                            </li>
                          ))
                          :
                          <li>

                            <h3 style={{ textAlign: "center" }}> No Result Found </h3>
                          </li>
                      }
                    </ul>
                  </div>

                  {previewShow && (
                    <div
                      className="right"
                      ref={rootRef}
                    >
                      <div>
                        <Stack style={{ padding: "0 10px" }} justifyContent="space-between">
                          <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)}  >
                            <CgScreenShot fontSize={24} style={{ transform: "rotate(90deg)", marginRight: "5px" }} />View Full Contract
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span onClick={() => {
                              if (localStorage.getItem('token')) {
                                setShowSaveDD(!showSaveDD)
                              }
                              else {
                                setToastData({
                                  open: true,
                                  status: 'warning',
                                  message: `Please Login First to save contract`
                                });
                              }
                            }}>
                              Save  <MdOutlineBookmarkAdd fontSize={24} style={{ marginLeft: "5px" }} />
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? 'active' : ''}`}>
                              <ul>
                                <li>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="my_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library'}
                                        />
                                      }
                                      label="My Library"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="team_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'team_library'}
                                        />
                                      }
                                      label="Teams Library"
                                      labelPlacement="start"
                                    />
                                  </FormGroup>
                                </li>
                                <li onClick={() => setSaveModal(true)}>
                                  <span> Client Library <AiFillCaretRight /></span>
                                </li>
                                <li onClick={() => setCustomBookMarkModal(true)}>
                                  <span> Custom Library <AiFillCaretRight /></span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      {console.log("docData", docData)}
                      <div style={{ padding: "10px", overflow: "auto" }} ref={previewDivRef} >
                        {docData?.raw_text ?
                          regex.test(docData?.raw_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.raw_text }} ></div>
                            :
                            <pre>
                              <div>{docData?.raw_text}</div>
                            </pre>
                          :
                          regex.test(docData?.formatted_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                            :
                            <pre>
                              <div>{docData?.formatted_text}</div>
                            </pre>
                        }
                      </div>

                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />

                    </div>
                  )}
                </Box>
              </CustomTabPanel>



              {/* caluse tab */}
              <CustomTabPanel value={tabValue} index={1} className="searchtab_content">
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <ul style={{ height: "100%" }}>
                      {isLoading ?
                        <div style={{ height: "100%", position: "relative" }} >
                          <Loader center content="Loading Data..." />
                        </div>
                        :
                        activeTab?.clause && searchResult?.results?.length ?
                          searchResult?.results?.map((item, i) => (
                            <li
                              className={item?.selected && previewShow ? "selected" : ""}
                              onClick={() => onSelectHandler(item, i, 'clause')}
                            >
                              <div className="title">
                                <span className="number">{searchPageCount + (i + 1)}. </span>
                                <h5>{item?.heading}</h5>
                              </div>
                              <div className="doc_content">
                                <div className="tags-main">
                                  <ul>
                                    {item?.contract_title &&
                                      <li>
                                        <p>
                                          <b>Contract Type: </b> {item?.contract_title}
                                        </p>
                                      </li>
                                    }
                                    {item?.industry &&
                                      <li>
                                        <p>
                                          <b>Industry: </b> {item?.industry}
                                        </p>
                                      </li>
                                    }
                                    {item?.department &&
                                      <li>
                                        <p>
                                          <b>Department: </b>{" "}
                                          {item?.department?.map((d) => d?.department_type)?.join(", ")}
                                        </p>
                                      </li>
                                    }
                                    {item?.governing_law &&
                                      <li>
                                        <p>
                                          <b>Governing Law: </b>{" "}
                                          {item?.governing_law || "NA"}
                                        </p>
                                      </li>
                                    }
                                    {item?.filing_date &&
                                      <li>
                                        <p>
                                          <b>Filing Date: </b>{" "}
                                          {new Date(item?.filing_date).toISOString().split("T")[0]}
                                        </p>
                                      </li>
                                    }
                                  </ul>
                                </div>
                                {/* <div className="definitions">
                                  {searchData.selectedincludeKeyword.length ?
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.clause_content, searchData.selectedincludeKeyword) }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.clause_content, query) }} ></div>
                                  }
                                </div> */}

                                <div className="description">
                                  {item?.description?.map((desc) => (
                                    <>
                                      <p>
                                        {searchData.selectedincludeKeyword.length ?
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, searchData.selectedincludeKeyword) }} ></div>
                                          :
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, query) }} ></div>
                                        }
                                      </p>
                                    </>
                                  ))}
                                </div>

                              </div>
                            </li>
                          ))
                          :
                          <li>
                            <h3 style={{ textAlign: "center" }}> No Result Found </h3>
                          </li>
                      }
                    </ul>
                  </div>
                  {console.log("docData", docData)}
                  {previewShow && (
                    <div
                      className="right"
                      ref={rootRef}
                    >
                      <div>
                        <Stack style={{ padding: "0 10px" }} justifyContent="space-between">
                          <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)}  >
                            <CgScreenShot fontSize={24} style={{ transform: "rotate(90deg)", marginRight: "5px" }} />View Full Contract
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span onClick={() => {
                              if (localStorage.getItem('token')) {
                                setShowSaveDD(!showSaveDD)
                              }
                              else {
                                setToastData({
                                  open: true,
                                  status: 'warning',
                                  message: `Please Login First to save contract`
                                });
                              }
                            }}>
                              Save  <MdOutlineBookmarkAdd fontSize={24} style={{ marginLeft: "5px" }} />
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? 'active' : ''}`}>
                              <ul>
                                <li>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="my_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library'}
                                        />
                                      }
                                      label="My Library"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="team_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'team_library'}
                                        />
                                      }
                                      label="Teams Library"
                                      labelPlacement="start"
                                    />
                                  </FormGroup>
                                </li>
                                <li onClick={() => setSaveModal(true)}>
                                  <span> Client Library <AiFillCaretRight /></span>
                                </li>
                                <li onClick={() => setCustomBookMarkModal(true)}>
                                  <span> Custom Library <AiFillCaretRight /></span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      <div style={{ padding: "10px", overflow: "auto" }} ref={previewDivRef}>
                        {docData?.raw_text ?
                          regex.test(docData?.raw_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.raw_text }} ></div>
                            :
                            <pre>
                              <div>{docData?.raw_text}</div>
                            </pre>
                          :
                          regex.test(docData?.formatted_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                            :
                            <pre>
                              <div>{docData?.formatted_text}</div>
                            </pre>
                        }
                      </div>
                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />

                    </div>
                  )}
                </Box>
              </CustomTabPanel>



              {/* definition tab */}

              <CustomTabPanel value={tabValue} index={2} className="searchtab_content">
                <Box className="search-result-body">
                  <div className={`left ${previewShow ? "preview-open" : ""}`}>
                    <ul style={{ height: "100%" }}>
                      {isLoading ?
                        <div style={{ height: "100%", position: "relative" }} >
                          <Loader center content="Loading Data..." />
                        </div>
                        :
                        activeTab?.definition && searchResult?.results?.length ?
                          searchResult?.results?.map((item, i) => (
                            <li
                              className={item?.selected && previewShow ? "selected" : ""}
                              onClick={() => onSelectHandler(item, i, 'definition')}
                            >
                              <div className="title">
                                <span className="number">{searchPageCount + (i + 1)}. </span>
                                <h5>{item?.define_term}</h5>
                              </div>
                              <div className="doc_content">
                                <div className="tags-main">
                                  <ul>
                                    {item?.contract_title &&
                                      <li>
                                        <p>
                                          <b>Contract Type: </b> {item?.contract_title}
                                        </p>
                                      </li>
                                    }
                                    {item?.industry &&
                                      <li>
                                        <p>
                                          <b>Industry: </b>  {item?.industry || "NA"}
                                        </p>
                                      </li>
                                    }
                                    {item?.department &&
                                      <li>
                                        <p>
                                          <b>Department: </b>{" "}
                                          {item?.department?.map((d) => d?.department_type)?.join(", ")} </p>
                                      </li>
                                    }
                                    {item?.filing_date &&
                                      <li>
                                        <p>
                                          <b>Filing Date: </b>{" "}
                                          {new Date(item?.filing_date).toISOString().split("T")[0]}
                                        </p>
                                      </li>
                                    }
                                  </ul>
                                </div>
                                {/* <div className="definitions">
                                  {searchData.selectedincludeKeyword.length ?
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.definition, searchData.selectedincludeKeyword) }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: boldString(item?.definition, query) }} ></div>
                                  }
                                </div> */}

                                <div className="description">
                                  {item?.description?.map((desc) => (
                                    <>
                                      <p>
                                        {searchData.selectedincludeKeyword.length ?
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, searchData.selectedincludeKeyword) }} ></div>
                                          :
                                          <div dangerouslySetInnerHTML={{ __html: boldString(desc, query) }} ></div>
                                        }
                                      </p>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </li>
                          ))
                          :
                          <li>

                            <h3 style={{ textAlign: "center" }}> No Result Found </h3>
                          </li>
                      }
                    </ul>
                  </div>
                  {previewShow && (
                    <div
                      className="right"
                      ref={rootRef}
                    >
                      <div>
                        <Stack style={{ padding: "0 10px" }} justifyContent="space-between">
                          <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)}  >
                            <CgScreenShot fontSize={24} style={{ transform: "rotate(90deg)", marginRight: "5px" }} />View Full Contract
                          </span>
                          <Stack className="save_dropdwon" alignItems="center">
                            <span onClick={() => {
                              if (localStorage.getItem('token')) {
                                setShowSaveDD(!showSaveDD)
                              }
                              else {
                                setToastData({
                                  open: true,
                                  status: 'warning',
                                  message: `Please Login First to save contract`
                                });
                              }
                            }}>
                              Save  <MdOutlineBookmarkAdd fontSize={24} style={{ marginLeft: "5px" }} />
                            </span>
                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? 'active' : ''}`}>
                              <ul>
                                <li>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="my_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library'}
                                        />
                                      }
                                      label="My Library"
                                      labelPlacement="start"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="team_library"
                                          onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                          checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'team_library'}
                                        />
                                      }
                                      label="Teams Library"
                                      labelPlacement="start"
                                    />
                                  </FormGroup>
                                </li>
                                <li onClick={() => setSaveModal(true)}>
                                  <span> Client Library <AiFillCaretRight /></span>
                                </li>
                                <li onClick={() => setCustomBookMarkModal(true)}>
                                  <span> Custom Library <AiFillCaretRight /></span>
                                </li>
                              </ul>
                            </div>
                          </Stack>
                        </Stack>
                        {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                      </div>
                      <div style={{ padding: "10px", overflow: "auto" }} ref={previewDivRef}>
                        {docData?.raw_text ?
                          regex.test(docData?.raw_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.raw_text }} ></div>
                            :
                            <pre>
                              <div>{docData?.raw_text}</div>
                            </pre>
                          :
                          regex.test(docData?.formatted_text) ?
                            <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                            :
                            <pre>
                              <div>{docData?.formatted_text}</div>
                            </pre>
                        }
                      </div>

                      <SaveBM
                        docData={docData}
                        bookmarkList={bookmarkList}
                        activeTab={activeTab}
                        fetchBookmarkList={fetchBookmarkList}
                        rootRef={() => rootRef?.current}
                        saveModal={saveModal}
                        setSaveModal={setSaveModal}
                        customBookMarkModal={customBookMarkModal}
                        setCustomBookMarkModal={setCustomBookMarkModal}
                      />

                    </div>
                  )}
                </Box>
              </CustomTabPanel>
            </Box>
          </Container >
        </div>
        :
        <ViewFullScreen docFullViewData={docData} setViewFullViewer={setViewFullViewer} activeTab={activeTab} />
      }

      {cikPopup &&
        <MiuiSelectPopup
          open={cikPopup}
          close={() => setCikPopup(false)}
          ddname='CIK'
          placeholder="CIK"
          loadOptions={cikList}
          activeTab={activeTab}
          query={query}
          setSelected={setSelectedCIKList}
          genericFilterHandler={genericFilterHandler}
          value={searchData?.selectedCIKList}
        />
      }

      {
        accessionPopup &&
        <MiuiSelectPopup
          open={accessionPopup}
          close={() => setAccessionPopup(false)}
          ddname='AccessionNumber'
          placeholder="AccessionNumber"
          loadOptions={accessionNumberList}
          activeTab={activeTab}
          query={query}
          setSelected={setSelectedAccessionNumberList}
          genericFilterHandler={genericFilterHandler}
          value={searchData?.selectedAccessionNumberList}
          fetchListData={fetchAcessionNumberHandler}
        />
      }

      {
        formPopup &&
        <MiuiSelectPopup
          open={formPopup}
          close={() => setFormPopup(false)}
          ddname='Forms'
          placeholder="Forms"
          loadOptions={formsList}
          activeTab={activeTab}
          query={query}
          setSelected={setSelectedFormsList}
          genericFilterHandler={genericFilterHandler}
          value={searchData?.selectedFormsList}
          fetchListData={fetchFormsHandler}
        />
      }

      <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />

    </>
  );
};

export default SearchResult;
