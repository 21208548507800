/* eslint-disable import/no-anonymous-default-export */
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";

export default ({ type, label, leftIcon, rightIcon, ...rest }) => {
  return (
    <>
      <FormControl>
        {label && <FormLabel>{label}</FormLabel>}

        <InputGroup>
          {leftIcon && (
            <InputLeftElement pointerEvents="none">{leftIcon}</InputLeftElement>
          )}
          <Input type={type} {...rest} />
          {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
        </InputGroup>
      </FormControl>
    </>
  );
};
