/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { setSelectedDepartment, setSelectedFilters } from "../../redux/searchSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function HomeMiuiAsyncSelect({ multiple, ddname, placeholder, loadOptions, setSelected, value }) {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  // const [options, setOptions] = useState([]);
  // const loading = open && loadOptions?.length === 0;
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    setSelectedOptions(value || [])
  }, [value])


  const handleToggleOption = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  }

  const handleClearOptions = () => {
    setSelectedOptions([])
  }

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === "clear") {
      handleClearOptions();
      dispatch(setSelected([]));
      dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
    }
    else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions && Array.isArray(selectedOptions)) {
        // for only names 
        // const namesArray = selectedOptions.map(item => item.name);
        handleToggleOption(selectedOptions);
        dispatch(setSelected(selectedOptions));
        dispatch(setSelectedFilters({ data: ddname, value: selectedOptions }));
      }
      else {
        handleToggleOption([selectedOptions])
        dispatch(setSelectedDepartment([selectedOptions]));
        dispatch(setSelectedFilters({ data: ddname, value: [selectedOptions] }));
      }
    }
  };

  return (
    <>
      {console.log("loadOptions", loadOptions)}
      {console.log("selectedOptions", selectedOptions)}

      <Autocomplete
        id="asynchronous-demo"
        multiple={multiple || false}
        limitTags={1}
        value={selectedOptions}
        open={open}
        disableCloseOnSelect
        onOpen={() => { setOpen(true); }}
        onClose={() => { setOpen(false); }}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        getOptionLabel={(option) => option?.label}
        options={loadOptions}
        onChange={handleChange}
        renderOption={(props, option, { selected }) => (
          <li {...props} className="customlist">
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <p>{option.label}</p>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ fontSize: "12px" }}
            placeholder={placeholder} />
        )}
      />
    </>
  );
};


export default HomeMiuiAsyncSelect