/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanyHandler,
  fetchDepartmentHandler,
  fetchGoverningLawHandler,
  fetchIndustryHandler,
  genericFilterHandler,
  setSelectedCompany,
  setSelectedDepartment,
  setSelectedGoverningLaw,
  setSelectedIndustry,
} from "../../redux/searchSlice";
import HomeMiuiAsyncSelect from "./HomeMiuiAsyncSelect";

const MoreFilters = ({ searchQuery, activeTab }) => {
  const dispatch = useDispatch();
  let searchData = useSelector((state) => state.search);

  const { companyList, governingLawList, departmentList, industryList } = useSelector((state) => state.search);


  useEffect(() => {
    dispatch(genericFilterHandler(activeTab, searchQuery, "search"));
    // dispatch(fetchCompanyHandler())
    // dispatch(fetchDepartmentHandler())
    // dispatch(fetchIndustryHandler())
    // dispatch(fetchGoverningLawHandler())
  }, [])


  return (
    <>
      <Grid container spacing={"14px"} mb={3} className="more-filters">
        <Grid item xs={6} >
          <HomeMiuiAsyncSelect
            multiple
            placeholder="Company Name"
            ddname='CompanyName'
            loadOptions={companyList.data}
            setSelected={setSelectedCompany}
            value={searchData?.selectedCompany}
          />
        </Grid>
        <Grid item xs={6}>
          <HomeMiuiAsyncSelect
            multiple
            placeholder="Department"
            ddname='department'
            loadOptions={departmentList.data}
            setSelected={setSelectedDepartment}
            value={searchData?.selectedDepartment}
          />

        </Grid>
        <Grid item xs={6}>
          <HomeMiuiAsyncSelect
            multiple
            placeholder="Industry"
            ddname='industry'
            loadOptions={industryList.data}
            setSelected={setSelectedIndustry}
            value={searchData?.selectedIndustry}
          />

        </Grid>
        <Grid item xs={6}>
          <HomeMiuiAsyncSelect
            multiple
            placeholder="Governing Law"
            ddname='governinglaw'
            loadOptions={governingLawList.data}
            setSelected={setSelectedGoverningLaw}
            value={searchData?.selectedGoverningLaw}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MoreFilters;
