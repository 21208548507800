import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../component/SEO";

const InstallNow = () => {
  return (
    <>
      <SEO
        title={"Installin Process" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading className="animate__animated animate__fadeInDown animate__faster">
            Install Now
          </Heading>
        </Box>
      </Box>
      <div className="container">
        <div className="install-now">
          <p>
            You could install the Kronicle Word Add-in by adopting the following
            steps -
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>a) Click on the Insert Icon in your MS word Document</li>
            <li>b) Select Get Add-ins</li>
            <li>
              c) Browse the list of Office Add-in or search for Kronicle Word
              Add-in in the search box
            </li>
            <li>d) Select the Kronicle Add-in</li>
            <li>e) Select Add to download the Kronicle</li>
          </ul>
          <Link to="/demo">Request Demo</Link>
        </div>
      </div>
    </>
  );
};

export default InstallNow;
