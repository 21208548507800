import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { getLibraryList } from "../service/BookmarkService";
import { fetchAllLibraryFilter } from "../service/Library";

const initialState = {
    ActiveTab: [],
    searchValue: "",
    libraryResult: [],
    fullLibraryResult: [],
    libraryResultCount: 10,
    searchPageCount: 0,
    contractCount: 0,
    clauseCount: 0,
    definitionCount: 0,
    searchItemList: { data: [], total_count: '' },
    isLoading: false,
    companyList: { data: [], total_count: '' },
    industryList: { data: [], total_count: '' },
    departmentList: { data: [], total_count: '' },
    governingLawList: { data: [], total_count: '' },
    countryList: { data: [], total_count: '' },
    contractTitleList: { data: [], total_count: '' },
    accessionNumberList: { data: [], total_count: '' },
    entityTypeList: { data: [], total_count: '' },
    formsList: { data: [], total_count: '' },
    stockExchangesList: { data: [], total_count: '' },
    stateOfIncorporationList: { data: [], total_count: '' },
    exhibitIDList: { data: [], total_count: '' },
    cikList: { data: [], total_count: '' },
    clausesList: { data: [], total_count: '' },
    selectedDate: [],
    selectedCompany: [],
    selectedIndustry: [],
    selectedDepartment: [],
    selectedGoverningLaw: [],
    selectedCountry: [],
    selectedContractTitle: [],
    selectedAccessionNumberList: [],
    selectedEntityTypeList: [],
    selectedFormsList: [],
    selectedStockExchangesList: [],
    selectedStateOfIncorporationList: [],
    selectedExhibitIDList: [],
    selectedCIKList: [],
    selectedLibraryList: [],
    selectedClause: [],
    selectedFilters: [],
    docUrl: { uri: "" },
    docData: {},
    includeKeyword: [],
    excludeKeyword: [],
    flteredTitle: '',
    groupIDs: [],
    openlibraryType: '',
    selectedincludeKeyword: [],
    selectedexcludeKeyword: [],
};

const librarySlice = createSlice({
    name: "library",
    initialState,
    reducers: {
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setSelectedActiveTab: (state, action) => {
            state.ActiveTab = action.payload;
        },
        setSearchItem: (state, action) => {
            state.searchItemList = action.payload;
        },
        setLibraryResult: (state, action) => {
            state.libraryResult = action.payload;
        },
        setFullLibraryResult: (state, action) => {
            state.fullLibraryResult = action.payload;
        },

        setLibraryResultCount: (state, action) => {
            state.libraryResultCount = action.payload;
        },
        setContractCount: (state, action) => {
            state.contractCount = action.payload;
        },
        setClauseCount: (state, action) => {
            state.clauseCount = action.payload;
        },
        setDefinitionCount: (state, action) => {
            state.definitionCount = action.payload;
        },
        setSearchPageCount: (state, action) => {
            state.searchPageCount = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCompanyList: (state, action) => {
            state.companyList = action.payload;
        },
        setIndustryList: (state, action) => {
            state.industryList = action.payload;
        },
        setDepartmentList: (state, action) => {
            state.departmentList = action.payload;
        },
        setGoverningLawList: (state, action) => {
            state.governingLawList = action.payload;
        },
        setCountryList: (state, action) => {
            state.countryList = action.payload;
        },
        setContractTitleList: (state, action) => {
            state.contractTitleList = action.payload;
        },
        setClausesList: (state, action) => {
            state.clausesList = action.payload;
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        setSelectedIndustry: (state, action) => {
            state.selectedIndustry = action.payload;
        },
        setSelectedDepartment: (state, action) => {
            state.selectedDepartment = action.payload;
        },
        setSelectedGoverningLaw: (state, action) => {
            state.selectedGoverningLaw = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedContractTitle: (state, action) => {
            state.selectedContractTitle = action.payload;
        },
        setSelectedClause: (state, action) => {
            state.selectedClause = action.payload;
        },
        setDocUrl: (state, action) => {
            state.docUrl = action.payload;
        },
        setDocData: (state, action) => {
            state.docData = action.payload;
        },

        setSelectedFilters: (state, action) => {
            const newObject = action.payload;

            if (newObject.replaceData) {
                state.selectedFilters = newObject.replaceData
            }
            else {
                // Get the first key from newData
                const newDataKey = newObject?.data;
                const isKeyFound = newDataKey in state.selectedFilters;
                // If the key is found, update the existing array; otherwise, add a new key-value pair
                const updatedArray = {
                    ...state.selectedFilters,
                    [newDataKey]: isKeyFound ? newObject.value : [...(state.selectedFilters[newDataKey] || []), ...newObject.value],
                };
                state.selectedFilters = updatedArray;
            }
        },

        setRemoveFilters: (state, action) => {
            const newObject = action.payload;
            const existingIndex = state?.selectedFilters.findIndex(item => item.type === newObject.type);

            if (existingIndex !== -1) {
                // If an object with the same data exists, remove it
                state.selectedFilters = state?.selectedFilters.filter((_, index) => index !== existingIndex);
            }
        },

        setRemoveAllFilters: (state, action) => {
            state.selectedFilters = [];
            state.selectedDate = [];
            state.selectedCompany = [];
            state.selectedIndustry = [];
            state.selectedDepartment = [];
            state.selectedGoverningLaw = [];
            state.selectedCountry = [];
            state.selectedContractTitle = [];
            state.selectedAccessionNumberList = [];
            state.selectedEntityTypeList = [];
            state.selectedFormsList = [];
            state.selectedStockExchangesList = [];
            state.selectedExhibitIDList = [];
            state.selectedStateOfIncorporationList = [];
            state.selectedCIKList = [];
            state.selectedClause = [];
            state.selectedFilters = [];
            state.selectedincludeKeyword = [];
            state.selectedexcludeKeyword = [];
            state.includeKeyword = [];
            state.excludeKeyword = [];
        },

        setAccessionNumberList: (state, action) => {
            state.accessionNumberList = action.payload;
        },
        setSelectedAccessionNumberList: (state, action) => {
            state.selectedAccessionNumberList = action.payload;
        },
        setEntityTypeList: (state, action) => {
            state.entityTypeList = action.payload;
        },
        setSelectedEntityTypeList: (state, action) => {
            state.selectedEntityTypeList = action.payload;
        },
        setFormsList: (state, action) => {
            state.formsList = action.payload;
        },
        setSelectedFormsList: (state, action) => {
            state.selectedFormsList = action.payload;
        },
        setStockExchangesList: (state, action) => {
            state.stockExchangesList = action.payload;
        },
        setSelectedStockExchangesList: (state, action) => {
            state.selectedStockExchangesList = action.payload;
        },
        setexhibitsList: (state, action) => {
            state.exhibitIDList = action.payload;
        },
        setSelectedExhibitList: (state, action) => {
            state.selectedExhibitIDList = action.payload;
        },
        setCIKList: (state, action) => {
            state.cikList = action.payload;
        },
        setSelectedCIKList: (state, action) => {
            state.selectedCIKList = action.payload;
        },

        setSelectedLibraryList: (state, action) => {
            state.selectedLibraryList = action.payload;
        },
        setStateOfIncorporationList: (state, action) => {
            state.stateOfIncorporationList = action.payload;
        },
        setSelectedstateOfIncorporationList: (state, action) => {
            state.selectedStateOfIncorporationList = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },

        setIncludeKeyword: (state, action) => {
            state.includeKeyword = action.payload;
        },
        setExcludeKeyword: (state, action) => {
            state.excludeKeyword = action.payload;
        },
        setSelectedIncludeKeyword: (state, action) => {
            state.selectedincludeKeyword = action.payload;
        },
        setSelectedExcludeKeyword: (state, action) => {
            state.x = action.payload;
        },
        setSelectedFilteredTitle: (state, action) => {
            state.flteredTitle = action.payload;
        },
        setSelectedGroupIDs: (state, action) => {
            state.groupIDs = action.payload;
        },
        setOpenLibraryType: (state, action) => {
            state.openlibraryType = action.payload;
        },
    },
});

export const {
    setSearchItem,
    setSelectedActiveTab,
    setLibraryResult,
    setFullLibraryResult,
    setFullLibraryResultCount,
    setLibraryResultCount,
    setContractCount,
    setClauseCount,
    setDefinitionCount,
    setSearchPageCount,
    setIsLoading,
    setCompanyList,
    setDepartmentList,
    setGoverningLawList,
    setCountryList,
    setIndustryList,
    setSelectedCompany,
    setSelectedDepartment,
    setSelectedGoverningLaw,
    setSelectedCountry,
    setSelectedIndustry,
    setDocUrl,
    setContractTitleList,
    setSelectedContractTitle,
    setStateOfIncorporationList,
    setexhibitsList,
    setCIKList,
    setClausesList,
    setSelectedClause,
    setSearchValue,
    setSelectedFilters,
    setRemoveFilters,
    setAccessionNumberList,
    setSelectedAccessionNumberList,
    setEntityTypeList,
    setSelectedEntityTypeList,
    setFormsList,
    setSelectedFormsList,
    setStockExchangesList,
    setSelectedStockExchangesList,
    setSelectedstateOfIncorporationList,
    setSelectedExhibitList,
    setSelectedCIKList,
    setSelectedDate,
    setDocData,
    setSelectedIncludeKeyword,
    setSelectedExcludeKeyword,
    setIncludeKeyword,
    setExcludeKeyword,
    setSelectedFilteredTitle,
    setSelectedLibraryList,
    setSelectedGroupIDs,
    setOpenLibraryType,
    setRemoveAllFilters
} = librarySlice.actions;
export default librarySlice.reducer;

export const fetchSearchItemHnadler = (payload, type, offset, sort) => async (dispatch, getState) => {
    try {
        dispatch(setIsLoading(true));
        const response = await getLibraryList(payload, sort || 'relevance');

        if (response.data?.data) {
            dispatch(setLibraryResult(response?.data?.data || []));
            dispatch(setIsLoading(false));
        }
        else {
            dispatch(setLibraryResult([]));
            dispatch(setIsLoading(false));
        }
    } catch (error) {
        dispatch(setIsLoading(false));
    }
};
export const fetchFullSearchItemHnadler = (payload, type, offset, sort) => async (dispatch, getState) => {
    try {
        dispatch(setIsLoading(true));
        const response = await getLibraryList(payload, sort || 'relevance');

        if (response.data?.data) {
            dispatch(setFullLibraryResult(response?.data?.data || []));
            dispatch(setIsLoading(false));
        }
        else {
            dispatch(setFullLibraryResult([]));
            dispatch(setIsLoading(false));
        }
    } catch (error) {
        dispatch(setIsLoading(false));
    }
};


export const genericFilterHandler = (contract_data, contract_id, type = null, offset, sort) =>
    async (dispatch, getState) => {
        try {
            const selectedContractTitle = getState().library.selectedContractTitle;
            // const filterByTitle = getState().library.filterByTitle;
            const selectedCompany = getState().library.selectedCompany;
            const selectedIndustry = getState().library.selectedIndustry;
            const selectedGoverningLaw = getState().library.selectedGoverningLaw;
            const selectedCountry = getState().library.selectedCountry;
            const selectedDepartment = getState().library.selectedDepartment;
            const selectedClause = getState().library.selectedClause;
            const selectedAccessionNumber = getState().library.selectedAccessionNumberList;
            const selectedEntityTypeList = getState().library.selectedEntityTypeList;
            const selectedFormsList = getState().library.selectedFormsList;
            const selectedExchangesList = getState().library.selectedStockExchangesList;
            const selectedExhibitIDList = getState().library.selectedExhibitIDList;
            const selectedDateRange = getState().library.selectedDate;
            const selectedInclueKeyword = getState().library.includeKeyword;
            const selectedExcludeKeyword = getState().library.excludeKeyword;
            const selectedFlteredTitle = getState().library.flteredTitle;
            const selectedLibraryList = getState().library.selectedLibraryList;
            const selectedCIKList = getState().search.selectedCIKList;
            const selectedStateOfIncorporationList = getState().search.selectedStateOfIncorporationList;


            const payload = {
                filters: {}
            }

            if (contract_data === 'contract_data') {
                payload.contract_data = true
            }
            if (contract_id) {
                payload.contract_id = contract_id
            }

            if (selectedCompany) {
                payload.filters = {
                    ...payload.filters,
                    company: selectedCompany?.map(item => item._id),
                };
            }

            if (selectedContractTitle?.length) {
                payload.filters = {
                    ...payload.filters,
                    contract_type: selectedContractTitle?.map(item => item.name),
                };
            }
            if (selectedIndustry) {
                payload.filters = {
                    ...payload.filters,
                    industry: selectedIndustry?.map(item => item._id),
                };
            }
            if (selectedGoverningLaw) {
                payload.filters = {
                    ...payload.filters,
                    governing_law: selectedGoverningLaw?.map(item => item._id),
                };
            }
            if (selectedCountry) {
                payload.filters = {
                    ...payload.filters,
                    country: selectedCountry?.map(item => item.name),
                };
            }

            if (selectedDepartment) {
                payload.filters = {
                    ...payload.filters,
                    departments: selectedDepartment?.map(item => item._id),
                };
            }
            if (selectedClause) {
                payload.filters = {
                    ...payload.filters,
                    clauses: selectedClause?.map(item => item._id),
                };
            }
            if (selectedAccessionNumber) {
                payload.filters = {
                    ...payload.filters,
                    accession_number: selectedAccessionNumber?.map(item => item.value),
                };
            }
            if (selectedEntityTypeList) {
                payload.filters = {
                    ...payload.filters,
                    entity_type: selectedEntityTypeList?.map(item => item._id),
                };
            }
            if (selectedFormsList) {
                payload.filters = {
                    ...payload.filters,
                    forms: selectedFormsList?.map(item => item._id),
                };
            }
            if (selectedExchangesList) {
                payload.filters = {
                    ...payload.filters,
                    exchanges: selectedExchangesList?.map(item => item._id),
                };
            }
            if (selectedExhibitIDList) {
                payload.filters = {
                    ...payload.filters,
                    exhibit: selectedExhibitIDList?.map(item => item._id),
                };
            }
            if (selectedInclueKeyword) {
                payload.filters = {
                    ...payload.filters,
                    include_keywords: selectedInclueKeyword,
                };
            }
            if (selectedExcludeKeyword) {
                payload.filters = {
                    ...payload.filters,
                    exclude_keywords: selectedExcludeKeyword,
                };
            }
            if (selectedDateRange.length) {
                payload.filters = {
                    ...payload.filters,
                    filing_date_str_start: moment(selectedDateRange[0]).format('YYYY-MM-DD'),
                    filing_date_str_end: moment(selectedDateRange[1]).format('YYYY-MM-DD'),
                };
            }
            if (selectedFlteredTitle?.length) {
                payload.filters = {
                    ...payload.filters,
                    bookmark_name: selectedFlteredTitle,
                };
            }
            if (selectedLibraryList?.length) {
                payload.filters = {
                    ...payload.filters,
                    library_type: [selectedLibraryList[0].name],
                };
            }
            if (selectedCIKList) {
                payload.filters = {
                    ...payload.filters,
                    cik_number: selectedCIKList?.map(item => item.name),
                };
            }
            if (selectedStateOfIncorporationList) {
                payload.filters = {
                    ...payload.filters,
                    state_of_incorporation: selectedStateOfIncorporationList?.map(item => item.name),
                };
            }

            else {
                payload.filters = {
                    ...payload.filters,
                    library_type: ['my_library'],
                };
            }
            debugger
            dispatch(getAllsearchFilters(contract_data, contract_id))
            if (contract_data) {
                dispatch(fetchFullSearchItemHnadler(payload, 10, offset ? offset : 0, sort || 'relevance'))
            }
            else {
                dispatch(fetchSearchItemHnadler(payload, 10, offset ? offset : 0, sort || 'relevance'))
            }
        } catch (error) {
            console.log(error.message, "error.message");
            dispatch(setIsLoading(false));
        }
    };



export const getAllsearchFilters = (contract_data, contract_id, size, name) => async (dispatch, getState) => {
    const selectedContractTitle = getState().library.selectedContractTitle;
    const selectedCompany = getState().library.selectedCompany;
    const selectedIndustry = getState().library.selectedIndustry;
    const selectedGoverningLaw = getState().library.selectedGoverningLaw;
    const selectedCountry = getState().library.selectedCountry;
    const selectedDepartment = getState().library.selectedDepartment;
    const selectedClause = getState().library.selectedClause;
    const selectedAccessionNumber = getState().library.selectedAccessionNumberList;
    const selectedEntityTypeList = getState().library.selectedEntityTypeList;
    const selectedFormsList = getState().library.selectedFormsList;
    const selectedExchangesList = getState().library.selectedStockExchangesList;
    const selectedExhibitIDList = getState().library.selectedExhibitIDList;
    const selectedDateRange = getState().library.selectedDate;
    const selectedInclueKeyword = getState().library.includeKeyword;
    const selectedExcludeKeyword = getState().library.excludeKeyword;
    const selectedFlteredTitle = getState().library.flteredTitle;
    const selectedLibraryList = getState().library.selectedLibraryList;
    const selectedCIKList = getState().search.selectedCIKList;
    const selectedStateOfIncorporationList = getState().search.selectedStateOfIncorporationList;

    const payload = {
        filters: {}
    }

    if (contract_data) {
        payload.contract_data = true
    }
    if (contract_id) {
        payload.contract_id = contract_id
    }
    else {
        payload.contract_id = contract_id = getState().library.groupIDs
    }

    if (selectedCompany) {
        payload.filters = {
            ...payload.filters,
            company: selectedCompany?.map(item => item._id),
        };
    }

    if (selectedContractTitle?.length) {
        payload.filters = {
            ...payload.filters,
            contract_type: selectedContractTitle?.map(item => item.name),
        };
    }
    if (selectedIndustry) {
        payload.filters = {
            ...payload.filters,
            industry: selectedIndustry?.map(item => item._id),
        };
    }
    if (selectedGoverningLaw) {
        payload.filters = {
            ...payload.filters,
            governing_laws: selectedGoverningLaw?.map(item => item._id),
        };
    }
    if (selectedCountry) {
        payload.filters = {
            ...payload.filters,
            country: selectedCountry?.map(item => item.name),
        };
    }

    if (selectedDepartment) {
        payload.filters = {
            ...payload.filters,
            departments: selectedDepartment?.map(item => item._id),
        };
    }
    if (selectedClause) {
        payload.filters = {
            ...payload.filters,
            clauses: selectedClause?.map(item => item._id),
        };
    }
    if (selectedAccessionNumber) {
        payload.filters = {
            ...payload.filters,
            accession_number: selectedAccessionNumber?.map(item => item.value),
        };
    }
    if (selectedEntityTypeList) {
        payload.filters = {
            ...payload.filters,
            entity_type: selectedEntityTypeList?.map(item => item._id),
        };
    }
    if (selectedFormsList) {
        payload.filters = {
            ...payload.filters,
            form: selectedFormsList?.map(item => item._id),
        };
    }
    if (selectedExchangesList) {
        payload.filters = {
            ...payload.filters,
            exchanges: selectedExchangesList?.map(item => item._id),
        };
    }
    if (selectedExhibitIDList) {
        payload.filters = {
            ...payload.filters,
            exhibit: selectedExhibitIDList?.map(item => item._id),
        };
    }
    if (selectedInclueKeyword) {
        payload.filters = {
            ...payload.filters,
            include_keywords: selectedInclueKeyword,
        };
    }
    if (selectedExcludeKeyword) {
        payload.filters = {
            ...payload.filters,
            exclude_keywords: selectedExcludeKeyword,
        };
    }
    if (selectedDateRange.length) {
        payload.filters = {
            ...payload.filters,
            filing_date_str_start: moment(selectedDateRange[0]).format('YYYY-MM-DD'),
            filing_date_str_end: moment(selectedDateRange[1]).format('YYYY-MM-DD'),
        };
    }

    if (selectedFlteredTitle?.length) {
        payload.filters = {
            ...payload.filters,
            bookmark_name: selectedFlteredTitle,
        };
    }

    if (selectedLibraryList?.length) {
        payload.filters = {
            ...payload.filters,
            library_type: [selectedLibraryList[0].name],
        };
    }

    if (selectedCIKList) {
        payload.filters = {
            ...payload.filters,
            cik_number: selectedCIKList?.map(item => item.name),
        };
    }
    if (selectedStateOfIncorporationList) {
        payload.filters = {
            ...payload.filters,
            state_of_incorporation: selectedStateOfIncorporationList?.map(item => item.name),
        };
    }
    else {
        payload.filters = {
            ...payload.filters,
            library_type: ['my_library'],
        };
    }
    debugger
    dispatch(fetchAllLibraryFilterHandler(payload))

};

export const fetchAllLibraryFilterHandler = (payload) => async (dispatch, getState) => {
    try {
        const response = await fetchAllLibraryFilter(payload);
        debugger
        if (response.data.company) {
            const temp = response.data.company.map((item) => {
                return {
                    _id: item._id,
                    name: item.name,
                    label: item.name.toLowerCase(),
                    value: item._id,
                };
            });
            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setCompanyList(finalData || []));
        }

        if (response.data.industry) {
            const filteredData = response.data.industry.filter(item => item?.name.length > 0);
            const temp = filteredData.map((item) => {
                return {
                    _id: item.sic_code,
                    name: item.name,
                    label: item.name.toLowerCase(),
                    value: item.sic_code,
                };
            });
            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setIndustryList(finalData || []));

        }

        if (response.data.departments) {
            const temp = response.data.departments.map((item) => {
                return {
                    _id: item.department_id,
                    department_id: item.department_id,
                    name: item.department_type,
                    label: item.department_type.toLowerCase(),
                    value: item._id,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }

            dispatch(setDepartmentList(finalData || []));
        }

        if (response.data.governing_law) {
            const filteredData = response.data.governing_law.filter(item => item?.governing_law !== null);
            const temp = filteredData.map((item) => {
                return {
                    _id: item.governing_law_id,
                    name: item.governing_law,
                    label: item.governing_law.toLowerCase(),
                    value: item.governing_law_id,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }

            dispatch(setGoverningLawList(finalData || []));

        }

        if (response?.data?.country) {
            const filteredData = response?.data?.country.filter(item => item !== null);
            const temp = filteredData.map((item, index) => {
                return {
                    _id: index,
                    name: item,
                    label: item.toLowerCase(),
                    value: item,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setCountryList(finalData || []));
        }

        if (response.data.contract_title) {
            const filteredData = response.data.contract_title.filter(item => item !== null);
            const temp = filteredData.map((item, index) => {
                return {
                    _id: index,
                    name: item,
                    label: item.toLowerCase(),
                    value: item,
                };
            });
            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }

            dispatch(setContractTitleList(finalData || []));
            // dispatch(setContractTitleList(temp || []));
        }

        if (response.data.clauses) {
            const temp = response.data.clauses.map((item, index) => {
                return {
                    _id: item.clause_id,
                    name: item.heading,
                    label: item.heading.toLowerCase(),
                    value: item.clause_id,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setClausesList(finalData || []));
        }

        if (response.data.accession_number) {
            const filteredData = response.data.accession_number.filter(item => item !== null);
            const temp = filteredData.map((item, index) => {
                return {
                    _id: index,
                    name: item,
                    label: item?.toLowerCase(),
                    value: item,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setAccessionNumberList(finalData || []));
        }

        debugger
        if (response.data.entity_type) {
            const temp = response.data.entity_type.map((item, index) => {
                return {
                    _id: item?.entity_type_id,
                    name: item?.entity_type,
                    label: item?.entity_type.toLowerCase(),
                    value: item?.entity_type,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setEntityTypeList(finalData || []));
        }

        if (response.data.form) {
            const temp = response.data.form.map((item, index) => {
                return {
                    _id: item?.form_id,
                    name: item?.form,
                    label: item?.form.toLowerCase(),
                    value: item?.form,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setFormsList(finalData || []));
        }

        if (response.data.exchanges) {
            const temp = response.data.exchanges.map((item, index) => {
                return {
                    _id: item?.exchanges_id,
                    name: item?.exchanges,
                    label: item?.exchanges.toLowerCase(),
                    value: item?.exchanges,
                };
            });
            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }

            dispatch(setStockExchangesList(finalData || []));
        }

        if (response.data.exhibit) {
            debugger
            const temp = response.data.exhibit.map((item, index) => {
                return {
                    _id: item.exhibit_id,
                    name: item?.exhibit,
                    label: item?.exhibit.toLowerCase(),
                    value: item?.exhibit,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setexhibitsList(finalData || []));

        }

        if (response.data.cik) {
            const filteredData = response.data.cik.filter(item => item !== "" || item !== null);
            const temp = filteredData.map((item, index) => {
                return {
                    _id: index,
                    name: item,
                    label: item.toLowerCase(),
                    value: item,
                };
            });

            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setCIKList(finalData || []));
        }

        if (response.data.state_of_incorporation) {
            const filteredData = response.data.state_of_incorporation.filter(item => item !== "" || item !== null);
            const temp = filteredData.map((item, index) => {
                return {
                    _id: index,
                    name: item,
                    label: item,
                    value: item,
                };
            });
            let finalData = {
                data: temp,
                total_count: response?.data?.count
            }
            dispatch(setStateOfIncorporationList(finalData || []));
        }

    } catch (error) {
        dispatch(setIsLoading(false));
    }
};



