import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem('token');
    if (token) {
      reqConfiglocal.headers.Authorization = `Bearer ${token}`;
    }

    return reqConfiglocal;
  },
  (error) => {
    console.log(error, 'global_error');
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response, 'global_response');
    return response;
  },
  (error) => {
    console.log(error.response, 'error_main');
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>

        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
reportWebVitals();
