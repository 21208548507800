import {
  Box,
  Heading,
  SimpleGrid
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchAllCaseStudy } from "../../service/CMSService";
import { AiOutlineDownload } from "react-icons/ai";
import { TabPanel, useTabs } from "react-headless-tabs";
import SEO from "../../component/SEO";


const CaseStudy = () => {
  const [caseStudyList, setCaseStudyList] = useState([]);
  const [whitePaperList, setWhitePapaerList] = useState([]);

  const fetchAllCaseStudyHandler = async () => {
    const res = await fetchAllCaseStudy();
    setCaseStudyList(res.data.data.filter((j) => j.is_case));
    setWhitePapaerList(res.data.data.filter((j) => j.is_whitepaper));
  };


  const [selectedTab, setSelectedTab] = useTabs(["CaseStudies", "Whitepapers"]);


  useEffect(() => {
    fetchAllCaseStudyHandler();
    if (window.location.search === "?page=Whitepapers") {
      setSelectedTab("Whitepapers")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <>
      <SEO
        title={"CaseStudies" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />

      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading>Case Studies and Whitepapers</Heading>
        </Box>
      </Box>
      <div className="container casetab">
        <Box mt={5}>

          <nav className="flex border-b border-gray-300">
            <TabSelector
              isActive={selectedTab === "CaseStudies"}
              onClick={() => setSelectedTab("CaseStudies")}
            >
              Case Studies
            </TabSelector>
            <TabSelector
              isActive={selectedTab === "Whitepapers"}
              onClick={() => setSelectedTab("Whitepapers")}
            >
              Whitepapers
            </TabSelector>
          </nav>
          <div className="casetab_content">
            <TabPanel hidden={selectedTab !== "CaseStudies"}>
              <SimpleGrid columns={[1, 2, 3]} >
                {caseStudyList.map((item, i) => (
                  <>
                    <Box mb={3} mt={3} px={3} key={i}>
                      <Heading fontSize="2xl" textTransform="capitalize" mb={2}>
                        {item?.heading}
                      </Heading>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.short_description,
                        }}
                      />
                      {item?.file ?
                        <a href={item?.file} target="_blank" rel="noreferrer" className="download_btn">
                          <AiOutlineDownload /> Downlod PDF
                        </a>
                        :
                        ""
                      }
                    </Box>
                  </>
                ))}
              </SimpleGrid>
            </TabPanel>
            <TabPanel hidden={selectedTab !== "Whitepapers"}>
              <SimpleGrid columns={[1, 2, 3]} >
                {whitePaperList.map((item, i) => (
                  <>
                    <Box mb={3} mt={3} px={3} key={i}>
                      <Heading fontSize="2xl" textTransform="capitalize" mb={2}>
                        {item?.heading}
                      </Heading>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.short_description,
                        }}
                      />
                      {item?.file ?
                        <a href={item?.file} target="_blank" rel="noreferrer" className="download_btn">
                          <AiOutlineDownload /> Downlod PDF
                        </a>
                        :
                        ""
                      }
                    </Box>
                  </>
                ))}
              </SimpleGrid>
            </TabPanel>
          </div>

        </Box>
      </div>
    </>
  );
};

export default CaseStudy;


export const TabSelector = ({
  isActive,
  children,
  onClick,
}) => (
  <button
    className={`items-center  ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {children}
  </button>
);