import { Button, IconButton, Modal, Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { MdCheck, MdOutlineClose } from 'react-icons/md';

const ModalComponent = ({ open, handleClose, children, width, maxWidth, padding, closebutton, height, ...rest }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width || '400px',
        // height: height || 'calc(100% - 30px)',
        maxWidth: maxWidth || 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: padding || 1.5,
        // maxHeight: 'calc(100% - 30px)',
        overflow: 'auto',
    };
    return (
        <>
            <Modal open={open} onClose={handleClose} {...rest} >
                <Box sx={style} className="kd_modal">
                    {closebutton &&
                        <IconButton className="close_modal" color="primary" onClick={handleClose} component="label">
                            <CloseIcon />
                        </IconButton>
                    }
                    {children}
                </Box>
            </Modal>
        </>
    );
};

export default ModalComponent;
