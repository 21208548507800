import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
export default function SEO(props) {
  const { title, description, metaKeywords, ogTag, url } = props
  useEffect(() => {
    // Parse the ogTags string and set the meta tags dynamically
    const metaTags = parseOgTags(ogTag);
    setMetaTags(metaTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMetaTags = (metaTags) => {
    const head = document.getElementsByTagName('head')[0];
    metaTags.forEach((metaTag) => {
      const tag = document.createElement('meta');
      tag.setAttribute('property', metaTag.property);
      tag.content = metaTag.content;
      head.appendChild(tag);
    });
  };

  const parseOgTags = (ogTagsString) => {
    const regex = /property='([^']+)' content='([^']+)'/g;
    const metaTags = [];
    let match;
    while ((match = regex.exec(ogTagsString))) {
      const [, property, content] = match;
      metaTags.push({ property, content });
    }
    return metaTags;
  };


  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={metaKeywords} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {/* <meta property="og:type" content={type} /> */}
      {/* <meta property="og:title" content={title} /> */}
      {/* <meta property="og:description" content={description} /> */}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {/* <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} /> */}
      {url && <link rel="canonical" href={url} />}
    </Helmet>
  );
}
