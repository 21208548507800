/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  Tab,
  Tabs,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  MdKeyboardArrowUp,
  MdOutlineExpandMore,
} from "react-icons/md";
import MoreFilters from "./MoreFilters";
import Search from "../../component/Search";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSelectedDate } from "../../redux/searchSlice";
import { FaChevronRight, FaRegFileAlt } from "react-icons/fa";
import { PiArticleLight, PiTextAlignJustifyLight } from "react-icons/pi";
import word from '../../assets/images/word.svg';
import Slider from "react-slick";
import { getAllBlog, getAllPage } from "../../service/CMSService";
import Footer from "../../component/Footer";
import { useNavigate } from "react-router-dom";


function Home() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [radioItem, setRadioItem] = useState("Kronicle Library");
  const [moreFilterShow, setMoreFilterShow] = useState(false);
  const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem('activeTab')));
  let searchData = useSelector((state) => state.search);


  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(setSelectedDate(''))
    if (!localStorage.getItem('activeTab')) {
      localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
      setActiveTab({ contractType: true, clause: false, definition: false })
    }
  })

  useEffect(() => {
    let showmore = searchData?.selectedCompany?.length || searchData?.selectedDepartment?.length || searchData?.selectedIndustry?.length || searchData?.selectedGoverningLaw?.length
    if (showmore) {
      setMoreFilterShow(true)
    }
  }, [searchData])

  const handleCheck = (e) => {
    setRadioItem(e.target.value);
  };

  const [searchQuery, setSearchQuery] = useState('')


  const [pages, setPages] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getAllPageHanlder = async () => {
    const res = await getAllPage();
    setPages(res.data.data || []);
  };

  useEffect(() => {
    getAllPageHanlder();
    getAllBlogHandler();
  }, []);

  const getAllBlogHandler = async () => {
    const res = await getAllBlog();
    setBlogs(res.data.data || []);
  };



  console.log("searchQuery", searchQuery)

  return (
    <>
      <div style={{ background: "#fff" }}>
        <div className="sub_header ">
          <div className="container">
            <ul>
              <li> <a onClick={() => navigate(`/Library/my_library`)}> My Library </a> </li>
              <li> <a onClick={() => navigate(`/Library/client_library`)}> Client Library </a> </li>
              <li> <a onClick={() => navigate(`/Library/team_library`)}> Teams Library </a> </li>
            </ul>
          </div>
        </div>



        <div className="home_banner">
          <div className="home_banner_left">
            <div className="home_banner_left_wrp">
              <h1> Kronicle Dealbase - One search, Infinite Contract Insights </h1>

              <div className="home_banner_search">
                <Tabs sx={{ width: '100%', height: "50px" }} aria-label="wrapped label tabs example" >
                  <Tab
                    value="one"
                    label="Contract Type"
                    sx={{ color: "white" }}
                    className={activeTab?.contractType ? "active" : ""}
                    onClick={() => {
                      setActiveTab({ contractType: true, clause: false, definition: false });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: true, clause: false, definition: false }))
                    }}
                  />
                  <Tab
                    value="two"
                    label="Clause"
                    sx={{ color: "white" }}
                    className={activeTab?.clause ? "active" : ""}
                    onClick={() => {
                      setActiveTab({ contractType: false, clause: true, definition: false });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: true, definition: false }))
                    }}
                  />
                  <Tab
                    value="three"
                    label="Definition"
                    sx={{ color: "white" }}
                    className={activeTab?.definition ? "active" : ""}
                    onClick={() => {
                      setActiveTab({ contractType: false, clause: false, definition: true, });
                      localStorage.setItem("activeTab", JSON.stringify({ contractType: false, clause: false, definition: true }))
                    }}
                  />
                </Tabs>

                <Search activeTab={activeTab} getSearchQuery={setSearchQuery} />
                <div className="home_banner_search_radio">
                  <FormControl className="home_banner_search_radio_left">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="Kronicle Library"
                      name="row-radio-buttons-group"
                      sx={{ pl: 1 }}
                      onChange={handleCheck}
                    >
                      <FormControlLabel
                        className="radio-icon"
                        value="Kronicle Library"
                        control={<Radio sx={{ color: "#909090" }} />}
                        label={
                          <Typography sx={{ fontSize: "16px", fontWeight: 500, color: radioItem === "Kronicle Library" ? "#2196f3" : "#909090" }} >
                            Kronicle Library
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="My Library"
                        control={<Radio sx={{ color: "#909090" }} />}
                        label={
                          <Typography sx={{ fontSize: "16px", fontWeight: 500, color: radioItem === "My Library" ? "#2196f3" : "#909090" }} >
                            My Library
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Teams Library"
                        control={<Radio sx={{ color: "#909090" }} />}
                        label={
                          <Typography sx={{ fontSize: "16px", fontWeight: 500, color: radioItem === "Teams Library" ? "#2196f3" : "#909090" }} >
                            Teams Library
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Client Library"
                        control={<Radio sx={{ color: "#909090" }} />}
                        label={
                          <Typography sx={{ fontSize: "16px", fontWeight: 500, color: radioItem === "Client Library" ? "#2196f3" : "#909090" }} >
                            Client Library
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>


                  <span className="more-filter" onClick={() => setMoreFilterShow(!moreFilterShow)} >
                    {moreFilterShow ? "Less Filter" : "More Filter"}
                    {moreFilterShow ? (<MdKeyboardArrowUp />) : (<MdOutlineExpandMore />)}
                  </span>
                </div>
              </div>

              <div className="home_banner_search_morefilter">
                {moreFilterShow && <MoreFilters activeTab={activeTab} searchQuery={searchQuery} />}

              </div>

              <Stack direction={'row'} gap={3} flexWrap={"wrap"} className="home_banner_search_footer">

                <Box className="home_banner_search_demo" justifyContent={"left"} sx={{ display: "flex" }}>
                  <Grid>
                    <Button
                      sx={{
                        width: "230px",
                        height: "48px",
                        fontSize: "20px",
                        fontWeight: 500,
                        backgroundColor: "#00A0DC",
                        boxShadow: "none",
                        '&:hover': {
                          backgroundColor: '#0088bb',
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="secondary"
                    >
                      Buy Now
                    </Button>
                  </Grid>

                  <Grid>
                    <Button
                      sx={{
                        width: "230px",
                        height: "48px",
                        fontSize: "20px",
                        fontWeight: 400,
                        borderRadius: "4px",
                        border: "1px solid #54515C",
                        boxShadow: "none",
                      }}
                      type="submit"
                      variant="contained"
                      style={{ background: "white", color: "#54515C" }}
                    >
                      Request Demo
                    </Button>
                  </Grid>
                </Box>
                <Stack direction={'row'} gap={3} flexWrap={"wrap"}>
                  <div className="home_banner_search_footer_left">
                    <h4> Built for your productivity </h4>
                    <p> Download Kronicle Word Add-in Tool </p>
                  </div>
                  <div className="home_banner_search_footer_right">
                    <span> <img src={word} alt="word" /> Download Now <FaChevronRight /> </span>
                  </div>
                </Stack>
              </Stack>
            </div>
          </div>

          <div className="home_banner_right">
            <div className="home_banner_right_img">
              <img src='/images/banner_gear.png' alt="home_banner_gear" draggable="false" />
            </div>
          </div>
        </div>

        {/* See what’s popular? */}
        <div className="container home_popular">
          <div className="section_title">
            <h2> See what’s popular? </h2>
          </div>
          <Stack direction={"row"} flexWrap={"wrap"} gap={3} className="home_popular_wrp">
            <div className="home_popular_wrp_item">
              <h4> <FaRegFileAlt size={32} fill="#8099FF" /> Contracts </h4>
              <ul>
                <li> <a> Employment Agreement  </a></li>
                <li> <a> Lease Agreement  </a></li>
                <li> <a> Service Agreement  </a></li>
                <li> <a> Loan Agreement  </a></li>
                <li> <a> License Agreement  </a></li>
              </ul>
            </div>

            <div className="home_popular_wrp_item">
              <h4> <PiArticleLight size={32} fill="#FF8A00" /> Clauses </h4>
              <ul>
                <li> <a> Confidentiality </a></li>
                <li> <a> Indemnity </a></li>
                <li> <a> Force Majeure </a></li>
                <li> <a> Non Compete </a></li>
                <li> <a> Non Solicitation </a></li>
              </ul>
            </div>

            <div className="home_popular_wrp_item">
              <h4> <PiTextAlignJustifyLight size={32} fill="#B500E3" /> Definitions </h4>
              <ul>
                <li> <a> Tax Identification Number  </a></li>
                <li> <a> Per Annum  </a></li>
                <li> <a> Confidential Information  </a></li>
                <li> <a> Business Day  </a></li>
                <li> <a> Registered Office  </a></li>
              </ul>
            </div>

          </Stack>
        </div>


        {/* Features */}
        <div className="container home_features">
          <div className="section_title">
            <h2> Features </h2>
          </div>
          <Stack direction={"row"} className="home_features_wrp">
            <div className="home_features_wrp_item">
              <img src="images/product_img/ContractQuery.svg" alt="ContractQuery" />
              <div className="home_features_wrp_item_content">
                <h3> Contract Query </h3>
                <p> Over 2 million executed contracts in a searchable format with over 15 filters across deal points </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src="images/product_img/ClauseQuery.svg" alt="ClauseQuery" />
              <div className="home_features_wrp_item_content">
                <h3> Clause Query </h3>
                <p> Query from over /40 million unique clauses/ definitions. Kronicle indexes all the clauses in contracts as per SALI clause identifiers </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src="images/product_img/ContractDiscovery.svg" alt="ContractDiscovery" />
              <div className="home_features_wrp_item_content">
                <h3> Contract Discovery </h3>
                <p> Identify contracts based on how they are organized, what clauses they contain, the standard language for their clauses, and the full range of deal-specific variations. </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src="images/product_img/DealAnalytics.svg" alt="DealAnalytics" />
              <div className="home_features_wrp_item_content">
                <h3> Deal Analytics </h3>
                <p> Get extensive insights through specialised contract review of executed contracts and comprehensive deal data. </p>
              </div>
            </div>

          </Stack>
        </div>



        {/* Benefit */}
        <div className="container home_benefit">
          <div className="section_title">
            <h2> How you can benefit? </h2>
          </div>
          <div className="home_benefit_wrp">
            <Stack className="home_benefit_wrp_item">
              <img src="images/product_img/SaveTime.svg" alt="SaveTime" />
              <div className="home_features_wrp_item_content">
                <h3> Save Time </h3>
                <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
              </div>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <img src="images/product_img/MinimiseRisk.svg" alt="MinimiseRisk" />
              <div className="home_features_wrp_item_content">
                <h3> Minimise Risk </h3>
                <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
              </div>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <img src="images/product_img/GainInsights.svg" alt="GainInsights" />
              <div className="home_features_wrp_item_content">
                <h3> Gain Insights </h3>
                <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
              </div>
            </Stack>

            <Stack className="home_benefit_wrp_item">
              <img src="images/product_img/MasterNegotiations.svg" alt="MasterNegotiations" />
              <div className="home_features_wrp_item_content">
                <h3> Master Negotiations </h3>
                <p> Accelerate your contract drafting process with instant access to millions of contracts, clauses and definitions. </p>
              </div>
            </Stack>
          </div>
        </div>




        {/* Libraries */}
        <div className="container home_features libraries">
          <div className="section_title">
            <h2> Your Libraries </h2>
          </div>
          <Stack direction={"row"} justifyContent={'center'} className="home_features_wrp">
            <div className="home_features_wrp_item">
              <img src="images/product_img/MyLibrary.svg" alt="MyLibrary" />
              <div className="home_features_wrp_item_content">
                <h3> My Library </h3>
                <p> Lorem ipsum dolor sit amet consectetur. Semper integer dictum enim interdum diam </p>
              </div>
            </div>
            <div className="home_features_wrp_item">
              <img src="images/product_img/ClientLibrary.svg" alt="ClientLibrary" />
              <div className="home_features_wrp_item_content">
                <h3> Client Library </h3>
                <p> Lorem ipsum dolor sit amet consectetur. Semper integer dictum enim interdum diam </p>
              </div>
            </div>

            <div className="home_features_wrp_item">
              <img src="images/product_img/TeamsLibrary.svg" alt="TeamsLibrary" />
              <div className="home_features_wrp_item_content">
                <h3> Teams Library </h3>
                <p> Lorem ipsum dolor sit amet consectetur. Semper integer dictum enim interdum diam </p>
              </div>
            </div>

          </Stack>
        </div>



        {/* our tools */}
        <div className="home_ourtools">
          <div className="container ">
            <div className="section_title">
              <h2> Speed contract drafting & review with our tools </h2>
            </div>
            <Slider {...settings}>
              <div className="home_ourtools_item">
                <div className="home_ourtools_item_wrp">
                  <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                    <div className="home_ourtools_item_top_image">
                      <img src="/images/product_img/KronicleAutomate.svg" alt="KronicleAutomate" />
                    </div>
                    <div className="home_ourtools_item_top_title">
                      Kronicle Automate
                    </div>
                  </Stack>
                  <div className="home_ourtools_item_content">
                    <p>
                      Enjoy fast, compliant document creation, e-signatures, and with electronic proof of delivery records with real-time tracking on any device.
                    </p>
                    <a> Know More <FaChevronRight /> </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/KronicleReview.svg" alt="KronicleReview" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Kronicle Review
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Enjoy fast, compliant document creation, e-signatures, and with electronic proof of delivery records with real-time tracking on any device.
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/KronicleDraft.svg" alt="KronicleDraft" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Kronicle Draft
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Enjoy fast, compliant document creation, e-signatures, and with electronic proof of delivery records with real-time tracking on any device.
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="home_ourtools_item">
                  <div className="home_ourtools_item_wrp">
                    <Stack direction={"row"} alignItems={"center"} gap={2} className="home_ourtools_item_top">
                      <div className="home_ourtools_item_top_image">
                        <img src="/images/product_img/MyLibrary.svg" alt="MyLibrary" />
                      </div>
                      <div className="home_ourtools_item_top_title">
                        Kronicle Automate
                      </div>
                    </Stack>
                    <div className="home_ourtools_item_content">
                      <p>
                        Enjoy fast, compliant document creation, e-signatures, and with electronic proof of delivery records with real-time tracking on any device.
                      </p>
                      <a> Know More <FaChevronRight /> </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>


        {/* our tools */}
        <div className="container home_blog ">
          <div className="section_title">
            <h2> Our Blogs </h2>
          </div>
          <div className="home_blog_wrp">
            {blogs.filter((_) => _.is_latest_blog === true)
              .map((item, i) => {
                return (
                  <>
                    <div className="home_blog_item">
                      <a href={`/blog/${item.seoURL}`} target="_blank" rel="noreferrer" >
                        <div className="home_blog_item_img">
                          <img src={item.image} alt="blog_img" />
                        </div>
                        <div className="home_blog_item_content">
                          <h4> {item.title} </h4>
                          <p dangerouslySetInnerHTML={{ __html: item.pageContent }} ></p>
                        </div>
                      </a>
                    </div>
                  </>
                );
              })}
          </div>

        </div>



      </div >




      {/* footer */}


      <Footer pages={pages} />
      {/* < footer style={{ display: "flex" }
      }>
        <div className="container">
          <Grid item xs={12} mt={10} >
            <Grid
              container
              p={5}
              pt={0}
              spacing={5}
            >
              <Grid item xs={12} md={4}>
                <a href={localStorage.getItem('token') ? '/' : '/'} style={{ display: "inline-block" }}>
                  <img src={logo} alt="Kronicle" style={{ width: "125px" }} />
                </a>
                <Typography
                  sx={{ margin: "32px 0", fontSize: "16px", fontWeight: 500 }}
                >
                  KRONICLE RESEARCH PRIVATE LIMITED
                </Typography>
                <Stack direction="row" gap={3}>
                  <a> <BsInstagram size={24} color="#005287" /> </a>
                  <a> <BsFacebook size={24} color="#005287" /> </a>
                  <a> <BsTwitter size={24} color="#005287" /> </a>
                  <a> <BsLinkedin size={24} color="#005287" /> </a>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={2} className="footer_item">
                <Stack>
                  <h3 style={{ fontSize: "20px", fontWeight: 500, color: "#005287" }} > Company </h3>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > About US </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Contact US </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Join Our Team </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Privacy Policy </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Terms & Conditions </a>

                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className="footer_item">
                <Stack>
                  <h3
                    style={{ fontSize: "20px", fontWeight: 500, color: "#005287" }}
                  >
                    Support
                  </h3>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Pricing </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Tech Support </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Join Sales Desk </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Privacy Policy </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Terms & Conditions </a>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className="footer_item">
                <Stack>
                  <h3
                    style={{ fontSize: "20px", fontWeight: 500, color: "#005287" }}
                  >
                    Products
                  </h3>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Review </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Assemble </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Contract DB </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Judgement DB </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Additor </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Abstract </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Affix </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Timer </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Billing </a>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={2} className="footer_item">
                <Stack>
                  <h3 style={{ fontSize: "20px", fontWeight: 500, color: "#005287" }} > Resources
                  </h3>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Contract Library </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Clause Library </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Case Studies </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > White papers </a>

                  <a style={{ fontSize: "16px", fontWeight: 300, marginTop: "10px" }} > Blogs </a>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </footer > */}

    </>
  );
}

export default Home;
