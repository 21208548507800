/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Button, CardContent, FormGroup, Link, Modal, Stack, TextField, FormControlLabel, Checkbox, InputLabel, IconButton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { createBookmark, getAllCustomBMList, getAllbookmarkList, removeBookmark, removeGPBookmark } from '../../service/BookmarkService'
import Toast from '../../component/Toast'
import CustomButton from '../../ui-component/button';
import WarningIcon from '@mui/icons-material/Warning';
import { MdArrowDropDown, MdArrowDropUp, MdClose, MdOutlineBookmarkAdd, MdRemoveRedEye } from 'react-icons/md';
import LibraryMiuiAsyncSelect from './LibraryMiuiAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { RiDeleteBinLine } from "react-icons/ri";
import CloseIcon from '@mui/icons-material/Close';
import {
    genericFilterHandler,
    setDocData,
    setFullLibraryResult,
    setSelectedAccessionNumberList,
    setSelectedCIKList,
    setSelectedClause,
    setSelectedContractTitle,
    setSelectedCountry,
    setSelectedDate,
    setSelectedDepartment,
    setSelectedEntityTypeList,
    setSelectedExcludeKeyword,
    setSelectedExhibitList,
    setSelectedFilters,
    setSelectedFormsList,
    setSelectedGoverningLaw,
    setSelectedIncludeKeyword,
    setSelectedIndustry,
    setSelectedStockExchangesList,
    setSelectedstateOfIncorporationList,
    setSelectedFilteredTitle,
    setSelectedGroupIDs,
    setSelectedLibraryList,
    setOpenLibraryType,
    setRemoveAllFilters,
    setIncludeKeyword,
    setExcludeKeyword,
} from "../../redux/librarySlice";
import { DateRangePicker, Loader, SelectPicker } from 'rsuite';
import styled from 'styled-components';
import { CgScreenShot } from 'react-icons/cg';
import { AiFillCaretRight } from 'react-icons/ai';
import SaveBM from '../SearchResult/SaveBM';
import ViewFullScreen from './ViewFullScreen';
import LibraryMiuiSingleSelect from './LibraryMiuiSingleSelect';
import { getAllClient } from '../../service/UserService';
import AddIcon from '@mui/icons-material/Add';
import ModalComponent from '../../component/Modal';
import RemoveClient from './RemoveClient';
import { createCustomClient } from '../../service/Library';
import MiuiSelectPopup from '../../ui-component/MiuiSelectPopup';
import ChipInput from 'material-ui-chip-input';


const data = [
    { label: "Sort by - Relevance", value: "relevance" },
    { label: "Sort by - Latest", value: "newest" },
    { label: "Sort by - Older", value: "oldest" },
]


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#005287',
    backgroundColor: 'transparent',
    borderColor: '#005287'
}));


function Library() {
    const rootRef = useRef(null);
    const divRef = useRef(null);
    const dispatch = useDispatch();
    const [bookmarkList, setBookmarkList] = useState([])
    const [toastData, setToastData] = useState({ open: false, message: '', status: '' });
    const [moreFilter, setMoreFilter] = useState(false);
    const { libraryResult, fullLibraryResult, flteredTitle, docData, isLoading, governingLawList, selectedFilters, departmentList, industryList, contractTitleList, clausesList, cikList, accessionNumberList,
        entityTypeList, formsList, stockExchangesList, countryList, stateOfIncorporationList, exhibitIDList
    } = useSelector((state) => state.library);
    let searchData = useSelector((state) => state.library);
    const [sellAllresults, setSellAllresults] = useState(false);
    const [fullResultData, setfullResultData] = useState([]);
    const [fullResultDataTitle, setfullResultDataTitle] = useState('');
    const [previewShow, setPreviewShow] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [customBookMarkModal, setCustomBookMarkModal] = useState(false);
    const [customCreateClient, setCustomCreateClient] = useState(false);
    const [viewFullViewer, setViewFullViewer] = useState(false)
    const [showSaveDD, setShowSaveDD] = useState(false)
    const [searchScreen, setSearchScreen] = useState(false)
    const [activeTab, setActiveTab] = useState('')
    const [groupIds, setGroupIds] = useState([])
    const [libraryList, setLibraryList] = useState([
        {
            _id: 'my_library',
            name: 'my_library',
            label: 'My Library',
            value: 'my_library',
        },
        {
            _id: 'team_library',
            name: 'team_library',
            label: 'Team Library',
            value: 'team_library',
        },
        {
            _id: 'client_library',
            name: 'client_library',
            label: 'Client Library',
            value: 'client_library',
        },
    ])

    const [clientList, setClientList] = useState([])
    const [open, setOpen] = React.useState(false);
    const [customClientName, setCustomClientName] = useState('')
    const [CCmodal, setCCmodal] = useState(false)


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpen(false);
    };

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const handleCloseCCModal = (event, reason) => {
        setCCmodal(false);
    };


    // useEffect(() => {
    //     if (window.location.pathname.split('/')[2]) {
    //         window.location.pathname.split('/')[2] === 'my_library' && dispatch(setSelectedLibraryList([{ "_id": "my_library", "name": "my_library", "label": "My Library", "value": "my_library" }]))
    //         window.location.pathname.split('/')[2] === 'client_library' && dispatch(setSelectedLibraryList([{ "_id": "client_library", "name": "client_library", "label": "Client Library", "value": "client_library" }]))
    //         window.location.pathname.split('/')[2] === 'team_library' && dispatch(setSelectedLibraryList([{ "_id": "team_library", "name": "team_library", "label": "Team Library", "value": "team_library" }]))
    //     }
    // }, [window.location.pathname.split('/')[2]])





    //handle All fecth filterdata
    useEffect(() => {
        if (window.location.pathname.split('/')[2]) {
            window.location.pathname.split('/')[2] === 'my_library' && dispatch(setSelectedLibraryList([{ "_id": "my_library", "name": "my_library", "label": "My Library", "value": "my_library" }]))
            window.location.pathname.split('/')[2] === 'client_library' && dispatch(setSelectedLibraryList([{ "_id": "client_library", "name": "client_library", "label": "Client Library", "value": "client_library" }]))
            window.location.pathname.split('/')[2] === 'team_library' && dispatch(setSelectedLibraryList([{ "_id": "team_library", "name": "team_library", "label": "Team Library", "value": "team_library" }]))
        }
        dispatch(genericFilterHandler())
        if (!localStorage.getItem('token')) {
            handleOpen(true)
        }
        else {
            fetchBookmarkList()
            fetchCustomBookmarkList()
            fetchClientList()
        }
    }, [])

    //fetch bookmark list
    const fetchBookmarkList = async () => {
        const res = await getAllbookmarkList();
        if (res.data) {
            setBookmarkList(res?.data)
        }
    }


    const fetchCustomBookmarkList = async () => {
        const res = await getAllCustomBMList();
        if (res) {
            const temp = res?.distinct_library.map((item) => {
                return {
                    _id: item,
                    name: item,
                    label: item,
                    value: item._id,
                };
            });
            setLibraryList([...libraryList, ...temp])
        }
    }

    const fetchClientList = async () => {
        const res = await getAllClient();
        console.log(res, 'resClient');
        if (res) {
            const temp = res?.map((item) => {
                return {
                    _id: item.id,
                    name: item.name,
                    label: item.name,
                    value: item.id,
                };
            });
            setClientList(temp);
        }
    };


    const handleWord = (value, name) => {
        // setPreviewShow(false)

        debugger
        if (name === 'Include Keywords') {
            const objectsArray = [...searchData?.includeKeyword, value]?.map((str, index) => {
                if (str.trim() !== '') {
                    return {
                        "_id": str.trim(),
                        "name": str.trim(),
                        "label": str.trim(),
                        "value": str.trim()
                    }
                }
                return null;
            }).filter(obj => obj !== null);
            debugger
            dispatch(setIncludeKeyword([...searchData?.includeKeyword, value]));
            dispatch(setSelectedIncludeKeyword([...searchData?.includeKeyword, value]));
            dispatch(setSelectedFilters({ data: 'include', value: objectsArray }));
            dispatch(genericFilterHandler());
        }
        if (name === 'Exclude Keywords') {
            const objectsArray = [...searchData?.excludeKeyword, value]?.map((str, index) => {
                if (str.trim() !== '') {
                    return {
                        "_id": str.trim(),
                        "name": str.trim(),
                        "label": str.trim(),
                        "value": str.trim()
                    }
                }
                return null;
            }).filter(obj => obj !== null);
            debugger
            dispatch(setExcludeKeyword([...searchData?.excludeKeyword, value]));
            dispatch(setSelectedExcludeKeyword([...searchData?.excludeKeyword, value]));
            dispatch(setSelectedFilters({ data: 'exclude', value: objectsArray }));
            dispatch(genericFilterHandler());
        }

        if (name === 'filterbytitle') {
            dispatch(setSelectedFilteredTitle(value));
            setTimeout(() => {
                dispatch(genericFilterHandler());
            }, 1000);
        }
    }



    //handle Date
    const handleDate = (date) => {
        dispatch(setSelectedDate(date));
        dispatch(genericFilterHandler());
    }

    //handle remove Date
    const handleRemove = () => {
        dispatch(setSelectedDate(''));
        dispatch(genericFilterHandler());
    }



    // remove selected data 
    const handleRemoveSelected = (arrayType, idToRemove) => {
        if (selectedFilters[arrayType]) {
            const updatedArray = selectedFilters[arrayType].filter(item => item._id !== idToRemove);
            let finalData = { ...selectedFilters, [arrayType]: updatedArray };
            dispatch(setSelectedFilters({ replaceData: finalData }));

            if (arrayType === 'Industry') {
                dispatch(setSelectedIndustry(updatedArray))
            }
            else if (arrayType === 'ContractType') {
                dispatch(setSelectedContractTitle(updatedArray))
            }
            else if (arrayType === 'Department') {
                dispatch(setSelectedDepartment(updatedArray))
            }
            else if (arrayType === 'Clauses') {
                dispatch(setSelectedClause(updatedArray))
            }
            else if (arrayType === 'GoverningLaw') {
                dispatch(setSelectedGoverningLaw(updatedArray))
            }
            else if (arrayType === 'AccessionNumber') {
                dispatch(setSelectedAccessionNumberList(updatedArray))
            }
            else if (arrayType === 'EntityType') {
                dispatch(setSelectedEntityTypeList(updatedArray))
            }
            else if (arrayType === 'Forms') {
                dispatch(setSelectedFormsList(updatedArray))
            }
            else if (arrayType === 'StockExchanges') {
                dispatch(setSelectedStockExchangesList(updatedArray))
            }
            else if (arrayType === 'StateOfIncorporation') {
                dispatch(setSelectedstateOfIncorporationList(updatedArray))
            }
            else if (arrayType === 'ExhibitID') {
                dispatch(setSelectedExhibitList(updatedArray))
            }
            else if (arrayType === 'CIK') {
                dispatch(setSelectedCIKList(updatedArray))
            }
            else if (arrayType === 'Country') {
                dispatch(setSelectedCountry(updatedArray))
            }
            else if (arrayType === 'include') {
                const updatedSelectInclude = searchData?.includeKeyword.filter(item => item !== idToRemove);
                dispatch(setIncludeKeyword(updatedSelectInclude))
                dispatch(setSelectedIncludeKeyword(updatedArray.map(obj => obj.name)))
            }
            else if (arrayType === 'exclude') {
                const updatedSelectExclude = searchData?.includeKeyword.filter(item => item !== idToRemove);
                dispatch(setExcludeKeyword(updatedSelectExclude))
                dispatch(setSelectedExcludeKeyword(updatedArray.map(obj => obj.name)))
            }
            // else if (arrayType === 'FilterByTitle') {
            //     dispatch(setSelectedTitleList(updatedArray))
            // }
            dispatch(genericFilterHandler());
        }
    }

    const handleRemoveAllSelected = () => {
        setPreviewShow(false)
        dispatch(setRemoveAllFilters([]));
        dispatch(genericFilterHandler());
    }



    // handle click on group
    const handleClickedGroup = (item, name) => {
        setActiveTab(name)
        setSearchScreen(true)
        setGroupIds(item.contract_id)
        dispatch(setOpenLibraryType(name))
        dispatch(setSelectedGroupIDs(item.contract_id))
        dispatch(genericFilterHandler('contract_data', item.contract_id))
    }

    // handle remove group
    const hanleRemoveGroup = async (item) => {
        let res = await removeGPBookmark(item.bookmark_type, item.bookmark_name)
        if (res?.data?.message === "Bookmark deleted successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res.data.message || `success`
            });
            dispatch(genericFilterHandler());
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res.message || res.data.message || `something went wrong`
            });
        }
    }

    // handle fullscreen result
    const handleFullResults = (data, type) => {
        setfullResultDataTitle(type)
        setfullResultData(data)
        setSellAllresults(true)
    }




    const handlePrviewClick = () => {
        if (previewShow === false) {
            let previewData = fullLibraryResult.contract[Object.keys(fullLibraryResult?.contract)[0]]
            let newData = { ...previewData, ...fullLibraryResult.results[0] }
            dispatch(setDocData(newData));
            setPreviewShow(!previewShow)
        }
        else {
            setPreviewShow(!previewShow)
        }
    }

    // select contarct
    const onSelectHandler = (item, index) => {
        setPreviewShow(true);
        setSaveModal(false)
        setCustomBookMarkModal(false)
        let previewData = fullLibraryResult?.contract[item?.contract_id]

        let newData = { ...previewData, ...item }

        dispatch(setDocData(newData));

        const _temp = [...fullLibraryResult.results];
        // add selected true to selected item using map
        const temp = _temp?.map((el, i) => {
            if (i === index) {
                return { ...el, selected: true };
            } else {
                return { ...el, selected: false };
            }
        });


        dispatch(setFullLibraryResult({ ...fullLibraryResult, 'results': temp }));
    };


    // handle bookmark
    const handleBookmark = async (e, item) => {
        // 
        const { name, checked } = e.target;
        if (!checked) {
            const res = await removeBookmark(item.id);
            if (res.message === 'Bookmark deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
        else {
            let payload = {
                contract_id: docData?.contract_id,
                library_type: name,
                bookmark_type: activeTab === "contracts" ? "contracts" : activeTab === "clauses" ? "clauses" : "definitions",
                bookmark_name: activeTab === "contracts" ? docData?.contract_title : activeTab === "clauses" ? docData.heading : docData.define_term,
            }
            const res = await createBookmark(payload);
            if (res.message === 'Bookmark Created Successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
    }



    //handle remove single contartc from group
    // const handleRemoveSingleContarct = async (item) => {
    //     const res = await removeBookmark(item?.contract_id);
    //     if (res.message === 'Bookmark deleted successfully.') {
    //         setToastData({
    //             open: true,
    //             status: 'success',
    //             message: res.message || `success`
    //         });
    //         dispatch(genericFilterHandler('contract_data', groupIds))
    //     }
    //     else {
    //         setToastData({
    //             open: true,
    //             status: 'error',
    //             message: res.message || `something went wrong`
    //         });
    //     }
    // }


    // handle back to library navigation
    const handleLibraryNavigation = () => {
        setSellAllresults(false);
        setSearchScreen(false);
        setPreviewShow(false)
        dispatch(setSelectedGroupIDs([]))
        dispatch(genericFilterHandler());
    }

    //handle custom client 
    const handleCustomClient = async () => {
        let payload = {
            client_name: customClientName
        }
        const res = await createCustomClient(payload);
        if (res?.status === 200 && res?.data?.message === "Custom Client Created Successfully.") {
            setToastData({
                open: true,
                status: 'success',
                message: res?.data?.message || `success`
            });
            setCustomCreateClient(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res?.response?.data?.message || `something went wrong`
            });
        }
    }


    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (divRef?.current && !divRef?.current.contains(e.target)) {
            // Clicked outside the div, so remove the class
            setShowSaveDD(false)
        }
    };

    const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);



    const [cikPopup, setCikPopup] = useState(false)
    const [accessionPopup, setAccessionPopup] = useState(false)
    const [formPopup, setFormPopup] = useState(false)



    return (
        <>
            <div className='main-container library'>
                {viewFullViewer ? <ViewFullScreen docFullViewData={docData} handleLibrary={handleLibraryNavigation} setViewFullViewer={setViewFullViewer} activeTab={activeTab} />
                    :
                    <>
                        <div className="search-result-head">
                            <div className='search-main'>
                                <Box className="search-head">
                                    <div className="search-left">
                                        <div className='search-result-head'>
                                            <ul>
                                                <li>
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => handleLibraryNavigation()}>
                                                        library</Link>
                                                </li>
                                                {
                                                    sellAllresults ?
                                                        <li>
                                                            All saved items
                                                        </li>
                                                        : ''
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <ColorButton
                                            size="medium    "
                                            variant="outlined"
                                            endIcon={moreFilter ? <MdArrowDropUp /> : <MdArrowDropDown />}
                                            onClick={() => setMoreFilter(!moreFilter)}
                                        >
                                            {moreFilter ? 'Less Filter' : 'More Filter'}
                                        </ColorButton>
                                    </div>
                                </Box>
                            </div>
                            <div className="filters-main">
                                <div className="filter-row">
                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Contract Type"
                                            ddname='ContractType'
                                            loadOptions={contractTitleList}
                                            setSelected={setSelectedContractTitle}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedContractTitle}
                                        />
                                    </div>

                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Industry"
                                            ddname='Industry'
                                            loadOptions={industryList}
                                            setSelected={setSelectedIndustry}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedIndustry}

                                        />
                                    </div>

                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Department"
                                            ddname='Department'
                                            groupIds={groupIds}
                                            loadOptions={departmentList}
                                            setSelected={setSelectedDepartment}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedDepartment}

                                        />
                                    </div>
                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="clauses"
                                            ddname='clauses'
                                            groupIds={groupIds}
                                            loadOptions={clausesList}
                                            setSelected={setSelectedClause}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedClause}

                                        />
                                    </div>

                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Governing Law"
                                            ddname='GoverningLaw'
                                            loadOptions={governingLawList}
                                            setSelected={setSelectedGoverningLaw}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedGoverningLaw}

                                        />
                                    </div>

                                    <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Country"
                                            ddname='Country'
                                            loadOptions={countryList}
                                            setSelected={setSelectedCountry}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedCountry}

                                        />
                                    </div>

                                    {/* <div className="filter-col">
                                        <LibraryMiuiAsyncSelect
                                            multiple
                                            placeholder="Party/Country Party"
                                            ddname='Country'
                                            loadOptions={[]}
                                            setSelected={setSelectedContractTitle || []}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedContractTitle}
                                            
                                        />
                                    </div> */}

                                    {/* <div className="filter-col">
                                        <TextField
                                            sx={{ width: '151px' }}
                                            className="textfield"
                                            hiddenLabel
                                            id="include-keywords"
                                            placeholder="Include Keywords"
                                            variant="outlined"
                                            size="small"
                                            value={inclueKeyword}
                                            onChange={(e) => handleWord(e.target.value, 'include')}
                                        />
                                    </div>


                                    <div className="filter-col">
                                        <TextField
                                            sx={{ width: '151px' }}
                                            hiddenLabel
                                            className="textfield"
                                            id="exclude-keywords"
                                            placeholder="Exclude Keywords"
                                            variant="outlined"
                                            size="small"
                                            value={excludeKeyword}
                                            onChange={(e) => handleWord(e.target.value, 'exclude')}
                                        />
                                    </div> */}


                                    <div className="filter-col">
                                        <ChipInput
                                            id="includekeyword"
                                            variant="outlined"
                                            color='primary'
                                            size="small"
                                            className='taginput'
                                            hiddenLabel
                                            alwaysShowPlaceholder={true}
                                            dataSource={searchData?.includeKeyword}
                                            value={searchData?.includeKeyword}
                                            placeholder={'Include Keywords'}
                                            onAdd={(chip) => handleWord(chip, 'Include Keywords')}
                                        />
                                    </div>

                                    <div className="filter-col">
                                        <ChipInput
                                            id="excludekeyword"
                                            variant="outlined"
                                            color='primary'
                                            size="small"
                                            className='taginput'
                                            hiddenLabel
                                            alwaysShowPlaceholder={true}
                                            dataSource={searchData?.excludeKeyword}
                                            value={searchData?.excludeKeyword}
                                            placeholder={'Exclude Keywords'}
                                            onAdd={(chip) => handleWord(chip, 'Exclude Keywords')}
                                        />
                                    </div>


                                    <div className="filter-col">
                                        <DateRangePicker
                                            cleanable
                                            showOneCalendar
                                            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                                            defaultValue={searchData?.selectedDate?.length ? [new Date(searchData.selectedDate[0]), new Date(searchData.selectedDate[1])] : []}
                                            onOk={(date) => handleDate(date)}
                                            onClean={(date) => handleRemove(date)}
                                        />
                                    </div>

                                    <div className="filter-col">
                                        <TextField
                                            sx={{ width: '151px' }}
                                            hiddenLabel
                                            className="textfield"
                                            id="exclude-keywords"
                                            placeholder="Filter By Title"
                                            variant="outlined"
                                            size="small"
                                            value={flteredTitle}
                                            onChange={(e) => handleWord(e.target.value, 'filterbytitle')}
                                        />
                                    </div>
                                    {console.log("setSelectedLibraryList", searchData?.selectedLibraryList)}
                                    <div className="filter-col singleselect">
                                        <LibraryMiuiSingleSelect
                                            placeholder="Library Type"
                                            ddname='LibraryType'
                                            loadOptions={libraryList}
                                            setSelected={setSelectedLibraryList}
                                            genericFilterHandler={genericFilterHandler}
                                            value={searchData?.selectedLibraryList}
                                            searchScreen={searchScreen}
                                            url={window.location.pathname.split('/')[2]}
                                        />
                                    </div>


                                    {moreFilter &&
                                        <>
                                            <div className="filter-col" onClick={() => setCikPopup(true)}>
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    readOnly
                                                    placeholder="CIK"
                                                    ddname='CIK'
                                                    loadOptions={cikList}
                                                    setSelected={setSelectedCIKList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedCIKList}
                                                />
                                            </div>

                                            <div className="filter-col" onClick={() => setAccessionPopup(true)}>
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    readOnly
                                                    placeholder="Accession Number"
                                                    ddname='AccessionNumber'
                                                    loadOptions={accessionNumberList}
                                                    setSelected={setSelectedAccessionNumberList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedAccessionNumberList}

                                                />
                                            </div>

                                            <div className="filter-col">
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    placeholder="Entity Type"
                                                    ddname='EntityType'
                                                    loadOptions={entityTypeList}
                                                    setSelected={setSelectedEntityTypeList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedEntityTypeList}

                                                />
                                            </div>

                                            <div className="filter-col" onClick={() => setFormPopup(true)}>
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    readOnly
                                                    placeholder="Form"
                                                    ddname='Forms'
                                                    loadOptions={formsList}
                                                    setSelected={setSelectedFormsList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedFormsList}

                                                />
                                            </div>
                                            <div className="filter-col">
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    placeholder="Stock Exchange"
                                                    ddname='StockExchanges'
                                                    loadOptions={stockExchangesList}
                                                    setSelected={setSelectedStockExchangesList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedStockExchangesList}
                                                />
                                            </div>

                                            <div className="filter-col">
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    placeholder="Exhibit ID"
                                                    ddname='ExhibitID'
                                                    loadOptions={exhibitIDList}
                                                    setSelected={setSelectedExhibitList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedExhibitIDList}
                                                />
                                            </div>

                                            <div className="filter-col">
                                                <LibraryMiuiAsyncSelect
                                                    multiple
                                                    placeholder="State of Incorporation"
                                                    ddname='StateOfIncorporation'
                                                    loadOptions={stateOfIncorporationList}
                                                    setSelected={setSelectedstateOfIncorporationList}
                                                    genericFilterHandler={genericFilterHandler}
                                                    value={searchData?.selectedStateOfIncorporationList}
                                                />
                                            </div>
                                        </>
                                    }


                                </div>
                                <div className="sort-by">
                                    <div className="filter-row">
                                        <div className="filter-col">
                                            <SelectPicker
                                                data={data}
                                                cleanable={false}
                                                defaultValue='relevance'
                                                placeholder="Sort by"
                                                searchable={false}
                                                style={{ width: 224 }}
                                                onChange={(data) => {
                                                    console.log(data, "data");
                                                    dispatch(genericFilterHandler('', '', null, 0, data));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="selected-filter">
                                {console.log("selectedFilters", selectedFilters)}

                                <ul>
                                    {Object.keys(selectedFilters).map((item, i) => {
                                        return (
                                            selectedFilters[item].length ?
                                                <li key={i}>
                                                    <h6>{item}: </h6>
                                                    <ul>
                                                        {selectedFilters[item].map((selected, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <div className="selected">
                                                                        <span>{selected.name}</span>
                                                                        <MdClose
                                                                            onClick={() => handleRemoveSelected(item, selected._id)}
                                                                        />
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                                : ""
                                        )
                                    })}
                                </ul>
                                {Object.values(selectedFilters).some(array => array.length > 0) ? <Button onClick={() => handleRemoveAllSelected()}> Clear All </Button> : ''}
                            </div>
                            <div>
                                {clientList?.length === 0 ?
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        className="navbar_wrp_right"
                                        fullWidth="true"
                                        justifyContent={"flex-end"}
                                        sx={{ mb: 2 }}
                                    >
                                        <Button variant="contained" startIcon={<AddIcon />}
                                            onClick={() => setCustomCreateClient(true)}
                                            sx={{
                                                width: "174px",
                                                backgroundColor: "#005287", '&:hover': {
                                                    backgroundColor: '#005287',
                                                },
                                            }}
                                        >
                                            Add Custom Client
                                        </Button>
                                        <Button
                                            onClick={() => setCCmodal(true)}
                                            variant="outlined" startIcon={<RiDeleteBinLine fill='#868686' />}
                                            sx={{ width: "199px", backgroundColor: "#fff", borderColor: "#868686", color: "#868686" }}
                                        >
                                            Remove Custom Client
                                        </Button>
                                    </Stack>
                                    :
                                    ""
                                }
                            </div>

                            {searchScreen &&
                                <div className="head-right">
                                    <ul>
                                        <li>
                                            <span className={`preview ${previewShow ? 'active' : ''}`} onClick={() => handlePrviewClick()} >
                                                <MdRemoveRedEye />
                                                Preview
                                            </span>
                                        </li>

                                    </ul>
                                </div>
                            }
                        </div>

                        {console.log("libraryResult", libraryResult)}

                        {!searchScreen ?
                            <>
                                {sellAllresults ?
                                    <Card>
                                        <CardContent>
                                            <div className='library_list'>
                                                <div className='library_list_header'>
                                                    <h6> Saved {fullResultDataTitle} </h6>
                                                </div>
                                                <div className='library_list_content'>
                                                    <ul>
                                                        {fullResultData.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <li>
                                                                        <div className="selected">
                                                                            <span onClick={() => handleClickedGroup(item)}>{item.bookmark_name.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())} </span>
                                                                            <p>({item.count})</p>
                                                                            <MdClose
                                                                                fill='#C6C6C6'
                                                                                size={24}
                                                                                onClick={() => hanleRemoveGroup(item)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>

                                    :

                                    <Card>
                                        <CardContent >
                                            <div className='library_list'>
                                                <div className='library_list_header'>
                                                    <h6> Contracts </h6>
                                                    <span className={libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.length < 15 ? 'disable' : ''}
                                                        onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "contracts"), 'Contracts')}
                                                    > See all saved items </span>
                                                </div>
                                                <div className='library_list_content'>
                                                    {isLoading ?
                                                        <div style={{ height: "100%", position: "relative" }} >
                                                            <Loader center content="Loading Data..." />
                                                        </div>
                                                        :
                                                        <ul>
                                                            {libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.length ?

                                                                libraryResult?.filter((_) => _?.bookmark_type === "contracts")?.slice(0, 15)?.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <div className="selected">
                                                                                    <span onClick={() => handleClickedGroup(item, 'contracts')}>{item.bookmark_name.toLowerCase().replace(/(^|\s)\S/g, (match) => match.toUpperCase())} </span>
                                                                                    <p>({item.count})</p>
                                                                                    <MdClose
                                                                                        fill='#C6C6C6'
                                                                                        size={24}
                                                                                        onClick={() => hanleRemoveGroup(item)}
                                                                                    />
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                <li>
                                                                    <div className="selected">
                                                                        No Data Found
                                                                    </div>
                                                                </li>
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>

                                            <div className='library_list'>
                                                <div className='library_list_header'>
                                                    <h6> Clauses </h6>
                                                    <span className={libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.length < 15 ? 'disable' : ''}
                                                        onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "clauses"), 'Clauses')}
                                                    > See all saved items </span>
                                                </div>
                                                <div className='library_list_content'>
                                                    {isLoading ?
                                                        <div style={{ height: "100%", position: "relative" }} >
                                                            <Loader center content="Loading Data..." />
                                                        </div>
                                                        :
                                                        <ul>
                                                            {libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.length ?
                                                                libraryResult?.filter((_) => _?.bookmark_type === "clauses")?.slice(0, 15)?.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <div className="selected">
                                                                                    <span onClick={() => handleClickedGroup(item, 'clauses')}>{item.bookmark_name} </span>
                                                                                    <p>({item.count})</p>
                                                                                    <MdClose
                                                                                        fill='#C6C6C6'
                                                                                        size={24}
                                                                                        onClick={() => hanleRemoveGroup(item)}
                                                                                    />
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })

                                                                :
                                                                <li>
                                                                    <div className="selected">
                                                                        No Data Found
                                                                    </div>
                                                                </li>
                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>

                                            <div className='library_list'>
                                                <div className='library_list_header'>
                                                    <h6> Definitions </h6>
                                                    <span className={libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.length < 15 ? 'disable' : ''}
                                                        onClick={() => handleFullResults(libraryResult?.filter((_) => _?.bookmark_type === "definitions"), 'Definitions')}
                                                    >
                                                        See all saved items
                                                    </span>
                                                </div>
                                                <div className='library_list_content'>
                                                    {isLoading ?
                                                        <div style={{ height: "100%", position: "relative" }} >
                                                            <Loader center content="Loading Data..." />
                                                        </div>
                                                        :
                                                        <ul>
                                                            {libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.length ?
                                                                libraryResult?.filter((_) => _?.bookmark_type === "definitions")?.slice(0, 15)?.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <div className="selected">
                                                                                    <span onClick={() => handleClickedGroup(item, 'definitions')}> {item.bookmark_name} </span>
                                                                                    <p>({item.count})</p>
                                                                                    <MdClose
                                                                                        fill='#C6C6C6'
                                                                                        size={24}
                                                                                        onClick={() => hanleRemoveGroup(item)}
                                                                                    />
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                <li>
                                                                    <div className="selected">
                                                                        No Data Found
                                                                    </div>
                                                                </li>

                                                            }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                }
                            </>
                            :
                            <Box className="search-result-body">
                                <div className={`left ${previewShow ? "preview-open" : ""}`}>
                                    <ul style={{ height: "100%" }}>
                                        {isLoading ?
                                            <div style={{ height: "100%", position: "relative" }} >
                                                <Loader center content="Loading Data..." />
                                            </div>
                                            :
                                            fullLibraryResult?.results?.length ?
                                                fullLibraryResult?.results?.map((item, i) => (
                                                    <li className={item?.selected && previewShow ? "selected" : ""} >
                                                        {/* <span className='remove'>
                                                            <MdDelete
                                                                fontSize={20}
                                                                fill='#505050'
                                                                onClick={() => handleRemoveSingleContarct(item)}
                                                            />
                                                        </span> */}
                                                        <div onClick={() => onSelectHandler(item, i)}>

                                                            <div className="title">
                                                                <span className="number">{i + 1}. </span>
                                                                <h5>{item?.contract_title}</h5>
                                                            </div>
                                                            <div className="doc_content">
                                                                <div className="tags-main">
                                                                    <ul>
                                                                        {item?.contract_title &&
                                                                            <li>
                                                                                <p>
                                                                                    <b>Contract Type: </b> {item?.contract_title}
                                                                                </p>
                                                                            </li>
                                                                        }
                                                                        {item?.industry &&
                                                                            <li>
                                                                                <p>
                                                                                    <b>Industry: </b> {item?.industry || 'NA'}
                                                                                </p>
                                                                            </li>
                                                                        }
                                                                        {item?.department &&
                                                                            <li>
                                                                                <p>
                                                                                    <b>Department: </b>{" "}
                                                                                    {item?.department?.map((d) => d.department_type)
                                                                                        .join(", ")}
                                                                                </p>
                                                                            </li>
                                                                        }

                                                                        {item?.governing_law &&
                                                                            <li>
                                                                                <p>
                                                                                    <b>Governing Law: </b>{" "}
                                                                                    {item?.governing_law || "NA"}
                                                                                </p>
                                                                            </li>
                                                                        }

                                                                        {item?.filing_date &&
                                                                            <li>
                                                                                <p>
                                                                                    <b>Filing Date: </b>{" "}
                                                                                    {new Date(item?.filing_date).toISOString().split("T")[0]}
                                                                                </p>
                                                                            </li>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className="description">
                                                                    {item?.description?.map((desc) => (
                                                                        <>
                                                                            <p>
                                                                                <div dangerouslySetInnerHTML={{ __html: desc }} ></div>
                                                                            </p>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </li>
                                                ))
                                                :
                                                <li>

                                                    <h3 style={{ textAlign: "center" }}> No Result Found </h3>
                                                </li>
                                        }
                                    </ul>
                                </div>

                                {previewShow && (
                                    <div
                                        className="right"
                                        ref={rootRef}
                                    >
                                        <div>
                                            <Stack style={{ padding: "0 10px" }} justifyContent="space-between" direction={"row"}>
                                                <span className="custombtn" onClick={() => setViewFullViewer(!viewFullViewer)}  >
                                                    <CgScreenShot fontSize={24} style={{ transform: "rotate(90deg)", marginRight: "5px" }} />View Full Contract
                                                </span>
                                                <Stack className="save_dropdwon" alignItems="center" >
                                                    <span onClick={() => {
                                                        if (localStorage.getItem('token')) {
                                                            setShowSaveDD(!showSaveDD)
                                                        }
                                                        else {
                                                            setToastData({
                                                                open: true,
                                                                status: 'warning',
                                                                message: `Please Login First to save contract`
                                                            });
                                                        }
                                                    }}>
                                                        Save  <MdOutlineBookmarkAdd fontSize={24} style={{ marginLeft: "5px" }} />
                                                    </span>
                                                    <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? 'active' : ''}`}>
                                                        <ul>
                                                            <li>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="my_library"
                                                                                onChange={(e) => handleBookmark(e, bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                                                                checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library'}
                                                                            />
                                                                        }
                                                                        label="My Library"
                                                                        labelPlacement="start"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                name="team_library"
                                                                                onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                                                                checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'team_library'}
                                                                            />
                                                                        }
                                                                        label="Teams Library"
                                                                        labelPlacement="start"
                                                                    />
                                                                </FormGroup>
                                                            </li>
                                                            <li onClick={() => setSaveModal(true)}>
                                                                <span> Client Library <AiFillCaretRight /></span>
                                                            </li>
                                                            <li onClick={() => setCustomBookMarkModal(true)}>
                                                                <span> Custom Library <AiFillCaretRight /></span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Stack>
                                            </Stack>
                                            {/* <h5 className="right_contracttitle"> {docData?.contract_title}</h5> */}
                                        </div>
                                        {console.log("docData", docData)}
                                        <div style={{ padding: "10px", overflow: "auto" }}>
                                            {docData?.raw_text ?
                                                regex.test(docData?.raw_text) ?
                                                    <div dangerouslySetInnerHTML={{ __html: docData?.raw_text }}></div>
                                                    :
                                                    <pre>
                                                        <div>{docData?.raw_text}</div>
                                                    </pre>
                                                :
                                                regex.test(docData?.raw_text) ?
                                                    <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                                                    :
                                                    <pre>
                                                        <div>{docData?.formatted_text}</div>
                                                    </pre>
                                            }
                                        </div>

                                        <SaveBM
                                            docData={docData}
                                            bookmarkList={bookmarkList}
                                            activeTab={activeTab}
                                            fetchBookmarkList={fetchBookmarkList}
                                            rootRef={() => rootRef?.current}
                                            saveModal={saveModal}
                                            setSaveModal={setSaveModal}
                                            customBookMarkModal={customBookMarkModal}
                                            setCustomBookMarkModal={setCustomBookMarkModal}
                                            library={'library'}
                                        />
                                    </div>
                                )}
                            </Box>
                        }
                    </>


                }
            </div >



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, textAlign: "center" }}>
                    <Stack direction={'row'} justifyContent={'center'} gap={2} alignItems={"center"} mb={1}>
                        <WarningIcon color="info" />  <h4 > Alert </h4>
                    </Stack>

                    <p style={{ marginBottom: "25px !important" }}>
                        Please Login First To See Library
                    </p>

                    <Stack direction={'row'} justifyContent={'center'} gap={2} mt={3}>
                        <CustomButton variant="contained" href="/" text="Home" />
                        <CustomButton variant="contained" href="/login" text="Login" />
                    </Stack>
                </Box>
            </Modal>


            <ModalComponent
                width="calc(100% - 30px)"
                maxWidth="575px"
                open={customCreateClient}
                handleClose={() => setCustomCreateClient(false)}
                className="custom_client"
                padding={2}
            >
                <>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} >
                        <h4 style={{ fontSize: "24px" }} >Add Custom Client</h4>
                        <IconButton className="close_modal" color="primary" onClick={() => setCustomCreateClient(false)} component="label">
                            <CloseIcon fill="#1C1B1F" />
                        </IconButton>
                    </Stack>


                    <Stack mb={5} mt={2}>
                        <InputLabel mb={1}> Custom Client Name </InputLabel>
                        <TextField id="outlined-basic"
                            variant="outlined"
                            hiddenLabel
                            size='small'
                            onChange={(e) => setCustomClientName(e.target.value)}
                        />
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                        <Button color="primary" onClick={() => setCustomCreateClient(false)}
                            sx={{
                                color: "#909090"
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained"
                            onClick={() => handleCustomClient()}
                            sx={{
                                backgroundColor: "#005287",
                                '&:hover': {
                                    backgroundColor: '#005287',
                                },
                            }}
                        > Add </Button>
                    </Stack>
                </>
            </ModalComponent>

            {CCmodal && <RemoveClient open={CCmodal} onClose={handleCloseCCModal} />}


            {cikPopup &&
                <MiuiSelectPopup
                    open={cikPopup}
                    close={() => setCikPopup(false)}
                    placeholder="CIK"
                    ddname='CIK'
                    loadOptions={cikList}
                    setSelected={setSelectedCIKList}
                    genericFilterHandler={genericFilterHandler}
                    value={searchData?.selectedCIKList}
                />
            }

            {accessionPopup &&
                <MiuiSelectPopup
                    open={accessionPopup}
                    close={() => setAccessionPopup(false)}
                    placeholder="Accession Number"
                    ddname='AccessionNumber'
                    loadOptions={accessionNumberList}
                    setSelected={setSelectedAccessionNumberList}
                    genericFilterHandler={genericFilterHandler}
                    value={searchData?.selectedAccessionNumberList}
                />
            }

            {formPopup &&
                <MiuiSelectPopup
                    open={formPopup}
                    close={() => setFormPopup(false)}
                    placeholder="Form"
                    ddname='Forms'
                    loadOptions={formsList}
                    setSelected={setSelectedFormsList}
                    genericFilterHandler={genericFilterHandler}
                    value={searchData?.selectedFormsList}
                />
            }


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default Library