import { Box, Heading, Image, Stack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import SEO from "../../component/SEO";
import { getAllBlog, getAllPage, getPageById } from "../../service/CMSService";
import SEO from "../../component/SEO";
import Footer from "../../component/Footer";
// import SEO from "../../component/SEO";

const StaticPage = () => {
  const { url } = useParams();
  const [pageContentData, setPageContentData] = useState({});
  const getPageData = useCallback(async () => {
    const res = await getPageById(url);
    if (res) {
      setPageContentData(res.data.data);
    }
  }, [url]);
  useEffect(() => {
    if (url) {
      getPageData();
    }
  }, [getPageData, url]);

  const [pages, setPages] = useState([]);

  const getAllPageHanlder = async () => {
    const res = await getAllPage();
    setPages(res.data.data || []);
  };

  useEffect(() => {
    getAllPageHanlder();
  }, []);





  return (
    <>
      <SEO
        title={pageContentData?.title || ''}
        description={pageContentData?.metaDiscription || ''}
        // url={`/forum/${pageContentData?.seoURL}`}
        url={window.location.href}
        ogTag={pageContentData?.ogTag || ''}
        metaKeywords={pageContentData.metaKeywords || ''}
      />

      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading className="animate__animated animate__fadeInDown animate__faster">
            {pageContentData?.title}
          </Heading>
        </Box>
      </Box>

      <div className="container ql-snow">
        <Box
          boxShadow="none"
          p={["20px 0", "20px 0", "20px 0", "56px 0"]}
          background="#fff"
        >
          {/* <Heading fontSize="20px" textAlign="center" mb={5}>
            {pageContentData?.title}
          </Heading> */}
          {!pageContentData?.webPageContent?.length ? (
            <div
              className=" ql-editor"
              style={{ padding: "0" }}
              dangerouslySetInnerHTML={{ __html: pageContentData?.pageContent }}
            />
          ) : (
            pageContentData?.webPageContent?.map((item, i) => {
              return (
                <Stack
                  direction={"row"}
                  wrap={"wrap"}
                  alignItems={"center"}
                  flexDirection={`${i % 2 === 0 ? "row-reverse" : ""}`}
                  spacing={5}
                  mb={14}
                  key={i}
                >
                  <Box width={["100%", "100%", "100%", "41%"]}>
                    <Image
                      height={"auto"}
                      width={"100%"}
                      src={item?.image}
                      objectFit={"contain"}
                    />
                  </Box>
                  <Box width={["100%", "100%", "100%", "57%"]}>
                    <Heading>{item?.imageName}</Heading>
                    <div
                      style={{ padding: "15px" }}
                      dangerouslySetInnerHTML={{
                        __html: item?.webPageContent,
                      }}
                    />
                  </Box>
                </Stack>
              );
            })
          )}
        </Box>
      </div>

      <Footer pages={pages} />
    </>

  );
};

export default StaticPage;
