/* eslint-disable arrow-body-style */

import logo from '../assets/images/dealbase_logo.png';
import logo2 from '../assets/images/lexops_logo.png';


// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    let pathname = ['/login', '/register-signup', '/register', '/ChangePassword', '/forgetPassword', '/resetPassword', '/passwordSuccess']
    return (
        <>
            {
                pathname.includes(window.location.pathname) ?
                    <a href={localStorage.getItem('token') ? '/' : '/'} styles={{ display: "inline-block" }}>
                        <img src={logo2} alt="Kronicle" style={{ width: "200px" }} />
                    </a>
                    :
                    <a href={localStorage.getItem('token') ? '/' : '/'} style={{ display: "inline-block" }}>
                        <img src={logo} alt="Kronicle" style={{ width: "90px" }} />
                    </a>
            }
        </>
    );
};

export default Logo;
