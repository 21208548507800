import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import SEO from "../../component/SEO";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <>
      <SEO
        title={"Thank You" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box className="container">
        <Box className="thankyou-box">
          <Heading>Thank You</Heading>
          <h3>for contacting us, we will get in touch with you soon...</h3>
          <button onClick={() => navigate("/")} className="btn btn-blue">
            Back to Home
          </button>
        </Box>
      </Box>
    </>
  );
};

export default ThankYou;
