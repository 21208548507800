import React, { useEffect, useState } from 'react'
import ModalComponent from '../../component/Modal'
import { makeStyles, styled } from '@mui/styles';
import Toast from '../../component/Toast';
import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableRow, tableCellClasses } from '@mui/material';
import SearchNotFound from '../SearchResult/SearchNotFound';
import UserModalToolbar from '../SearchResult/UserModalToolbar';
import { RiDeleteBinLine } from 'react-icons/ri';
import UserListHead from '../SearchResult/UserListHead';
import { deleteCustomClient, getCustomClient } from '../../service/Library';
const useStyles = makeStyles({
    root: {
        position: 'absolute',
    },
    backdrop: {
        position: 'absolute',
    },
});


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // return array.filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        return array?.filter((_) => _.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
    { id: '', label: 'Custom Client', alignRight: false },
    { id: '', label: 'Contracts', alignRight: false },
    { id: '', label: 'Clauses', alignRight: false },
    { id: '', label: 'Definitions', alignRight: false },
    { id: '', label: 'Date Created', alignRight: false },
    { id: '', label: '', alignRight: false },
];



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



function RemoveClient({ open, onClose }) {
    const classes = useStyles();
    const [clientList, setClientList] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [updateId, setupdateId] = useState('')


    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const filteredClient = applySortFilter(clientList, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredClient.length === 0;

    useEffect(() => {
        clientsHandler();
    }, [])


    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const clientsHandler = async () => {
        const res = await getCustomClient();
        console.log(res.data.data, 'resClient');
        if (res) {
            setClientList(res.data.data);
        }
    };


    const removeCustomClient = async (id) => {
        let res = await deleteCustomClient(id)
        if (res.status === 200) {
            setToastData({
                open: true,
                status: 'success',
                message: res.data.message || `Custom Client deleted successfully.`
            });
            clientsHandler();
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res.data.error || `something wrong happing.`
            });
        }
    }

    return (
        <>
            <ModalComponent
                width="calc(100% - 30px)"
                maxWidth="867px"
                height="346px"
                open={open}
                handleClose={onClose}
                closebutton
            >
                <div>
                    <Card>
                        <Stack>
                            <h4>Remove Custom Client </h4>
                        </Stack>
                        <Stack sx={{ maxWidth: "519px", marginTop: "10px" }}>
                            <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        </Stack>
                        <TableContainer sx={{ height: "400px", overflow: 'auto' }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                />
                                <TableBody >
                                    {filteredClient?.map((row) => {
                                        const { client_name, id } = row;
                                        return (
                                            <StyledTableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                            >
                                                <StyledTableCell align="left">{client_name || '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{client_name || '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{client_name || '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{client_name || '-'}</StyledTableCell>
                                                <StyledTableCell align="left">{client_name || '-'}</StyledTableCell>
                                                <StyledTableCell padding="checkbox" >
                                                    <span style={{ cursor: "pointer" }}>
                                                        <RiDeleteBinLine
                                                            fontSize={20}
                                                            fill='red'
                                                            onClick={() => removeCustomClient(id)}
                                                        />
                                                    </span>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Card>
                </div >
            </ModalComponent>


            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default RemoveClient