/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Button, Card, Checkbox, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import UserModalToolbar from './UserModalToolbar';
import SearchNotFound from './SearchNotFound';
import { getAllCustomBMList } from '../../service/BookmarkService';


const TABLE_HEAD = [
    { id: '', label: '', alignRight: false },
];



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // return array.filter(array, (_user) => _user?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        return array.filter((_) => _?.toLowerCase().indexOf(query?.toLowerCase()) !== -1)
    }
    return stabilizedThis.map((el) => el[0]);
}


function CustomBookMark({ docData, bookmarkList, handleClose, handleSave, handleCustomBM }) {
    const [customBMlist, setCustomBMlist] = useState([])
    const [order, setOrder] = React.useState('asc');
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('first_name');
    const [filterName, setFilterName] = React.useState('');
    const [bookmarkName, setBookmarkName] = useState('')
    const [create, setCreate] = useState(false)
    const [clData, setClData] = useState([])

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };


    const filteredBMlist = applySortFilter(customBMlist, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredBMlist?.length === 0;


    useEffect(() => {
        fetchCustomBookmarkList()
        // checkSelectedList()
    }, [])

    const fetchCustomBookmarkList = async () => {
        const res = await getAllCustomBMList();
        
        if (res) {
            setCustomBMlist(res?.distinct_library)
            setClData(res?.library_name)
            checkSelected(res?.library_name, docData?.contract_id)
        }
    }

    const checkSelected = (listData, id) => {
        let data = listData?.filter((_) => _?.contract_id === id)[0]?.library_type
        if (data) {
            const typesArray = data?.map(item => item?.type);
            setSelected(typesArray)
        }
    }


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };


    useEffect(() => {
        fetchCustomBookmarkList()
    }, [bookmarkList])

    return (
        <div>
            <Stack alignItems={'end'} mb={2}>
                <Button variant='contained' sx={{ width: "145px" }} onClick={() => setCreate(!create)}>{!create ? 'Create Bookmark' : 'Bookmark List'} </Button>
            </Stack>

            {create ?
                <>
                    <Stack mb={5} mt={2}>
                        <TextField id="outlined-basic" label="BookMark Name" variant="outlined" onChange={(e) => setBookmarkName(e.target.value)} />
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                        <Button color="primary" onClick={handleClose}> Cancel </Button>
                        <Button variant="contained" onClick={() => handleCustomBM(bookmarkName)}> Create & Save </Button>
                    </Stack>
                </>
                :
                <>
                    <Card>
                        {console.log("filteredBMlist", filteredBMlist)}
                        <UserModalToolbar filterName={filterName} onFilterName={handleFilterByName} />
                        <TableContainer sx={{ height: "250px", overflow: 'auto', mt: "10px", mb: "10px" }}>
                            <Table>
                                < TableBody >
                                    {filteredBMlist?.map((row) => {
                                        // const { item } = row;
                                        const isItemSelected = selected?.indexOf(row) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                // key={id}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}

                                            >
                                                <TableCell align="left">{row || '-'}</TableCell>
                                                <TableCell padding="checkbox" >
                                                    <Checkbox checked={isItemSelected}
                                                        onClick={(event) => {
                                                            handleSave(
                                                                isItemSelected,
                                                                row,
                                                                clData?.filter((_) => _.contract_id === docData?.contract_id)[0]?.library_type?.filter((l) => l.type === row)[0]
                                                            )
                                                            handleClick(event, row)
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Card>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={3}>
                        <Button color="primary" onClick={handleClose}> Cancel </Button>
                        {/* <Button variant="contained" onClick={() => handleSave(selected)}> Save </Button> */}
                    </Stack>
                </>
            }
        </div >
    )
}

export default CustomBookMark