import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAccession,
  fetchCIK,
  fetchClauses,
  fetchCompany,
  fetchContractTitle,
  fetchCountry,
  fetchDepartment,
  fetchEntityType,
  fetchExhibitID,
  fetchForms,
  fetchGoverlingLaw,
  fetchIndustry,
  fetchSearchData,
  fetchStateOfIncorporation,
  fetchStockExchanges,
  getSearchCount,
} from "../service/Global";
import moment from "moment";

const initialState = {
  ActiveTab: [],
  searchValue: "",
  searchResult: [],
  searchResultCount: 10,
  searchPageCount: 0,
  contractCount: 0,
  clauseCount: 0,
  definitionCount: 0,
  searchItemList: [],
  isLoading: false,
  companyList: { data: [], total_count: '' },
  industryList: { data: [], total_count: '' },
  departmentList: { data: [], total_count: '' },
  governingLawList: { data: [], total_count: '' },
  countryList: { data: [], total_count: '' },
  contractTitleList: { data: [], total_count: '' },
  accessionNumberList: { data: [], total_count: '' },
  entityTypeList: { data: [], total_count: '' },
  formsList: { data: [], total_count: '' },
  stockExchangesList: { data: [], total_count: '' },
  stateOfIncorporationList: { data: [], total_count: '' },
  exhibitIDList: { data: [], total_count: '' },
  cikList: { data: [], total_count: '' },
  clausesList: { data: [], total_count: '' },
  selectedDate: [],
  selectedCompany: [],
  selectedIndustry: [],
  selectedDepartment: [],
  selectedGoverningLaw: [],
  selectedCountry: [],
  selectedContractTitle: [],
  selectedAccessionNumberList: [],
  selectedEntityTypeList: [],
  selectedFormsList: [],
  selectedStockExchangesList: [],
  selectedExhibitIDList: [],
  selectedStateOfIncorporationList: [],
  selectedCIKList: [],
  selectedClause: [],
  selectedFilters: [],
  docUrl: { uri: "" },
  docData: {},
  includeKeyword: [],
  selectedincludeKeyword: [],
  excludeKeyword: [],
  selectedexcludeKeyword: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedActiveTab: (state, action) => {
      state.ActiveTab = action.payload;
    },
    setSearchItem: (state, action) => {
      state.searchItemList = action.payload;
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    setSearchResultCount: (state, action) => {
      state.searchResultCount = action.payload;
    },
    setContractCount: (state, action) => {
      state.contractCount = action.payload;
    },
    setClauseCount: (state, action) => {
      state.clauseCount = action.payload;
    },
    setDefinitionCount: (state, action) => {
      state.definitionCount = action.payload;
    },
    setSearchPageCount: (state, action) => {
      state.searchPageCount = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCompanyList: (state, action) => {
      state.companyList = action.payload;
    },
    setIndustryList: (state, action) => {
      state.industryList = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
    setGoverningLawList: (state, action) => {
      state.governingLawList = action.payload;
    },

    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },

    setContractTitleList: (state, action) => {
      state.contractTitleList = action.payload;
    },

    setClausesList: (state, action) => {
      state.clausesList = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedIndustry: (state, action) => {
      state.selectedIndustry = action.payload;
    },
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
    },
    setSelectedGoverningLaw: (state, action) => {
      state.selectedGoverningLaw = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSelectedContractTitle: (state, action) => {
      state.selectedContractTitle = action.payload;
    },
    setSelectedClause: (state, action) => {
      state.selectedClause = action.payload;
    },
    setDocUrl: (state, action) => {
      state.docUrl = action.payload;
    },
    setDocData: (state, action) => {
      state.docData = action.payload;
    },
    setSelectedFilters: (state, action) => {
      const newObject = action.payload;
      if (newObject.replaceData) {
        state.selectedFilters = newObject.replaceData
      }
      else {
        // Get the first key from newData
        const newDataKey = newObject?.data;

        const isKeyFound = newDataKey in state.selectedFilters;
        // If the key is found, update the existing array; otherwise, add a new key-value pair
        const updatedArray = {
          ...state.selectedFilters,
          [newDataKey]: isKeyFound ? newObject.value : [...(state.selectedFilters[newDataKey] || []), ...newObject.value],
        };
        state.selectedFilters = updatedArray;
      }
    },
    setRemoveFilters: (state, action) => {
      const newObject = action.payload;
      // const existingIndex = state?.selectedFilters?.findIndex(item => item?.type === newObject?.type);
      const existingIndex = state?.selectedFilters.findIndex(item => item.type === newObject.type);

      if (existingIndex !== -1) {
        // If an object with the same data exists, remove it
        state.selectedFilters = state?.selectedFilters.filter((_, index) => index !== existingIndex);
      }
    },
    setRemoveAllFilters: (state, action) => {
      state.selectedFilters = [];
      state.selectedDate = [];
      state.selectedCompany = [];
      state.selectedIndustry = [];
      state.selectedDepartment = [];
      state.selectedGoverningLaw = [];
      state.selectedCountry = [];
      state.selectedContractTitle = [];
      state.selectedAccessionNumberList = [];
      state.selectedEntityTypeList = [];
      state.selectedFormsList = [];
      state.selectedStockExchangesList = [];
      state.selectedExhibitIDList = [];
      state.selectedStateOfIncorporationList = [];
      state.selectedCIKList = [];
      state.selectedClause = [];
      state.selectedFilters = [];
      state.selectedincludeKeyword = [];
      state.selectedexcludeKeyword = [];
      state.includeKeyword = [];
      state.excludeKeyword = [];
    },
    setAccessionNumberList: (state, action) => {
      state.accessionNumberList = action.payload;
    },
    setSelectedAccessionNumberList: (state, action) => {
      state.selectedAccessionNumberList = action.payload;
    },
    setEntityTypeList: (state, action) => {
      state.entityTypeList = action.payload;
    },
    setSelectedEntityTypeList: (state, action) => {
      state.selectedEntityTypeList = action.payload;
    },
    setFormsList: (state, action) => {
      state.formsList = action.payload;
    },
    setSelectedFormsList: (state, action) => {
      state.selectedFormsList = action.payload;
    },
    setStockExchangesList: (state, action) => {
      state.stockExchangesList = action.payload;
    },
    setSelectedStockExchangesList: (state, action) => {
      state.selectedStockExchangesList = action.payload;
    },
    setexhibitsList: (state, action) => {
      state.exhibitIDList = action.payload;
    },
    setSelectedExhibitList: (state, action) => {
      state.selectedExhibitIDList = action.payload;
    },
    setCIKList: (state, action) => {
      console.log("set redux =>>>>>", action.payload)
      state.cikList = action.payload;
    },
    setSelectedCIKList: (state, action) => {
      state.selectedCIKList = action.payload;
    },
    setStateOfIncorporationList: (state, action) => {
      state.stateOfIncorporationList = action.payload;
    },
    setSelectedstateOfIncorporationList: (state, action) => {
      state.selectedStateOfIncorporationList = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setIncludeKeyword: (state, action) => {
      state.includeKeyword = action.payload;
    },
    setSelectedIncludeKeyword: (state, action) => {
      state.selectedincludeKeyword = action.payload;
    },
    setExcludeKeyword: (state, action) => {
      state.excludeKeyword = action.payload;
    },
    setSelectedExcludeKeyword: (state, action) => {
      state.selectedexcludeKeyword = action.payload;
    },
  },
});

export const {
  setSearchItem,
  setSelectedActiveTab,
  setSearchResult,
  setSearchResultCount,
  setContractCount,
  setClauseCount,
  setDefinitionCount,
  setSearchPageCount,
  setIsLoading,
  setCompanyList,
  setDepartmentList,
  setGoverningLawList,
  setCountryList,
  setIndustryList,
  setSelectedCompany,
  setSelectedDepartment,
  setSelectedGoverningLaw,
  setSelectedCountry,
  setSelectedIndustry,
  setDocUrl,
  setContractTitleList,
  setSelectedContractTitle,
  setStateOfIncorporationList,
  setexhibitsList,
  setCIKList,
  setClausesList,
  setSelectedClause,
  setSearchValue,
  setSelectedFilters,
  setRemoveFilters,
  setAccessionNumberList,
  setRemoveAllFilters,
  setSelectedAccessionNumberList,
  setEntityTypeList,
  setSelectedEntityTypeList,
  setFormsList,
  setSelectedFormsList,
  setStockExchangesList,
  setSelectedStockExchangesList,
  setSelectedstateOfIncorporationList,
  setSelectedExhibitList,
  setSelectedCIKList,
  setSelectedDate,
  setDocData,
  setSelectedIncludeKeyword,
  setIncludeKeyword,
  setExcludeKeyword,
  setSelectedExcludeKeyword
} = searchSlice.actions;
export default searchSlice.reducer;

export const fetchSearchItemHnadler = (payload, type, offset, sort) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const response = await fetchSearchData(payload, 10, offset ? offset : 0, sort || 'relevance');
    if (response.data.results.length) {
      response.data.results.forEach((el, i) => {
        if (i === 0) {
          dispatch(setDocUrl({ uri: el.source_url }));
          el.selected = true;
        } else {
          el.selected = false;
        }
      });
      dispatch(setSearchItem(response.data.results || []));
      dispatch(setIsLoading(false));
    }
    else {
      dispatch(setSearchItem([]));
      dispatch(setIsLoading(false));
    }
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchSearchDataHnadler = (payload, type, offset, sort) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const response = await fetchSearchData(payload, 10, offset ? offset : 0, sort || 'relevance');
    console.log(response, "response");
    if (response?.data?.results.length) {
      if (type === "search") {
        const temp = response.data.results.map((item) => {
          return {
            _id: item._id,
            name: item.name,
            label: item.name,
            value: item._id,
          };
        });
        return temp;
      }
      response?.data?.results?.forEach((el, i) => {
        if (i === 0) {
          dispatch(setDocUrl({ uri: el.source_url }));
          el.selected = true;
        } else {
          el.selected = false;
        }
      });
      dispatch(setSearchItem(response.data.results || []));
      dispatch(setSearchResult(response?.data || []));
      dispatch(setSearchResultCount(response?.data?.total_count > 10 ? response?.data?.total_count : 10))
      dispatch(setSearchPageCount(offset || 0));
      dispatch(setIsLoading(false));
    }
    else {
      dispatch(setSearchResult([]));
      dispatch(setSearchResultCount(0))
      dispatch(setSearchPageCount(0));
      dispatch(setIsLoading(false));
    }

  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchCompanyHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchCompany(payload, size);
    const temp = response.data.results.map((item) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }
    dispatch(setCompanyList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchIndustryHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchIndustry(payload, size || 10);

    const filteredData = response.data.results.filter(item => item?.key.length > 0);
    const temp = filteredData.map((item) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setIndustryList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchDepartmentHandler = (payload, size) => async (dispatch, getState) => {
  try {

    const response = await fetchDepartment(payload, size);
    const temp = response.data.results.map((item) => {
      return {
        _id: item.key,
        department_id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setDepartmentList(finalData || []));
    return temp;
  } catch (error) {
  }
};

export const fetchGoverningLawHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchGoverlingLaw(payload, size);
    const filteredData = response.data.results.filter(item => item?.key !== null);
    const temp = filteredData.map((item) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setGoverningLawList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

//fetch Country 
export const fetchCountryHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchCountry(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setCountryList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchContractTitleHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchContractTitle(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });


    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setContractTitleList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const fetchClausesHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchClauses(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });


    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setClausesList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

//Accession number 
export const fetchAcessionNumberHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchAccession(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setAccessionNumberList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

//EntityType 
export const fetchEntityTypeHandler = (payload) => async (dispatch, getState) => {
  try {
    const response = await fetchEntityType(payload);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item?.key,
        name: item?.key,
        label: item?.key.toLowerCase(),
        value: item?.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setEntityTypeList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};


//Forms 
export const fetchFormsHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchForms(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item?.key,
        name: item?.key,
        label: item?.key.toLowerCase(),
        value: item?.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setFormsList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};


//StockExchangesHandler 
export const fetchStockExchangesHandler = (payload) => async (dispatch, getState) => {
  try {
    const response = await fetchStockExchanges(payload);
    const filteredData = response.data.results.filter(item => item?.key !== "" || item?.key !== null);
    const temp = filteredData.map((item, index) => {
      return {
        _id: item?.key,
        name: item?.key,
        label: item?.key.toLowerCase(),
        value: item?.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setStockExchangesList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};


//ExhibitID 
export const fetchExhibitIDHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchExhibitID(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item?.key,
        label: item?.key.toLowerCase(),
        value: item?.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }
    dispatch(setexhibitsList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

//CIK 
export const fetchCIKHandler = (payload, size) => async (dispatch, getState) => {
  try {
    const response = await fetchCIK(payload, size);
    const temp = response.data.results.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });


    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setCIKList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};


//StateOfIncorporation 
export const fetchStateOfIncorporationHandler = (payload) => async (dispatch, getState) => {
  try {
    const response = await fetchStateOfIncorporation(payload);
    const filteredData = response.data.results.filter(item => item.key !== "");
    const temp = filteredData.map((item, index) => {
      return {
        _id: item.key,
        name: item.key,
        label: item.key.toLowerCase(),
        value: item.key,
      };
    });

    let finalData = {
      data: temp,
      total_count: response?.data?.count
    }

    dispatch(setStateOfIncorporationList(finalData || []));
    return temp;
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};


export const genericFilterHandler = (activeTab, value, type = null, offset, sort) => async (dispatch, getState) => {
  try {
    const selectedContractTitle = getState().search.selectedContractTitle;
    const selectedCompany = getState().search.selectedCompany;
    const selectedIndustry = getState().search.selectedIndustry;
    const selectedGoverningLaw = getState().search.selectedGoverningLaw;
    const selectedCountry = getState().search.selectedCountry;
    const selectedDepartment = getState().search.selectedDepartment;
    const selectedClause = getState().search.selectedClause;
    const selectedAccessionNumber = getState().search.selectedAccessionNumberList;
    const selectedEntityTypeList = getState().search.selectedEntityTypeList;
    const selectedFormsList = getState().search.selectedFormsList;
    const selectedExchangesList = getState().search.selectedStockExchangesList;
    const selectedExhibitIDList = getState().search.selectedExhibitIDList;
    const selectedDateRange = getState().search.selectedDate;
    const selectedInclueKeyword = getState().search.includeKeyword;
    const selectedExcludeKeyword = getState().search.excludeKeyword;
    const selectedCIKList = getState().search.selectedCIKList;
    const selectedStateOfIncorporationList = getState().search.selectedStateOfIncorporationList;
    // _index: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : activeTab?.definition ? "definitions" : '',

    const payload = {
      _query: value,
      filters: {}
    };

    if (activeTab?.contractType) {
      payload._index = 'contracts'
    }
    if (activeTab?.clause) {
      payload._index = 'clauses'
    }
    if (activeTab?.definition) {
      payload._index = 'definitions'
    }

    if (selectedCompany) {
      payload.filters = {
        ...payload.filters,
        company: selectedCompany?.map(item => item._id),
      };
    }

    if (selectedContractTitle?.length) {
      payload.filters = {
        ...payload.filters,
        contract_type: selectedContractTitle?.map(item => item.name),
      };
    }
    if (selectedIndustry) {
      payload.filters = {
        ...payload.filters,
        industry: selectedIndustry?.map(item => item._id),
      };
    }
    if (selectedGoverningLaw) {
      payload.filters = {
        ...payload.filters,
        governing_law: selectedGoverningLaw?.map(item => item._id),
      };
    }
    if (selectedCountry) {
      payload.filters = {
        ...payload.filters,
        country: selectedCountry?.map(item => item.name),
      };
    }

    if (selectedDepartment) {
      payload.filters = {
        ...payload.filters,
        departments: selectedDepartment?.map(item => item._id),
      };
    }
    if (selectedClause) {
      payload.filters = {
        ...payload.filters,
        clauses: selectedClause?.map(item => item._id),
      };
    }
    if (selectedAccessionNumber) {
      payload.filters = {
        ...payload.filters,
        accession_number: selectedAccessionNumber?.map(item => item.value),
      };
    }
    if (selectedEntityTypeList) {
      payload.filters = {
        ...payload.filters,
        entity_type: selectedEntityTypeList?.map(item => item._id),
      };
    }
    if (selectedFormsList) {
      payload.filters = {
        ...payload.filters,
        forms: selectedFormsList?.map(item => item._id),
      };
    }
    if (selectedExchangesList) {
      payload.filters = {
        ...payload.filters,
        exchanges: selectedExchangesList?.map(item => item._id),
      };
    }
    if (selectedExhibitIDList) {
      payload.filters = {
        ...payload.filters,
        exhibit: selectedExhibitIDList?.map(item => item._id),
      };
    }
    if (selectedInclueKeyword) {
      payload.filters = {
        ...payload.filters,
        include_keywords: selectedInclueKeyword,
      };
    }
    if (selectedExcludeKeyword) {
      payload.filters = {
        ...payload.filters,
        exclude_keywords: selectedExcludeKeyword,
      };
    }
    if (selectedCIKList) {
      payload.filters = {
        ...payload.filters,
        cik_number: selectedCIKList?.map(item => item.name),
      };
    }
    if (selectedStateOfIncorporationList) {
      payload.filters = {
        ...payload.filters,
        state_of_incorporation: selectedStateOfIncorporationList?.map(item => item.name),
      };
    }
    if (selectedDateRange.length) {
      payload.filters = {
        ...payload.filters,
        start_date: moment(selectedDateRange[0]).format('YYYY-MM-DD'),
        end_date: moment(selectedDateRange[1]).format('YYYY-MM-DD'),
      };
    }
    dispatch(getAllsearchFilters(activeTab, value));
    if (type === "search") {
      dispatch(fetchSearchItemHnadler(payload, 10, offset ? offset : 0, sort || 'relevance'));
      if (window?.location?.pathname?.split('/')[1] === 'search-result') {
        dispatch(fetchAllCount(payload))
      }
    } else {
      dispatch(fetchSearchDataHnadler(payload, '', offset ? offset : 0, sort || 'relevance'));
      if (window?.location?.pathname?.split('/')[1] === 'search-result') {
        dispatch(fetchAllCount(payload))
      }
    }
  } catch (error) {
    console.log(error.message, "error.message");
    dispatch(setIsLoading(false));
  }
};


export const fetchAllCount = (payload) => async (dispatch, getState) => {
  try {
    const response = await getSearchCount(payload);
    console.log(response, "clauses response");
    if (response.data) {
      dispatch(setContractCount(response?.data?.count.contracts));
      dispatch(setClauseCount(response?.data?.count.clauses));
      dispatch(setDefinitionCount(response?.data?.count.definitions));
    }

  } catch (error) {
  }
};



export const getAllsearchFilters = (activeTab, value, size, name) => async (dispatch, getState) => {
  const selectedContractTitle = getState().search.selectedContractTitle;
  const selectedCompany = getState().search.selectedCompany;
  const selectedIndustry = getState().search.selectedIndustry;
  const selectedGoverningLaw = getState().search.selectedGoverningLaw;
  const selectedCountry = getState().search.selectedCountry;
  const selectedDepartment = getState().search.selectedDepartment;
  const selectedClause = getState().search.selectedClause;
  const selectedAccessionNumber = getState().search.selectedAccessionNumberList;
  const selectedEntityTypeList = getState().search.selectedEntityTypeList;
  const selectedFormsList = getState().search.selectedFormsList;
  const selectedExchangesList = getState().search.selectedStockExchangesList;
  const selectedExhibitIDList = getState().search.selectedExhibitIDList;
  const selectedDateRange = getState().search.selectedDate;
  const selectedInclueKeyword = getState().search.selectedincludeKeyword;
  const selectedExcludeKeyword = getState().search.selectedexcludeKeyword;
  const selectedCIKList = getState().search.selectedCIKList;
  const selectedStateOfIncorporationList = getState().search.selectedStateOfIncorporationList;
  // _index: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : activeTab?.definition ? "definitions" : '',
  const payload = {
    _query: value,
    filters: {}
  };

  if (activeTab?.contractType) {
    payload._index = 'contracts'
  }
  if (activeTab?.clause) {
    payload._index = 'clauses'
  }
  if (activeTab?.definition) {
    payload._index = 'definitions'
  }

  if (selectedCompany) {
    payload.filters = {
      ...payload.filters,
      company: selectedCompany?.map(item => item._id),
    };
  }

  if (selectedContractTitle?.length) {
    payload.filters = {
      ...payload.filters,
      contract_type: selectedContractTitle?.map(item => item.name),
    };
  }
  if (selectedIndustry) {
    payload.filters = {
      ...payload.filters,
      industry: selectedIndustry?.map(item => item._id),
    };
  }
  if (selectedGoverningLaw) {
    payload.filters = {
      ...payload.filters,
      governing_law: selectedGoverningLaw?.map(item => item._id),
    };
  }
  if (selectedCountry) {
    payload.filters = {
      ...payload.filters,
      country: selectedCountry?.map(item => item.name),
    };
  }

  if (selectedDepartment) {
    payload.filters = {
      ...payload.filters,
      departments: selectedDepartment?.map(item => item._id),
    };
  }
  if (selectedClause) {
    payload.filters = {
      ...payload.filters,
      clauses: selectedClause?.map(item => item._id),
    };
  }
  if (selectedAccessionNumber) {
    payload.filters = {
      ...payload.filters,
      accession_number: selectedAccessionNumber?.map(item => item.value),
    };
  }
  if (selectedEntityTypeList) {
    payload.filters = {
      ...payload.filters,
      entity_type: selectedEntityTypeList?.map(item => item._id),
    };
  }
  if (selectedFormsList) {
    payload.filters = {
      ...payload.filters,
      forms: selectedFormsList?.map(item => item._id),
    };
  }
  if (selectedExchangesList) {
    payload.filters = {
      ...payload.filters,
      exchanges: selectedExchangesList?.map(item => item._id),
    };
  }
  if (selectedExhibitIDList) {
    payload.filters = {
      ...payload.filters,
      exhibit: selectedExhibitIDList?.map(item => item._id),
    };
  }
  if (selectedInclueKeyword) {
    payload.filters = {
      ...payload.filters,
      include_keywords: selectedInclueKeyword,
    };
  }
  if (selectedExcludeKeyword) {
    payload.filters = {
      ...payload.filters,
      exclude_keywords: selectedExcludeKeyword,
    };
  }
  if (selectedCIKList) {
    payload.filters = {
      ...payload.filters,
      cik_number: selectedCIKList?.map(item => item.name),
    };
  }
  if (selectedStateOfIncorporationList) {
    payload.filters = {
      ...payload.filters,
      state_of_incorporation: selectedStateOfIncorporationList?.map(item => item.name),
    };
  }
  if (selectedDateRange.length) {
    payload.filters = {
      ...payload.filters,
      start_date: moment(selectedDateRange[0]).format('YYYY-MM-DD'),
      end_date: moment(selectedDateRange[1]).format('YYYY-MM-DD'),
    };
  }

  if (name === 'Industry') {
    dispatch(fetchIndustryHandler(payload, size))
  }
  else if (name === 'ContractType') {
    dispatch(fetchContractTitleHandler(payload, size))
  }
  else if (name === 'Department') {
    dispatch(fetchDepartmentHandler(payload, size))
  }
  else if (name === 'Clauses') {
    dispatch(fetchClausesHandler(payload, size))
  }
  else if (name === 'GoverningLaw') {
    dispatch(fetchGoverningLawHandler(payload, size))
  }
  else if (name === 'Country') {
    dispatch(fetchCountryHandler(payload, size))
  }
  else if (name === 'Company') {
    dispatch(fetchCompanyHandler(payload, size))
  }
  else if (name === 'CIK') {
    dispatch(fetchCIKHandler(payload, size))
  }
  else if (name === 'AccessionNumber') {
    dispatch(fetchAcessionNumberHandler(payload, size))
  }
  else if (name === 'EntityType') {
    dispatch(fetchEntityTypeHandler(payload, size))
  }
  else if (name === 'Forms') {
    dispatch(fetchFormsHandler(payload, size))
  }
  else if (name === 'StockExchanges') {
    dispatch(fetchStockExchangesHandler(payload, size))
  }
  else if (name === 'ExhibitID') {
    dispatch(fetchExhibitIDHandler(payload, size))
  }
  else if (name === 'StateOfIncorporation') {
    dispatch(fetchStateOfIncorporationHandler(payload, size))
  }
  else {
    dispatch(fetchContractTitleHandler(payload))
    dispatch(fetchIndustryHandler(payload))
    dispatch(fetchDepartmentHandler(payload))
    dispatch(fetchClausesHandler(payload))
    dispatch(fetchGoverningLawHandler(payload))
    dispatch(fetchCountryHandler(payload))
    dispatch(fetchCompanyHandler(payload))
    dispatch(fetchCIKHandler(payload))
    dispatch(fetchAcessionNumberHandler(payload))
    dispatch(fetchEntityTypeHandler(payload))
    dispatch(fetchFormsHandler(payload))
    dispatch(fetchStockExchangesHandler(payload))
    dispatch(fetchExhibitIDHandler(payload))
    dispatch(fetchStateOfIncorporationHandler(payload))
  }
};