/* eslint-disable import/no-anonymous-default-export */
import { Button } from "@mui/material";
import React from "react";

export default ({ className, text, icon, ...rest }) => {
  return (
    <>
      <Button className={className} {...rest}>
        {text} {icon && icon}
      </Button>
    </>
  );
};
