import axios from "axios";


const instance = axios.create({
    baseURL: process.env.REACT_APP_CMS_API,
});


//blog
export const getAllBlog = async () => {
    try {
        const res = await instance.get(`list/blog`);
        return res;
    } catch (error) {
        return error;
    }
};
export const getBlogByUrl = async (url) => {
    try {
        const res = await instance.get(`list/blog/${url}`);
        return res;
    } catch (error) {
        return error;
    }
};


//product
export const getAllProject = async () => {
    try {
        const res = await instance.get(`list/product-master`);
        return res;
    } catch (error) {
        return error;
    }
};
export const getProductByUrl = async (url) => {
    try {
        const res = await instance.get(`list/product-master/${url}`);
        return res;
    } catch (error) {
        return error;
    }
};



//fetchAllCaseStudy
export const fetchAllCaseStudy = async () => {
    try {
        const res = await instance.get(`list/case-study`);
        return res;
    } catch (error) {
        return error;
    }
};

//contact
export const fetchContactList = async () => {
    try {
        const res = await instance.get(`list/contact-us`);
        return res;
    } catch (error) {
        return error;
    }
};
export const contactUs = async () => {
    try {
        const res = await instance.get(`contact`);
        return res;
    } catch (error) {
        return error;
    }
};


//faq

export const fetchAllFAQs = async () => {
    try {
        const res = await instance.get(`list/faq`);
        return res;
    } catch (error) {
        return error;
    }
};



//newletter

export const subscribeNewslatter = async (payload) => {
    try {
        const res = await instance.post(`subscribe-newslatter`, payload);
        return res;
    } catch (error) {
        return error;
    }
};



//pages

export const getAllPage = async () => {
    try {
        const res = await instance.get(`list/webpage`);
        return res;
    } catch (error) {
        return error;
    }
};

export const getPageById = async (id) => {
    try {
        const res = await instance.get(`list/webpage/${id}`);
        return res;
    } catch (error) {
        return error;
    }
};

//pricing
export const createPricing = async (payload) => {
    try {
        const res = await instance.post(`pricing`, payload);
        return res;
    } catch (error) {
        return error;
    }
};
