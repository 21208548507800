/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Box, Checkbox, Container, FormControlLabel, FormGroup, IconButton, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { fetchSingleContract, getAgreementIndex } from "../../service/Global";
import Toast from '../../component/Toast';
import { createBookmark, getAllbookmarkList, removeBookmark } from '../../service/BookmarkService';
import { AiFillCaretRight } from 'react-icons/ai';
import SaveBM from './SaveBM';
import { Loader } from 'rsuite';
import { RiExternalLinkFill } from "react-icons/ri";
import { useSelector } from 'react-redux';

function ViewFullScreen({ docFullViewData, setViewFullViewer, activeTab }) {
    const regex = new RegExp(/<html\b[^>]*>[\s\S]*<\/html>/i);
    const { query } = useParams();
    const { rootRef } = useRef(null);
    const previewDivRef = useRef(null);
    const divRef = useRef(null);
    const [contarctData, setContarctData] = useState([])
    const [docData, setDocData] = useState(docFullViewData)
    const [agreementindex, setAgreementindex] = useState([])
    const [fontSize, setFontSize] = useState(14)
    const [zoom, setzoom] = useState(100)
    const [showSaveDD, setShowSaveDD] = useState(false)
    const [saveModal, setSaveModal] = useState(false)
    const [customBookMarkModal, setCustomBookMarkModal] = useState(false)
    const [bookmarkList, setBookmarkList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { entityTypeList } = useSelector((state) => state.search);




    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };


    const handleScrollToTop = () => {
        if (previewDivRef.current) {
            // Scroll to the top of the div
            previewDivRef.current.scrollTop = 0;
        }
    };


    var base64Data = btoa(unescape(encodeURIComponent(docData?.formatted_text)));;
    // var base64Data = btoa(docData?.formatted_text);
    // let dec = atob(base64Data);
    // console.log(dec);

    useEffect(() => {
        debugger
        handleScrollToTop()
        fectchFullContarct()
        fetchAgrementIndex()
        fetchBookmarkList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docData])


    const fectchFullContarct = async () => {
        setIsLoading(true)
        let payload = { "documentData": base64Data }
        const response = await getAgreementIndex(payload);

        if (response?.data?.data) {
            setAgreementindex(response?.data?.data)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }
    }

    const fetchAgrementIndex = async () => {
        let payload = { "contract_title": docData?.contract_title }
        const response = await fetchSingleContract(payload);
        setContarctData(response?.data?.results)
    }



    //fetch bookmark list
    const fetchBookmarkList = async () => {
        const res = await getAllbookmarkList();
        if (res.data) {
            setBookmarkList(res?.data)
        }
    }


    //handlebookmark save
    const handleBookmark = async (e, item) => {

        const { name, checked } = e.target;
        if (!checked) {
            const res = await removeBookmark(item.id);

            if (res.message === 'Bookmark deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList();
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
        else {

            let payload = {
                contract_id: docData?.contract_id,
                library_type: name,
                bookmark_type: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions",
                bookmark_name: activeTab?.contractType ? docData?.contract_title : activeTab?.clause ? docData.heading : docData.define_term,
            }

            const res = await createBookmark(payload);

            if (res.message === 'Bookmark Created Successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
    }


    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (divRef?.current && !divRef?.current.contains(e.target)) {
            // Clicked outside the div, so remove the class
            setShowSaveDD(false)
        }
    };

    return (
        <>
            {console.log("docData", docData)}
            <div className="gray_bg" ref={previewDivRef}>
                <Container className="main-container " >
                    <div className="viewfull_head">
                        <ul className="flex">
                            <li className="active" onClick={() => setViewFullViewer(false)}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="active" onClick={() => setViewFullViewer(false)}>{query}</li>
                            <li>{docData?.contract_title.toLowerCase()}</li>
                        </ul>
                    </div>
                    <Stack className="viewfull">
                        <div className='viewfull_left'

                        >
                            <div className="viewfull_left_wrp" ref={rootRef} >
                                <div className="viewfull_left_top">
                                    <div className="viewfull_left_top_resize ">
                                        <IconButton color="primary" disabled={zoom === 200} onClick={() => { setFontSize(fontSize + 2); setzoom(zoom + 20); }} >
                                            <CgMathPlus size={18} />
                                        </IconButton>
                                        <p> {zoom}% </p>
                                        <IconButton color="primary" disabled={zoom === 60} onClick={() => { setFontSize(fontSize - 2); setzoom(zoom - 20); }} >
                                            <CgMathMinus size={18} />
                                        </IconButton>
                                    </div>
                                    <div className="viewfull_left_top_save">
                                        <Stack className="save_dropdwon" alignItems="center">
                                            <span onClick={() => {
                                                if (localStorage.getItem('token')) {
                                                    setShowSaveDD(!showSaveDD)
                                                }
                                                else {
                                                    setToastData({
                                                        open: true,
                                                        status: 'warning',
                                                        message: `Please Login First to save contract`
                                                    });
                                                }
                                            }}>
                                                Save  <MdOutlineBookmarkAdd fontSize={24} style={{ marginLeft: "5px" }} />
                                            </span>


                                            {console.log("docDataaaaa", docData, bookmarkList, bookmarkList?.my_library, bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library')}
                                            <div ref={divRef} className={`save_dropdwon_wrp ${showSaveDD ? 'active' : ''}`}>
                                                <ul>
                                                    <li>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="my_library"
                                                                        onChange={(e) => handleBookmark(e, bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                                                        checked={bookmarkList?.my_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'my_library'}
                                                                    />
                                                                }
                                                                label="My Library"
                                                                labelPlacement="start"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="team_library"
                                                                        onChange={(e) => handleBookmark(e, bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0])}
                                                                        checked={bookmarkList?.team_library?.filter((_) => _?.contract_id === docData?.contract_id)[0]?.library_type === 'team_library'}
                                                                    />
                                                                }
                                                                label="Teams Library"
                                                                labelPlacement="start"
                                                            />
                                                        </FormGroup>
                                                    </li>
                                                    <li onClick={() => setSaveModal(true)}>
                                                        <span> Client Library <AiFillCaretRight /></span>
                                                    </li>
                                                    <li onClick={() => setCustomBookMarkModal(true)}>
                                                        <span> Custom Library <AiFillCaretRight /></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Stack>

                                    </div>
                                    <h5 className="right_contracttitle"> {docData?.contract_title}</h5>
                                </div>
                                <Box className="viewfull_left_doc" sx={{ '& *': { fontSize: `${fontSize}px !important` } }}>
                                    {/* {docData?.raw_text ?
                                    <div dangerouslySetInnerHTML={{ __html: docData?.raw_text.replace(/\n/g, "<br />") }} ></div>
                                    :
                                    <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                                } */}

                                    {docData?.raw_text ?
                                        regex.test(docData?.raw_text) ?
                                            <div dangerouslySetInnerHTML={{ __html: docData?.raw_text }} ></div>
                                            :
                                            <pre>
                                                <div>{docData?.raw_text}</div>
                                            </pre>
                                        :
                                        regex.test(docData?.raw_text) ?
                                            <div dangerouslySetInnerHTML={{ __html: docData?.formatted_text?.replace(/\n/g, "<br />") }} ></div>
                                            :
                                            <pre>
                                                <div>{docData?.formatted_text}</div>
                                            </pre>
                                    }

                                </Box>

                                <SaveBM
                                    docData={docData}
                                    bookmarkList={bookmarkList}
                                    activeTab={activeTab}
                                    fetchBookmarkList={fetchBookmarkList}
                                    rootRef={() => rootRef?.current}
                                    saveModal={saveModal}
                                    setSaveModal={setSaveModal}
                                    customBookMarkModal={customBookMarkModal}
                                    setCustomBookMarkModal={setCustomBookMarkModal}
                                />
                            </div>


                            <div className="viewfull_left_similar">
                                <h4> Similar Content </h4>
                                <div className="viewfull_left_similar_list">
                                    <h3> Contract Types you might be interested in </h3>
                                    <ul>
                                        {console.log("contarctData", contarctData)}
                                        {
                                            contarctData?.length ?
                                                contarctData?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <li onClick={() => setDocData({ ...item[0], ...item[1] })}>
                                                                {item[0]?.contract_title.toLowerCase().replace(/(^|\s)\S/g, (match) => match?.toUpperCase())}
                                                            </li>
                                                        </>
                                                    )
                                                })
                                                :
                                                <li>
                                                    <span> No Data Found </span>
                                                </li>
                                        }

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className='viewfull_right'>
                            <div className="viewfull_right_meta">
                                <h4> Contract Metadata </h4>
                                <div className="viewfull_right_meta_list">
                                    <ul>
                                        {docData?.contract_title &&
                                            <li>
                                                <span> Contract type : </span>
                                                <span> {docData?.contract_title.toLowerCase()} </span>
                                            </li>
                                        }
                                        {docData?.country &&
                                            <li>
                                                <span> Country : </span>
                                                <span>  {docData?.country.toLowerCase()} </span>
                                            </li>
                                        }

                                        {docData?.jurisdiction &&
                                            <li>
                                                <span> Jurisdiction : </span>
                                                <span>  {docData?.jurisdiction.toLowerCase()} </span>
                                            </li>
                                        }

                                        {docData?.industry &&
                                            <li>
                                                <span> Industry : </span>
                                                <span>  {docData?.industry.toLowerCase()} </span>
                                            </li>
                                        }

                                        {docData?.party &&
                                            <li>
                                                <span> Party : </span>
                                                <span>  {docData?.party.toLowerCase()} </span>
                                            </li>
                                        }

                                        {docData?.accession_number &&
                                            <li>
                                                <span> Accession Number :</span>
                                                <span>  {docData?.accession_number} </span>
                                            </li>
                                        }

                                        {docData?.filing_date &&
                                            <li>
                                                <span> Filing Date :</span>
                                                <span>  {docData?.filing_date} </span>
                                            </li>
                                        }

                                        {(docData?.entity_type && entityTypeList?.length) &&
                                            <li>
                                                <span> Entity Type : </span>
                                                <span> {entityTypeList?.filter((_) => _?._id === docData?.entity_type)[0]?.name}</span>
                                            </li>
                                        }

                                        {docData?.company &&
                                            <li>
                                                <span> Company Name : </span>
                                                <span>  {docData?.company.toLowerCase()} </span>
                                            </li>
                                        }
                                        {docData?.state_of_incorporation &&
                                            <li>
                                                <span> State Of Incorporation : </span>
                                                <span>  {docData?.state_of_incorporation} </span>
                                            </li>
                                        }

                                        {docData?.governing_law &&
                                            <li>
                                                <span> Governing Law :</span>
                                                <span>  {docData?.governing_law ? docData?.governing_law.toLowerCase() : '-'} </span>
                                            </li>
                                        }

                                        {docData?.department &&
                                            <li>
                                                <span> Department : </span>
                                                <span>  {docData?.department?.map((d) => d?.department_type).join(", ")} </span>
                                            </li>
                                        }

                                        {docData?.cik &&
                                            <li>
                                                <span> CIK : </span>
                                                <span>  {docData?.cik} </span>
                                            </li>
                                        }
                                        {docData?.exhibit &&
                                            <li>
                                                <span> Exhibit ID : </span>
                                                <span> {docData?.exhibit} </span>
                                            </li>
                                        }

                                        <li className='source_link'>
                                            <span> Source : </span>
                                            <span>
                                                <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ textDecoration: "underline" }}>
                                                    <a href={docData.source_url} target='_blank'> Link <RiExternalLinkFill /></a>
                                                </Stack>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="viewfull_right_meta agreement">
                                <h4> Agreement Index </h4>
                                <div className="viewfull_right_meta_list">


                                    {/* No Data Found */}
                                    {/* <span> {docData?.contra ct_title} </span> */}
                                    {isLoading ?
                                        <div style={{ height: "40px", position: "relative" }} > <Loader center content="Loading Data..." /> </div>
                                        :
                                        agreementindex?.length ?
                                            <>
                                                <ul>
                                                    {agreementindex?.map((item, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <span > {item.headings} </span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </>
                                            :
                                            <ul>
                                                <li >
                                                    <span > No Data Found </span>
                                                </li>

                                            </ul>
                                    }

                                </div>

                            </div>
                        </div>
                    </Stack>
                </Container >
                <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
            </div>
        </>
    )
}

export default ViewFullScreen