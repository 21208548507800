import React, { useState } from 'react'
import ModalComponent from '../../component/Modal'
import { makeStyles } from '@mui/styles';
import SaveDocModal from './SaveDocModal';
import CustomBookMark from './CustomBookMark';
import Toast from '../../component/Toast';
import { createBookmark, removeBookmark, } from '../../service/BookmarkService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    root: {
        position: 'absolute',
    },
    backdrop: {
        position: 'absolute',
    },
});

function SaveBM({ docData, activeTab, saveModal, bookmarkList, fetchBookmarkList, setSaveModal, customBookMarkModal, setCustomBookMarkModal, rootRef, library }) {
    const classes = useStyles();
    const { openlibraryType } = useSelector((state) => state.library);
    const [toastData, setToastData] = useState({
        open: false,
        message: '',
        status: ''
    });

    const handleCloseToast = () => {
        setToastData((prev) => ({ ...prev, open: false }));
    };

    const hadleSaveClientLibrary = async (check, id, deleteBKid) => {
        let payload;
        let res
        if (check) {
            let res = await removeBookmark(deleteBKid);
            if (res.message === 'Bookmark deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
        else {
            payload = {
                contract_id: docData?.contract_id,
                library_type: 'client_library',
                bookmark_type: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions",
                bookmark_name: library === 'library' ? openlibraryType : activeTab?.contractType ? docData?.contract_title : activeTab?.clause ? docData.heading : docData.define_term,
                client_id: id,
            }
            res = await createBookmark(payload);
            if (res.message === 'Bookmark Created Successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
    }


    // create custom bookmark popup

    const handleMultiBookMarkLibrary = async (check, name, selected) => {

        if (check) {
            let res = await removeBookmark(selected?.id);

            if (res.message === 'Bookmark deleted successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
                // setCustomBookMarkModal(false)
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }
        else {
            let payload = {
                contract_id: docData?.contract_id,
                library_type: name,
                bookmark_type: activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions",
                bookmark_name: library === 'library' ? openlibraryType : activeTab?.contractType ? docData?.contract_title : activeTab?.clause ? docData.heading : docData.define_term,
            }

            const res = await createBookmark(payload);

            if (res.message === 'Bookmark Created Successfully.') {
                setToastData({
                    open: true,
                    status: 'success',
                    message: res.message || `success`
                });
                fetchBookmarkList()
            }
            else {
                setToastData({
                    open: true,
                    status: 'error',
                    message: res.message || `something went wrong`
                });
            }
        }


    }

    const handleCustomBM = async (name) => {
        let payload = {
            contract_id: docData?.contract_id,
            library_type: name,
        }

        if (typeof activeTab === "string") {
            payload.bookmark_type = activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions"
            payload.bookmark_name = activeTab === "contracts" ? docData?.contract_title : activeTab === "clauses" ? docData.heading : docData.define_term
        }
        else {
            payload.bookmark_type = activeTab?.contractType ? "contracts" : activeTab?.clause ? "clauses" : "definitions"
            payload.bookmark_name = activeTab?.contractType ? docData?.contract_title : activeTab?.clause ? docData.heading : docData.define_term
        }
        const res = await createBookmark(payload);

        if (res.message === 'Bookmark Created Successfully.') {
            setToastData({
                open: true,
                status: 'success',
                message: res.message || `success`
            });
            fetchBookmarkList()
            setCustomBookMarkModal(false)
        }
        else {
            setToastData({
                open: true,
                status: 'error',
                message: res.message || `something went wrong`
            });
        }
    }

    return (
        <>
            {console.log("docData", docData)}
            <ModalComponent
                width="calc(100% - 30px)"
                maxWidth="346px"
                height="346px"
                open={saveModal}
                handleClose={() => setSaveModal(false)}
                className="client_assignmodal"
                container={rootRef}
                classes={{ root: classes.root }}
                BackdropProps={{ classes: { root: classes.backdrop } }}
                style={{ position: 'absolute' }}
            >
                <SaveDocModal
                    handleClose={() => setSaveModal(false)}
                    handleSave={hadleSaveClientLibrary}
                    bookmarkList={bookmarkList}
                    docData={docData}
                />
            </ModalComponent>


            {/* custom library popup*/}
            <ModalComponent
                width="calc(100% - 30px)"
                maxWidth="346px"
                height="346px"
                open={customBookMarkModal}
                handleClose={() => setCustomBookMarkModal(false)}
                className="client_assignmodal"
                container={rootRef}
                classes={{ root: classes.root }}
                BackdropProps={{ classes: { root: classes.backdrop } }}
                style={{ position: 'absolute' }}
            >
                <CustomBookMark
                    handleClose={() => setCustomBookMarkModal(false)}
                    handleSave={handleMultiBookMarkLibrary}
                    handleCustomBM={handleCustomBM}
                    bookmarkList={bookmarkList}
                    docData={docData}
                />
            </ModalComponent>
            <Toast open={toastData.open} message={toastData.message} status={toastData.status} handleClose={handleCloseToast} />
        </>
    )
}

export default SaveBM