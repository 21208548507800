import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { contactUs, fetchContactList } from "../../service/CMSService";
import SEO from "../../component/SEO";

const ContactUs = () => {
  const toast = useToast();
  const [contactDetails, setContactDetails] = useState({});
  const [formDetails, setFormDetails] = useState({});

  const fetchContactListHandler = async () => {
    const res = await fetchContactList();
    setContactDetails(res.data.data[0]);
  };
  useEffect(() => {
    fetchContactListHandler();
  }, []);

  const resetForm = () => {
    setFormDetails({
      firstName: "",
      lastName: "",
      workEmail: "",
      jobTitle: "",
      company: "",
      employees: "",
      phone: "",
      country: "",
    });
  };

  const contactUsHandler = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
    } = formDetails;
    const payload = {
      firstName,
      lastName,
      workEmail,
      jobTitle,
      company,
      employees,
      phone,
      country,
    };
    const res = await contactUs(payload);
    if (res.status === 200) {
      resetForm();
      toast({
        title: "Success.",
        description: "Thank you for contacting us.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error.",
        description: "Something went worng",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <SEO
        title={"Contact Us" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading className="animate__animated animate__fadeInDown animate__faster">
            Contact Us
          </Heading>
        </Box>
      </Box>
      <div className="container">
        <div className="contactus">
          <div className=" contactus_left">
            <Box mt={5}>
              <Heading className="animate__animated animate__fadeInDown animate__faster">
                Talk to a Specialist
              </Heading>
              <Text>
                {contactDetails?.talk_to_specialist?.toll_free} :{" "}
                <a
                  href={`tel:${contactDetails?.talk_to_specialist?.isd_code}-${contactDetails?.talk_to_specialist?.mobile}`}
                >
                  {" "}
                  <b>
                    {contactDetails?.talk_to_specialist?.isd_code}-
                    {contactDetails?.talk_to_specialist?.mobile}
                  </b>
                </a>
              </Text>
              <Heading className="animate__animated animate__fadeInDown animate__faster">
                Kronicle Head Office
              </Heading>
              <Text>{contactDetails?.customer_support?.address}</Text>
              <Text>
                <b>Support Hours:</b>{" "}
                {contactDetails?.customer_support?.support}
              </Text>
              <Text>
                <b>Contact no:</b>{" "}
                {contactDetails?.customer_support?.contact_number}
              </Text>
              <Text>
                <b>Email:</b> {contactDetails?.customer_support?.email}
              </Text>
            </Box>
          </div>
          <div className=" contactus_right">
            <form onSubmit={contactUsHandler}>
              <div className="contactus_form">
                <h4> Contact Us </h4>
                <div className="text-center">
                  <SimpleGrid columns={2} mb={10} spacing={5}>
                    <Box>
                      <label> First Name </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            firstName: e.target.value,
                          });
                        }}
                        value={formDetails?.firstName}
                        type="text"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Last Name </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            lastName: e.target.value,
                          });
                        }}
                        value={formDetails?.lastName}
                        type="text"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Work Email </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            workEmail: e.target.value,
                          });
                        }}
                        value={formDetails?.workEmail}
                        type="email"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Job Title </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            jobTitle: e.target.value,
                          });
                        }}
                        value={formDetails?.jobTitle}
                        type="text"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Company </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            company: e.target.value,
                          });
                        }}
                        value={formDetails?.company}
                        type="text"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Employees </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            employees: e.target.value,
                          });
                        }}
                        value={formDetails?.employees}
                        type="number"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Phone </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            phone: e.target.value,
                          });
                        }}
                        value={formDetails?.phone}
                        type="number"
                        required
                      />
                    </Box>
                    <Box>
                      <label> Country </label>
                      <input
                        onChange={(e) => {
                          setFormDetails({
                            ...formDetails,
                            country: e.target.value,
                          });
                        }}
                        value={formDetails?.country}
                        type="text"
                        required
                      />
                    </Box>
                  </SimpleGrid>
                  <Button type="submit"> Submit </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
