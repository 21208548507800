import {
  Box,
  Heading,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import SEO from "../../component/SEO";
import { getAllPage, getProductByUrl } from "../../service/CMSService";
import SEO from "../../component/SEO";
import Footer from "../../component/Footer";

const ProductRenderPage = () => {
  const navigate = useNavigate();
  const { url } = useParams();
  const [pageContentData, setPageContentData] = useState({
    detailData: "",
  });
  const getPageData = useCallback(async () => {
    const res = await getProductByUrl(url);
    const resData = res.data;
    console.log(resData, "resData");
    setPageContentData(resData);
  }, [url]);
  useEffect(() => {
    getPageData();
  }, [getPageData, url]);

  // console.log(pageContentData?.detailData[0]?.subHeader, "pageContentData");


  const [pages, setPages] = useState([]);

  const getAllPageHanlder = async () => {
    const res = await getAllPage();
    setPages(res.data.data || []);
  };

  useEffect(() => {
    getAllPageHanlder();
  }, []);


  return (
    <>
      <SEO
        title={pageContentData?.detailData[0]?.title || pageContentData?.detailData[0]?.subHeader || ''}
        description={pageContentData?.detailData[0]?.metaDiscription || ''}
        url={`/${pageContentData?.detailData[0]?.seoURL}` || ''}
        ogTag={pageContentData?.detailData[0]?.ogTag || ''}
        metaKeywords={pageContentData?.detailData[0]?.metaKeywords || ''}
      />


      <div
        className="product_main_title"
        style={{
          backgroundImage: `url(${pageContentData?.masterData?.imageName})`,
          fontSize: "50px",
        }}
      >
        <Box
          display="flex"
          px={5}
          py={7}
          alignItems="center"
          justifyContent="center"
          background="rgba(9,99,157,0.8)"
        >
          <div className="container">
            <h2 className="animate__animated animate__fadeInDown animate__faster">
              {pageContentData?.masterData?.name}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  pageContentData?.detailData[0]?.productContentDiscription,
              }}
            ></p>
          </div>
        </Box>
      </div>
    

        <Box
          p={0}
          m={["20px 0", "20px 0 ", "30px 0 ", "56px 0"]}
          background="#fff"
        >
          <div className="container staticdata">
            {pageContentData?.detailData[0]?.productTerms?.map((item, i) => {
              return (
                <>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    style={{ flexWrap: "wrap" }}
                    flexDirection={`${i % 2 !== 0 ? "row-reverse" : ""}`}
                    spacing={5}
                    mb={[8, 10, 14, 16]}
                    key={i}
                  >
                    <Box width={["100%", "100%", "55%", "55%"]}>
                      <Image
                        height={"auto"}
                        width={"100%"}
                        src={item?.detailsImage}
                        objectFit={"contain"}
                      />
                    </Box>
                    <Box width={["100%", "100%", "42%", "43%"]}>
                      <Heading>{item?.heading}</Heading>
                      <div
                        style={{ padding: "15px" }}
                        dangerouslySetInnerHTML={{
                          __html: item?.productDetailDescription,
                        }}
                      />
                      <button
                        style={{ borderRadius: 30 }}
                        onClick={() => navigate("/demo")}
                        className="btn btn-primary-outline"
                      >
                        Try it Free
                      </button>
                    </Box>
                  </Stack>
                  {/* {item.ProductTermSubHeading.map(() => {
                  return <></>;
                })} */}

                  {item?.ProductTermSubHeading.length ? (
                    <Stack mb={[8, 10, 14, 16]}>
                      <div>
                        <Tabs className="termsTabs">
                          <TabList className="termsTablist">
                            {item?.ProductTermSubHeading.reverse().map(
                              (terms, i) => {
                                return (
                                  <Tab key={i} className="termsTablist_item" mb={2}>
                                    {terms.subHeading1}
                                  </Tab>
                                );
                              }
                            )}
                          </TabList>

                          <TabPanels className="termsTabPanel" p={0}>
                            {item?.ProductTermSubHeading.reverse().map(
                              (terms, i) => {
                                return (
                                  <TabPanel key={i} p={2} className="termsTabContent">
                                    <img
                                      src={terms.productTermSubHeadingImage}
                                      alt=""
                                      style={{ width: "100%" }}
                                    />
                                    <p
                                      style={{
                                        marginBottom: "10px",
                                        padding: "15px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: terms.content,
                                      }}
                                    ></p>
                                  </TabPanel>
                                );
                              }
                            )}
                          </TabPanels>
                        </Tabs>
                      </div>
                    </Stack>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </Box>


      <Footer pages={pages} />
    </>
  );
};

export default ProductRenderPage;
