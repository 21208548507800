import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchContactList } from "../../service/CMSService";
import SEO from "../../component/SEO";

const TechSupport = () => {
  const [contactDetails, setContactDetails] = useState({});

  const fetchContactListHandler = async () => {
    const res = await fetchContactList();
    setContactDetails(res.data.data[0]);
  };
  useEffect(() => {
    fetchContactListHandler();
  }, []);
  return (
    <>
      <SEO
        title={"Tech Support" || ''}
        description={''}
        url={''}
        ogTag={''}
        metaKeywords={''}
      />
      <Box
        display="flex"
        p={5}
        alignItems="center"
        justifyContent="center"
        background="rgba(9,99,157,0.8)"
        color="#fff"
      >
        <Box textAlign="center">
          <Heading className="animate__animated animate__fadeInDown animate__faster">
            Tech Support
          </Heading>
        </Box>
      </Box>
      <div className="container tech-support">
        <div className="contactus">
          <div className=" contactus_left">
            <Box mt={5}>
              <Heading className="animate__animated animate__fadeInDown animate__faster">
                Talk to a Specialist
              </Heading>
              <Text>
                {contactDetails?.talk_to_specialist?.toll_free} :{" "}
                <a
                  href={`tel:${contactDetails?.talk_to_specialist?.isd_code}-${contactDetails?.talk_to_specialist?.mobile}`}
                >
                  {" "}
                  <b>
                    {contactDetails?.talk_to_specialist?.isd_code}-
                    {contactDetails?.talk_to_specialist?.mobile}
                  </b>
                </a>
              </Text>
              <Heading
                marginTop="80px !important"
                className="animate__animated animate__fadeInDown animate__faster "
              >
                Kronicle Head Office
              </Heading>
              <Text>{contactDetails?.customer_support?.address}</Text>
              <Text>
                <b>Support Hours:</b>{" "}
                {contactDetails?.customer_support?.support}
              </Text>
              <Text>
                <b>Contact no:</b>{" "}
                {contactDetails?.customer_support?.contact_number}
              </Text>
              <Text>
                <b>Email:</b> {contactDetails?.customer_support?.email}
              </Text>
            </Box>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default TechSupport;
